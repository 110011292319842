/**

  @container
--------------------------------------------------------
  layout/_container.scss
--------------------------------------------------------

  The .container class is applied to restrict the
  max-width of an element, usually a bit of content.

  Often, you will place a .container div within another
  element that has a background color. This gives you
  a full-bleed background, but the content is contained
  within the max-width.

  Example: Container within a section
  <section class="awesome-section-with-background-color">
    <div class="container">
      <p>Your content goes here... </p>
    </div>
  </section>

------------------------------------------------------ */

.container {
    @include clearfix();
    margin-left: auto;
    margin-right: auto;
    width: $site-max-width;
    max-width: 100%;
}

.container--v-center {
    position: relative;
    display: table;
    height: 100%;
    > * {
        display: table-cell;
        vertical-align: middle;
    }
}

.container--ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
