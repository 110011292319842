body.single, body.archive, body.blog {
    #content > .container {
        @include bp-mobile {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

.main {
    @include clearfix;
    > .__left {
        @include bp-tablet-and-desktop {
            float: left;
            width: 955px;
        }
        > .__top {
            @include clearfix;
        }
    }

    .__ctas {
        margin-left: -20px;
        overflow: hidden;
        margin-top: 60px;
        margin-bottom: 60px;
        .__cta-1, .__cta-2 {
            height: 470px;
            padding-left: 20px;
            text-align: center;

            @include bp-tablet-and-desktop {
                float: left;
                width: 50%;
            }

            @include bp-mobile {
                height: auto;
            }

            .__inner {
                height: 100%;
            }
        }

        .__cta-1 {
            .__inner {
                padding: 40px;
                background-color: #f6f6f6;
                border-radius: 5px;
                @include bp-mobile {
                    padding: 30px 20px;
                }
                .__thumbnail {
                    margin-bottom: 20px;
                    @include bp-mobile {
                        width: 126px;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
                .__button {
                    margin-top: 20px;
                }

                .__text {
                    font-weight: bold;
                    font-family: $YuGothic;
                    font-size: 18px;
                    @include bp-mobile {
                        font-size: 14px;
                    }
                }
            }
        }

        .__cta-2 {
            @include bp-mobile {
                margin-top: 20px;
            }
            .__inner {
                padding: 40px;
                border: 2px solid #f6f6f6;
                border-radius: 5px;
                @include bp-mobile {
                    padding: 30px 20px;
                }

                .__thumbnail {
                    text-align: center;
                    margin-bottom: 20px;
                }
                .__text-1 {
                    font-weight: bold;
                    font-family: $YuGothic;
                    font-size: 18px;
                    margin-top: 20px;
                    @include bp-mobile {
                        font-size: 14px;
                    }
                }
                .__text-2 {
                    margin-top: 20px;
                    font-family: $YuGothic;
                    font-size: 14px;
                    @include bp-mobile {
                        font-size: 13px;
                    }
                }
                .__buttons {
                    margin-top: 30px;
                    overflow: hidden;
                    margin-left: -8px;
                    margin-bottom: -8px;
                    .__button {

                        padding-left: 8px;
                        padding-bottom: 8px;
                        line-height: 2.4;

                        @include bp-tablet-and-desktop {
                            float: left;
                            width: 50%;
                        }

                        &:last-child {
                            width: 100%;
                        }
                        .button {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .__sns {
        width: 80px;
        float: left;
        margin-right: 80px;
    }

    .__middle {
        @include bp-tablet-and-desktop {
            float: left;
            width: 795px;
        }

        .__share {
            border: 3px solid #eeeeee;
            padding: 40px;
            border-radius: 5px;
            @include bp-mobile {
                padding: 20px;
                margin-left: -16px;
                margin-right: -16px;
            }
            .__top {
                font-family: $YuGothic;
                font-weight: bold;
                font-size: 18px;
                border-bottom: 1px solid #f2f2f2;
                line-height: 1;
                padding-bottom: 1em;
                margin-bottom: 1em;
                @include bp-mobile {
                    line-height: 1.5;
                    text-align: center;
                    font-size: 15px;
                }
            }

            .__bottom {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }

        .__author-section {
            overflow: hidden;
            margin-top: 60px;
            margin-bottom: 60px;

            .__avatar {
                margin-right: 20px;
                img {
                    width: 110px;
                    height: 110px;
                    border-radius: 100%;
                }

                @include bp-tablet-and-desktop {
                    float: left;
                }

                @include bp-mobile {
                    margin-bottom: 20px;
                    text-align: center;
                }
            }

            .__right {
                @include bp-tablet-and-desktop {
                    float: right;
                    width: calc(100% - 130px);
                }
                .__author-name {
                    font-family: $YuGothic;
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 1;
                    margin-bottom: 1em;
                }
                .__description {
                    font-size: 14px;
                    line-height: 1.7;
                }
            }
        }

        .__taxonomy {
            margin-top: 60px;
            margin-bottom: 60px;
            .__categories, .__tags {
                position: relative;
                font-weight: bold;
                font-size: 14px;
                font-family: $YuGothic;

                &:not(:last-child) {
                    margin-bottom: 20px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    background-color: #f6f6f6;
                    width: 28px;
                    height: 28px;
                    border-radius: 100%;
                    left: -8px;
                    top: -4px;
                    z-index: 1;
                }
            }

            .__categories {
                &:before {
                    content: '\e802';
                    font-family: iconfont;
                    color: $color__purple;
                    z-index: 2;
                    position: relative;
                    margin-right: 2em;
                }
            }
            .__tags {
                &:before {
                    content: '\e804';
                    font-family: iconfont;
                    color: $color__purple;
                    z-index: 2;
                    position: relative;
                    margin-right: 2em;
                }
                > a {
                    &:not(:first-child) {
                        display: inline-block;
                        margin-left: 1.5em;
                    }
                }
            }
        }
    }

    .sidebar {
        @include bp-mobile {
            margin-top: 50px;
        }
        @include bp-tablet-and-desktop {
            float: right;
            width: 325px;
        }
        .__widget {
            &:not(:last-child) {
                margin-bottom: 50px;
            }
            > .__title {
                font-size: 16px;
                margin-bottom: 1em;
            }

            &.category {
                .__categories {
                    .__item {
                        background-color: #f6f6f6;
                        font-size: 14px;
                        font-weight: bold;
                        font-family: $YuGothic;
                        padding: 20px;
                        margin-bottom: 2px;
                        .__count {
                            float: right;
                        }
                    }
                }
            }
        }

    }
}

.article {
    .__header {
        margin-bottom: 50px;
        @include bp-mobile {
            margin-bottom: 20px;
        }
        > .__title {
            font-size: 28px;
            font-weight: bold;
            font-family: $YuGothic;
            margin-bottom: 20px;
            @include bp-mobile {
                font-size: 20px;
            }
        }

        .__meta {
            @include clearfix;
            .__left {
                float: left;
                .__category {
                    padding: 0;
                    border-right: 0;
                }
                .__avatar {
                    width: 50px;
                    height: 50px;
                    margin-right: 10px;
                    border-radius: 100%;
                }
                .__author-name {
                    font-size: 14px;
                    font-family: $YuGothic;
                    font-weight: bold;
                }
            }
            .__right {
                @include bp-tablet-and-desktop {
                    float: right;
                }
                margin-top: 12px;

            }

            .__category {
                color: $color__purple;
                display: inline-block;
                font-size: 15px;
                font-weight: bold;
                position: relative;
                padding-right: 20px;
                border-right: 1px dotted #d7d7d7;
                margin-right: 20px;
                margin-left: 10px;
                @include bp-mobile {
                    font-size: 13px;
                    margin-bottom: 5px;
                }
                &:before {
                    content: '\e802';
                    font-family: iconfont;
                    position: relative;
                    margin-right: 1em;
                    z-index: 2;
                }

                &:after {
                    content: '';
                    position: absolute;
                    background-color: #f6f6f6;
                    width: 28px;
                    height: 28px;
                    border-radius: 100%;
                    left: -8px;
                    top: -4px;
                    z-index: 1;
                }

            }

            .__time {
                font-family: $Roboto;
                @include bp-mobile {
                    font-size: 13px;
                }
            }
        }

        .__thumbnail {
            margin-top: 25px;
            margin-bottom: 25px;
            overflow: hidden;
            position: relative;
            @include bp-tablet-and-desktop {
                border-radius: 5px;
            }

            @include bp-mobile {
                margin-top: 20px;
                margin-bottom: 20px;
                margin-left: -16px;
                margin-right: -16px;
            }
            img {
                width: 100%;
            }
            .__text {
                position: absolute;
                background-color: rgba(51, 47, 110, 0.9);
                color: white;
                left: 0;
                bottom: 0;
                padding: 15px;
                font-weight: bold;
                .__text-1 {
                    font-size: 16px;
                }
                .__text-2 {
                    font-size: 25px;
                }
            }

        }

        .case-info {
            margin-top: 40px;
            @include bp-mobile {
                margin-top: 20px;
            }
        }
    }
}

.case-info {
    margin-left: -40px;
    margin-top: 60px;

    @include bp-mobile {
        margin-top: 30px;
    }

    @include clearfix;
    > .__right, > .__left {
        padding-left: 40px;
        width: 50%;
        float: left;
        @include bp-mobile {
            width: 100%;
        }
    }

    > .__right {
        @include bp-mobile {
            margin-top: 20px;
        }
        .__author {
            display: flex;
            align-items: center;
            .__avatar {
                width: 60px;
                img {
                    border-radius: 100%;
                }
            }
            .__right {
                font-weight: bold;
                padding-left: 20px;

                .__title {
                    font-size: 12px;
                    color: $color__purple;
                }
                .__author-name {
                    font-size: 16px;
                }
            }
        }

        .__info {
            background-color: #f6f6f6;
            padding: 15px 20px;
            border-radius: 5px;
            margin-top: 10px;
            font-weight: bold;
            @include bp-mobile {
                margin-left: -16px;
                margin-right: -16px;
                border-radius: 0;
            }
            > .__title {
                font-size: 14px;
                margin-bottom: 1em;
            }

            .__services {
                .__item {
                    line-height: 1.5;
                    a {
                        display: block;
                        position: relative;
                        padding: 10px 0;
                        &:after {
                            content: '\e800';
                            font-family: iconfont;
                            position: absolute;
                            right: 1.5em;
                            top: 50%;
                            transform: translateY(-50%);
                            font-size: 12px;
                        }
                    }
                    &:hover {
                        color: $color__purple;
                    }
                }
            }
        }
    }

    tr {
        &:last-child {
            td, th {
                border-bottom: 1px solid #dddddd;
            }
        }
    }
    th {
        text-align: left;
    }
    td, th {
        font-size: 14px;
        padding: 1.3em 0;
        border-top: 1px solid #dddddd;
        @include bp-mobile {
            font-size: 13px;
        }
    }
}

