.breadcrumb {
    font-size: 12px;
    color: #999999;
    font-weight: bold;
    font-family: $YuGothic;
    //@include list--h(35px);
    margin-top: 15px;
    margin-bottom: 60px;
    white-space: nowrap;

    @include bp-mobile {
        font-size: 10px;
        margin-top: 10px;
        margin-bottom: 20px;
        width: 100%;
        overflow: auto;
    }

    li {
        position: relative;
        display: inline-block;
        margin-right: 35px;
        i {
            display: none;
        }
        @include bp-mobile {
            margin-right: 20px;
        }

        a {
            text-decoration: underline;
        }

        &:not(:last-child) {
            &:after {
                content: '>';
                position: absolute;
                right: -22px;
                @include bp-mobile {
                    right: -15px;
                }
            }
        }
    }
}