/**

  @fonts
--------------------------------------------------------
  base/_fonts.scss
--------------------------------------------------------

  Define the fonts used in this app and declare silent
  classes to be used in your CSS.

  If you are using a webfont that you're hosting, add 
  the @embed-font() mixin from base/_mixin-font-face-importer.scs 
  before you declare your fonts.

  Usage: Create a font-family named "interstate-light" using a font at /fonts/interstate_light-webfont.woff (and other extensions)
  @include embed-font("interstate-light", "/fonts/interstate_light-webfont");
  
  If you are using Google Fonts, you can add the
  import code before you declare your fonts.

  @import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700);

-------------------------------------------------------- */

 @include embed-font("iconfont", "../fonts/iconfont/font/iconfont");

/* @sans
--------------------------------------------------------

  This is your basic sans font.

  Usage: use sans regular on a p
  p {
    @extend %sans--regular;
  }

------------------------------------------------------ */

$YuGothic: "游ゴシック体", "Yu Gothic", YuGothic;
$sans: $YuGothic,-apple-system, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", 'メイリオ', Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
$Roboto: 'Roboto', sans-serif;

%sans--light {
    font-family: $sans;
    font-weight: 300;
    font-style: normal;
}

%sans--regular {
    font-family: $sans;
    font-weight: 400;
    font-style: normal;
}

%sans--semi-bold {
    font-family: $sans;
    font-weight: 600;
    font-style: normal;
}

%sans--bold {
    font-family: $sans;
    font-weight: 700;
    font-style: normal;
}

/* @serif
--------------------------------------------------------

  This is your basic serif font.

  Usage: use serif regular on a p
  p {
    @extend %serif--regular;
  }

-------------------------------------------------------- */

$serif: georgia, times;

%serif--light {
    font-family: $serif;
    font-weight: 300;
    font-style: normal;
}

%serif--regular {
    font-family: $serif;
    font-weight: 400;
    font-style: normal;
}

%serif--italic {
    font-family: $serif;
    font-weight: 400;
    font-style: italic;
}

%serif--semi-bold {
    font-family: $serif;
    font-weight: 600;
    font-style: italic;
}