/**

  @helpers mixins and functions
--------------------------------------------------------
  base/mixins/_helpers.scss
--------------------------------------------------------

 mixins and functions be used globally.

------------------------------------------------------ */

// function to strip units
@function strip-unit($number) {
    @return $number / ($number * 0 + 1);
}

// function to convert px to rem (with out unit)
@function px-to-rem($px) {
    @return strip-unit($px) / strip-unit($root-font-size);
}

// helper function of px-to-rem
@function p2r($px) {
    @return #{px-to-rem($px)}rem;
}

@mixin list--h($margin, $direction: left) {
    list-style: none;
    @include clearfix;
    > li {
        float: $direction;
        margin-right: #{$margin};
        @if $direction == left {
            &.last, &:last-child {
                margin-right: 0;
            }
        } @else {
            &.first, &:first-child {
                margin-right: 0;
            }
        }
    }
}

@mixin stroke($width, $color) {
    $width: $width + 0;
    $shadow: 0 0 0 transparent;
    $i: 0;
    $w: 1;

    @while ($i < $width) {
        $i: $i + 1;
        $j: 0;
        $w: $w + 2;

        @for $r from 1 through $w {
            @for $c from 1 through $w {
                $x: $c - ceil($w / 2);
                $y: $r - ceil($w / 2);

                $shadow: #{$shadow}, #{$x}px #{$y}px 0 $color;
            }
        }
    }

    text-shadow: $shadow;
}

@mixin triangle($size, $color, $direction) {
    $width: nth($size, 1);
    $height: nth($size, length($size));
    $foreground-color: nth($color, 1);
    $background-color: if(length($color) == 2, nth($color, 2), transparent);
    height: 0;
    width: 0;

    @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
        $width: $width / 2;
        $height: if(length($size) > 1, $height, $height/2);

        @if $direction == up {
            border-bottom: $height solid $foreground-color;
            border-left: $width solid $background-color;
            border-right: $width solid $background-color;
        } @else if $direction == right {
            border-bottom: $width solid $background-color;
            border-left: $height solid $foreground-color;
            border-top: $width solid $background-color;
        } @else if $direction == down {
            border-left: $width solid $background-color;
            border-right: $width solid $background-color;
            border-top: $height solid $foreground-color;
        } @else if $direction == left {
            border-bottom: $width solid $background-color;
            border-right: $height solid $foreground-color;
            border-top: $width solid $background-color;
        }
    } @else if ($direction == up-right) or ($direction == up-left) {
        border-top: $height solid $foreground-color;

        @if $direction == up-right {
            border-left: $width solid $background-color;
        } @else if $direction == up-left {
            border-right: $width solid $background-color;
        }
    } @else if ($direction == down-right) or ($direction == down-left) {
        border-bottom: $height solid $foreground-color;

        @if $direction == down-right {
            border-left: $width solid $background-color;
        } @else if $direction == down-left {
            border-right: $width solid $background-color;
        }
    } @else if ($direction == inset-up) {
        border-color: $background-color $background-color $foreground-color;
        border-style: solid;
        border-width: $height $width;
    } @else if ($direction == inset-down) {
        border-color: $foreground-color $background-color $background-color;
        border-style: solid;
        border-width: $height $width;
    } @else if ($direction == inset-right) {
        border-color: $background-color $background-color $background-color $foreground-color;
        border-style: solid;
        border-width: $width $height;
    } @else if ($direction == inset-left) {
        border-color: $background-color $foreground-color $background-color $background-color;
        border-style: solid;
        border-width: $width $height;
    }
}

@mixin utility($from: 0, $to: 100, $step: 5) {
    $i: 0;
    $j: 0;
    @while $j < $to {
        $j: $i * $step;
        .pt#{$j} {
            padding-top: p2r($j) !important;
        }
        .pb#{$j} {
            padding-bottom: p2r($j) !important;
        }
        .ptb#{$j} {
            padding-top: p2r($j) !important;
            padding-bottom: p2r($j) !important;
        }
        .pl#{$j} {
            padding-left: p2r($j) !important;
        }
        .pr#{$j} {
            padding-right: p2r($j) !important;
        }
        .plr#{$j} {
            padding-left: p2r($j) !important;
            padding-right: p2r($j) !important;
        }
        .p#{$j} {
            padding: p2r($j) !important;
        }

        .mt#{$j} {
            margin-top: p2r($j) !important;
        }
        .mb#{$j} {
            margin-bottom: p2r($j) !important;
        }
        .mtb#{$j} {
            margin-top: p2r($j) !important;
            margin-bottom: p2r($j) !important;
        }
        .ml#{$j} {
            margin-left: p2r($j) !important;
        }
        .mr#{$j} {
            margin-right: p2r($j) !important;
        }
        .mlr#{$j} {
            margin-left: p2r($j) !important;
            margin-right: p2r($j) !important;
        }
        .m#{$j} {
            padding: p2r($j) !important;
        }

        $i: $i + 1;
    }
}

@mixin supports($feature-name) {
    @at-root html.#{$feature-name} {
        @content;
    }
}