/**

  @headings
--------------------------------------------------------
  elements/_headings.scss
--------------------------------------------------------

  Base setting for headings.

------------------------------------------------------ */

// H1
.h1 {
    margin-bottom: 1em;
}

// H2
.h2 {
    margin-bottom: 1em;
}

// H3
.h3 {
    margin-bottom: 1em;
}

// H4
.h4 {
    margin-bottom: 1em;
}

// H5
.h5 {
    margin-bottom: 1em;
}

// H6
.h6 {
    margin-bottom: 1em;
}