@mixin embed-font($font-name, $font-file-path, $font-weight: normal, $font-style: normal, $local-font-names: false) {
	$local: '';
	@if $local-font-names {
		@each $local-font-name in $local-font-names {
			$local: #{$local}local("#{$local-font-name}")+',';
		}
	} @else {
		$local: local("#{$font-name}")+',';
	}

	@font-face {
		font-family: $font-name;
		src: url("#{$font-file-path}.eot"); /* IE9 Compat Modes */
		src: url("#{$font-file-path}.eot?#iefix") format('embedded-opentype'), /* IE6-IE8 */
		#{$local}
		url("#{$font-file-path}.woff2") format('woff2'), /* Modern Browsers */
		url("#{$font-file-path}.woff") format('woff'), /* Modern Browsers */
		url("#{$font-file-path}.ttf") format('truetype'), /* Safari, Android, iOS */
		url("#{$font-file-path}.svg#svgFontName") format('svg'); /* Legacy iOS */
		font-weight: unquote($font-weight);
		font-style: unquote($font-style);
	}
}