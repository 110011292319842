/**

  @blocks
--------------------------------------------------------
  modules/_blocks.scss
--------------------------------------------------------

  Blocks contain sections of content.  Blocks can have
  different visual styling, but usually have the same
  structure; one spacing unit margin at the bottom,
  and some have borders, and padding, etc.

-----------------------------------------------------  */

// Blocks add bottom margin
//
.block {
  margin-bottom: 2*$unit;
  // These blocks don't contain visual styling by default, but you can add your own
}


// Block with top and bottom margin
//
.block--ends {
  margin-top: 2*$unit;
  margin-bottom: 2*$unit;
}

