/**

  @grids
---------------------------------------------------------
  layout/_grids.scss
--------------------------------------------------------

  Skyline uses a simple grid system.  It starts with
  a wrapper div that can have a classname of .grid-wrap
  or .gw for short.  Inside of that div, you'll nest
  your grid units.  These are divs with classname of
  either .grid or .g for short.

  To specify the responsive behavior of the grid,
  add width classes from layout/_widths.scss.

  Example: Grid with responsive width classes
  <div class="gw has-lg-gutter has-lg-bottom-spacing no-gutter-transition no-bottom-spacing-transition">
    <div class="g sm-one-whole md-one-half  lg-one-third    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-half  lg-one-third    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-third lg-one-third    xl-one-twelfth">...</div>
    <div class="g sm-one-whole md-one-third lg-one-fifth    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-third lg-three-fifths xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-half  lg-one-fifth    xl-one-twelfth">...</div>
    <div class="g sm-one-whole md-one-half  lg-one-sixth    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-third lg-one-sixth    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-third lg-one-sixth    xl-one-twelfth">...</div>
    <div class="g sm-one-whole md-one-third lg-one-sixth    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-half  lg-one-sixth    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-half  lg-one-sixth    xl-one-twelfth">...</div>
  </div>

  For a grid wrap containing equally-sized grid units, like
  a gallery of thumbnails for instance, use the responsive ups
  classes from layout/_widths.scss. With these classes
  applied to the .grid-wrap/.gw, you won't need to specify
  widths on the individual grid units, just on the wrapper.

  Example: Grid with responsive ups classes
  <div class="gw two-up sm-three-up md-four-up lg-six-up">
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
  </div>
  
  NOTE: see some test styles at the bottom of this partial
  Remove them if you don't need them.

------------------------------------------------------ */

// Grid configuration
// -----------------------------------------------------
//
// Define the point at which grids no longer stack, but float
// You might use a $breakpoint__* variable from _vars.scss or
// any other value that works for you.
$grid__float-at: $breakpoint__xs;

// Determine if generate the `.no-gutter-transition` class.
$generate-no-gutter-transition-classes: true;

// Determine if generate the `.no-bottom-spacing-transition` class.
$generate-no-bottom-spacing-transition-classes: true;

// Grid
.grid,
.g {
    min-height: 1px;
    position: relative;
    display: block;
    width: 100%;

    @include bp-at-least($grid__float-at) {
        float: left;
        @at-root html.flexbox & {
            float: none;
        }
    }
}

// Grid Wrap
.grid-wrap,
.gw {
    display: block;
    display: flex;
    flex-wrap: wrap;
    // Sometimes, ul are used for grid wraps
    list-style-type: none;

    @each $gutter-size-name, $gutter-size-value in $gutters {
        &.has-#{$gutter-size-name}-gutter {

            @if length($gutter-size-value) == 1 {
                margin-left: -1*$gutter-size-value;
                > .grid,
                > .g {
                    padding-left: $gutter-size-value;
                }

            } @else {
                $max-amount: nth($gutter-size-value, 1);
                $min-amount: nth($gutter-size-value, 2);

                @include property(margin-left, -1*$max-amount, -1*$min-amount);

                > .grid,
                > .g {
                    @include property(padding-left, $max-amount, $min-amount);
                }

                @if $generate-no-gutter-transition-classes == true {
                    &.no-gutter-transition {
                        margin-left: -1*$min-amount;
                        @include bp-at-least($stop-shrinking-width) {
                            margin-left: -1*$max-amount;
                        }

                        > .grid,
                        > .g {
                            padding-left: $min-amount;
                            @include bp-at-least($stop-shrinking-width) {
                                padding-left: $max-amount;
                            }
                        }
                    }
                }
            }
        }
    }

    // Define the gird bottom spacings
    @if global-variable-exists('grid-bottom-spacings') == false {
        $grid-bottom-spacings: $gutters !global;
    }

    @each $grid-bottom-spacing-size-name, $grid-bottom-spacing-size-value in $grid-bottom-spacings {
        &.has-#{$grid-bottom-spacing-size-name}-bottom-spacing {

            @if length($grid-bottom-spacing-size-value) == 1 {
                margin-bottom: -1*$grid-bottom-spacing-size-value;
                > .grid,
                > .g {
                    padding-bottom: $grid-bottom-spacing-size-value;
                }
            } @else {
                $max-amount: nth($grid-bottom-spacing-size-value, 1);
                $min-amount: nth($grid-bottom-spacing-size-value, 2);

                @include property(margin-bottom, -1*$max-amount, -1*$min-amount);

                > .grid,
                > .g {
                    @include property(padding-bottom, $max-amount, $min-amount);
                }

                @if $generate-no-bottom-spacing-transition-classes == true {
                    &.no-bottom-spacing-transition {
                        margin-bottom: -1*$min-amount;
                        @include bp-at-least($stop-shrinking-width) {
                            margin-bottom: -1*$max-amount;
                        }

                        > .grid,
                        > .g {
                            padding-bottom: $min-amount;
                            @include bp-at-least($stop-shrinking-width) {
                                padding-bottom: $max-amount;
                            }
                        }
                    }
                }
            }
        }
    }
}
