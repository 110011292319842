/**

  @ups
--------------------------------------------------------
  layout/_ups.scss
--------------------------------------------------------

  UPS?

  Yep, ups.  These are global classes that will set
  their direct children to a specific width.  You will
  mainly use these in grids, and maybe with tabs or
  navigations.

  For instance: four-up will set each child to 25%

  Set the ups configuration variables below in this partial
  to specify how many or how few classes you want available
  in your project.

  Example: Ups and grids
  <div class="gw two-up">
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
  </div><!--/gw-->
  <div class="gw three-up">
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
  </div><!--/gw-->

  Ups work like widths in that they have responsive behavior.  If
  you prepend an up class with a responsive prefix, like (s for small
  or m for medium) the behaviour will only kick in at those
  viewports.

  For example, if you want your gallery to be two-up at the smallest
  viewport, four-up at medium, six-up at large, and eight-up on the largest then use
  this.

  Example: Responsive Ups
  <div class="gw two-up m-four-up l-six-up xl-eight-up">
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
  </div><!--/gw-->
  
  

-----------------------------------------------------  */





// Ups configuration
// Define the number of ups you want to use at each
// breakpoint. The breakpoints below match the values that
// you defined in base/_vars.scss 
//
$ups__number-of-ups-at-breakpoint: (
        n: 12,
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
        xxl: 12
);





// ----------------------------------------------------
// Function to make easy-to-read class from number
// ----------------------------------------------------

// Define english for each integer
// NOTE: this is also defined in layout/_widths.scss but
// we're redefining it to avoid making _widths.scss a
// dependency. In a future release, this will be included
// in something like base/_definitions.scss.
//
$english-by-number: (
        1: "one",
        2: "two",
        3: "three",
        4: "four",
        5: "five",
        6: "six",
        7: "seven",
        8: "eight",
        9: "nine",
        10: "ten",
        11: "eleven",
        12: "twelve"
);



// Mixin to return easy-to-read up class from 2 numbers
// Accepts a $numerator, $denominator, and optional breakpoint
// 
@mixin easy-to-read-up($n, $breakpoint:"") {
    // Define optional prefix
    //
    $breakpoint-prefix: ".";
    @if ($breakpoint != "n") {
        @if map-has-key($ups__number-of-ups-at-breakpoint, $breakpoint) {
            $breakpoint-prefix: ".#{unquote($breakpoint)}-";
        }
    }

    // Define english and delimiter '-' for first part of class
    //
    $up-first-part: "#{map-get($english-by-number, $n)}-";

    // Here's the class
    #{$breakpoint-prefix}#{$up-first-part}up > * {
        width: (100 / $n)*1%;
    }
}



// Loop through all breakpoints in $ups__number-of-ups-at-breakpoint
@each $breakpoint, $num-ups in $ups__number-of-ups-at-breakpoint {
    @if ($num-ups > 0) {
        @if ($breakpoint != "n") {
            /*

              Responsive ups at $breakpoint__#{$breakpoint}

            ---------------------------------------------------- */
            @include bp-at-least (map-get($breakpoints, $breakpoint)) {
                @for $i from 1 through $num-ups {
                    @include easy-to-read-up($i, $breakpoint);
                }
            }
        } @else {
            /*

              Widths at default Viewport

            ---------------------------------------------------- */
            @for $i from 1 through $num-ups {
                @include easy-to-read-up($i, $breakpoint);
            }
        }
    }
}
