@charset "UTF-8";
/****

  MAIN STYLESHEET for [* Site Name Here *]

---------------------------------------------------------

  BUILT WITH SKYLINE: EVOLVING CSS ARCHITECTURE

  See README.md for info and credits


  TABLE OF CONTENTS
  @toc

  - Base ............ foundations, mixins, functions, vars
  - Elements ........ single elements
  - Layout .......... layout objects like grids, containers
  - Modules ......... objects created by combining elements

---------------------------------------------------------  */
/***

  @base
---------------------------------------------------------

  Your CSS begins with some global variables, a reset which
  is basically Normalize, some mixins, and a print stylesheet.

-----------------------------------------------------  */
/**

  @variables
---------------------------------------------------------
  base/_vars.scss
--------------------------------------------------------

  Global variables are defined here.  Define your colors,
  spacing and grid settings.

-----------------------------------------------------  */
/* @colors
--------------------------------------------------------

  All color variables are defined here.

  We use real color names here.  Although naming something
  like 'brand' is in fact more generic and reusable, we've
  found that you end up creating variables like $brand-2,
  $brand-alt, $brand-complimentary etc.  While coding,
  it's too easy to forget what those mean.

  If the design changes drastically and the brand changes
  from, let's say, blue to green, just do a global
  find/replace - that's what text editors are for.

------------------------------------------------------ */
/* @breakpoints
---------------------------------------------------------

  Define global breakpoints as xs, sm, md, lg, xl, xxl

--------------------------------------------------------- */
/* @global spacing unit
---------------------------------------------------------

  Define an em value for $unit.  $unit is a helpful little
  variable that serves to keep your spacing consistent.

  Most often, 1 $unit is equal to your baseline height.  So
  if your baseline height is 1.125ems (which is 18px), that's
  the value of 1 $unit.

--------------------------------------------------------- */
/* @root font size
---------------------------------------------------------

  The font-size of `<html>` element.

--------------------------------------------------------- */
/**

  @reset
---------------------------------------------------------
  base/_reset.scss
--------------------------------------------------------

  This reset institutes the border-box box model and then
  block and margin/padding resets.

  Throughout Skyline, each partial has it's own resets.
  This allows you to only use what you need.

  Credit goes to Normalize
  http://normalize.css v2.1.0 http://git.io/normalize

--------------------------------------------------------- */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary,
audio,
canvas,
video {
  display: block; }

/**

  @helpers mixins and functions
--------------------------------------------------------
  base/mixins/_helpers.scss
--------------------------------------------------------

 mixins and functions be used globally.

------------------------------------------------------ */
/**

  @breakpoints mixin
-----------------------------------------------------
  base/_mixin-breakpoints.scss
-----------------------------------------------------

  This mixin makes using inline media queries easy.
  Options include bp-at-least for min-width, bp-until
  for max-width, and bp-between for min and max widths.

  You can use your breakpoint vars, or any other value.

  Usage: styles at medium breakpoint
  .some-element {
    @include bp-at-least($breakpoint__md){
      your-styles: go-here;
    }
  }

  Usage: styles at custom breakpoint
  .another-element {
    @include bp-until(83.45em){
      your-styles: go-here;
    }
  }

-----------------------------------------------------  */
/**

  @clearfix mixin
---------------------------------------------------------
  base/_clearfix.scss
--------------------------------------------------------

  Use @include clearfix(); in your CSS

  Usage: clearfix mixin
  .element-to-clearfix {
    @include cleafix();
  }


--------------------------------------------------------- */
/**

  @fonts
--------------------------------------------------------
  base/_fonts.scss
--------------------------------------------------------

  Define the fonts used in this app and declare silent
  classes to be used in your CSS.

  If you are using a webfont that you're hosting, add 
  the @embed-font() mixin from base/_mixin-font-face-importer.scs 
  before you declare your fonts.

  Usage: Create a font-family named "interstate-light" using a font at /fonts/interstate_light-webfont.woff (and other extensions)
  @include embed-font("interstate-light", "/fonts/interstate_light-webfont");
  
  If you are using Google Fonts, you can add the
  import code before you declare your fonts.

  @import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700);

-------------------------------------------------------- */
@font-face {
  font-family: "iconfont";
  src: url("../fonts/iconfont/font/iconfont.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/iconfont/font/iconfont.eot?#iefix") format("embedded-opentype"), local("iconfont"), url("../fonts/iconfont/font/iconfont.woff2") format("woff2"), url("../fonts/iconfont/font/iconfont.woff") format("woff"), url("../fonts/iconfont/font/iconfont.ttf") format("truetype"), url("../fonts/iconfont/font/iconfont.svg#svgFontName") format("svg");
  /* Legacy iOS */
  font-weight: normal;
  font-style: normal; }

/* @sans
--------------------------------------------------------

  This is your basic sans font.

  Usage: use sans regular on a p
  p {
    @extend %sans--regular;
  }

------------------------------------------------------ */
body, .button {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic, -apple-system, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: 400;
  font-style: normal; }

/* @serif
--------------------------------------------------------

  This is your basic serif font.

  Usage: use serif regular on a p
  p {
    @extend %serif--regular;
  }

-------------------------------------------------------- */
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder, select::-webkit-input-placeholder, input:-moz-placeholder, textarea:-moz-placeholder, select:-moz-placeholder {
  font-family: georgia, times;
  font-weight: 300;
  font-style: normal; }

.pt0 {
  padding-top: 0rem !important; }

.pb0 {
  padding-bottom: 0rem !important; }

.ptb0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important; }

.pl0 {
  padding-left: 0rem !important; }

.pr0 {
  padding-right: 0rem !important; }

.plr0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important; }

.p0 {
  padding: 0rem !important; }

.mt0 {
  margin-top: 0rem !important; }

.mb0 {
  margin-bottom: 0rem !important; }

.mtb0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important; }

.ml0 {
  margin-left: 0rem !important; }

.mr0 {
  margin-right: 0rem !important; }

.mlr0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important; }

.m0 {
  padding: 0rem !important; }

.pt5 {
  padding-top: 0.3125rem !important; }

.pb5 {
  padding-bottom: 0.3125rem !important; }

.ptb5 {
  padding-top: 0.3125rem !important;
  padding-bottom: 0.3125rem !important; }

.pl5 {
  padding-left: 0.3125rem !important; }

.pr5 {
  padding-right: 0.3125rem !important; }

.plr5 {
  padding-left: 0.3125rem !important;
  padding-right: 0.3125rem !important; }

.p5 {
  padding: 0.3125rem !important; }

.mt5 {
  margin-top: 0.3125rem !important; }

.mb5 {
  margin-bottom: 0.3125rem !important; }

.mtb5 {
  margin-top: 0.3125rem !important;
  margin-bottom: 0.3125rem !important; }

.ml5 {
  margin-left: 0.3125rem !important; }

.mr5 {
  margin-right: 0.3125rem !important; }

.mlr5 {
  margin-left: 0.3125rem !important;
  margin-right: 0.3125rem !important; }

.m5 {
  padding: 0.3125rem !important; }

.pt10 {
  padding-top: 0.625rem !important; }

.pb10 {
  padding-bottom: 0.625rem !important; }

.ptb10 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important; }

.pl10 {
  padding-left: 0.625rem !important; }

.pr10 {
  padding-right: 0.625rem !important; }

.plr10 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important; }

.p10 {
  padding: 0.625rem !important; }

.mt10 {
  margin-top: 0.625rem !important; }

.mb10 {
  margin-bottom: 0.625rem !important; }

.mtb10 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important; }

.ml10 {
  margin-left: 0.625rem !important; }

.mr10 {
  margin-right: 0.625rem !important; }

.mlr10 {
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important; }

.m10 {
  padding: 0.625rem !important; }

.pt15 {
  padding-top: 0.9375rem !important; }

.pb15 {
  padding-bottom: 0.9375rem !important; }

.ptb15 {
  padding-top: 0.9375rem !important;
  padding-bottom: 0.9375rem !important; }

.pl15 {
  padding-left: 0.9375rem !important; }

.pr15 {
  padding-right: 0.9375rem !important; }

.plr15 {
  padding-left: 0.9375rem !important;
  padding-right: 0.9375rem !important; }

.p15 {
  padding: 0.9375rem !important; }

.mt15 {
  margin-top: 0.9375rem !important; }

.mb15 {
  margin-bottom: 0.9375rem !important; }

.mtb15 {
  margin-top: 0.9375rem !important;
  margin-bottom: 0.9375rem !important; }

.ml15 {
  margin-left: 0.9375rem !important; }

.mr15 {
  margin-right: 0.9375rem !important; }

.mlr15 {
  margin-left: 0.9375rem !important;
  margin-right: 0.9375rem !important; }

.m15 {
  padding: 0.9375rem !important; }

.pt20 {
  padding-top: 1.25rem !important; }

.pb20 {
  padding-bottom: 1.25rem !important; }

.ptb20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important; }

.pl20 {
  padding-left: 1.25rem !important; }

.pr20 {
  padding-right: 1.25rem !important; }

.plr20 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important; }

.p20 {
  padding: 1.25rem !important; }

.mt20 {
  margin-top: 1.25rem !important; }

.mb20 {
  margin-bottom: 1.25rem !important; }

.mtb20 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important; }

.ml20 {
  margin-left: 1.25rem !important; }

.mr20 {
  margin-right: 1.25rem !important; }

.mlr20 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important; }

.m20 {
  padding: 1.25rem !important; }

.pt25 {
  padding-top: 1.5625rem !important; }

.pb25 {
  padding-bottom: 1.5625rem !important; }

.ptb25 {
  padding-top: 1.5625rem !important;
  padding-bottom: 1.5625rem !important; }

.pl25 {
  padding-left: 1.5625rem !important; }

.pr25 {
  padding-right: 1.5625rem !important; }

.plr25 {
  padding-left: 1.5625rem !important;
  padding-right: 1.5625rem !important; }

.p25 {
  padding: 1.5625rem !important; }

.mt25 {
  margin-top: 1.5625rem !important; }

.mb25 {
  margin-bottom: 1.5625rem !important; }

.mtb25 {
  margin-top: 1.5625rem !important;
  margin-bottom: 1.5625rem !important; }

.ml25 {
  margin-left: 1.5625rem !important; }

.mr25 {
  margin-right: 1.5625rem !important; }

.mlr25 {
  margin-left: 1.5625rem !important;
  margin-right: 1.5625rem !important; }

.m25 {
  padding: 1.5625rem !important; }

.pt30 {
  padding-top: 1.875rem !important; }

.pb30 {
  padding-bottom: 1.875rem !important; }

.ptb30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important; }

.pl30 {
  padding-left: 1.875rem !important; }

.pr30 {
  padding-right: 1.875rem !important; }

.plr30 {
  padding-left: 1.875rem !important;
  padding-right: 1.875rem !important; }

.p30 {
  padding: 1.875rem !important; }

.mt30 {
  margin-top: 1.875rem !important; }

.mb30 {
  margin-bottom: 1.875rem !important; }

.mtb30 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important; }

.ml30 {
  margin-left: 1.875rem !important; }

.mr30 {
  margin-right: 1.875rem !important; }

.mlr30 {
  margin-left: 1.875rem !important;
  margin-right: 1.875rem !important; }

.m30 {
  padding: 1.875rem !important; }

.pt35 {
  padding-top: 2.1875rem !important; }

.pb35 {
  padding-bottom: 2.1875rem !important; }

.ptb35 {
  padding-top: 2.1875rem !important;
  padding-bottom: 2.1875rem !important; }

.pl35 {
  padding-left: 2.1875rem !important; }

.pr35 {
  padding-right: 2.1875rem !important; }

.plr35 {
  padding-left: 2.1875rem !important;
  padding-right: 2.1875rem !important; }

.p35 {
  padding: 2.1875rem !important; }

.mt35 {
  margin-top: 2.1875rem !important; }

.mb35 {
  margin-bottom: 2.1875rem !important; }

.mtb35 {
  margin-top: 2.1875rem !important;
  margin-bottom: 2.1875rem !important; }

.ml35 {
  margin-left: 2.1875rem !important; }

.mr35 {
  margin-right: 2.1875rem !important; }

.mlr35 {
  margin-left: 2.1875rem !important;
  margin-right: 2.1875rem !important; }

.m35 {
  padding: 2.1875rem !important; }

.pt40 {
  padding-top: 2.5rem !important; }

.pb40 {
  padding-bottom: 2.5rem !important; }

.ptb40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important; }

.pl40 {
  padding-left: 2.5rem !important; }

.pr40 {
  padding-right: 2.5rem !important; }

.plr40 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important; }

.p40 {
  padding: 2.5rem !important; }

.mt40 {
  margin-top: 2.5rem !important; }

.mb40 {
  margin-bottom: 2.5rem !important; }

.mtb40 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important; }

.ml40 {
  margin-left: 2.5rem !important; }

.mr40 {
  margin-right: 2.5rem !important; }

.mlr40 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important; }

.m40 {
  padding: 2.5rem !important; }

.pt45 {
  padding-top: 2.8125rem !important; }

.pb45 {
  padding-bottom: 2.8125rem !important; }

.ptb45 {
  padding-top: 2.8125rem !important;
  padding-bottom: 2.8125rem !important; }

.pl45 {
  padding-left: 2.8125rem !important; }

.pr45 {
  padding-right: 2.8125rem !important; }

.plr45 {
  padding-left: 2.8125rem !important;
  padding-right: 2.8125rem !important; }

.p45 {
  padding: 2.8125rem !important; }

.mt45 {
  margin-top: 2.8125rem !important; }

.mb45 {
  margin-bottom: 2.8125rem !important; }

.mtb45 {
  margin-top: 2.8125rem !important;
  margin-bottom: 2.8125rem !important; }

.ml45 {
  margin-left: 2.8125rem !important; }

.mr45 {
  margin-right: 2.8125rem !important; }

.mlr45 {
  margin-left: 2.8125rem !important;
  margin-right: 2.8125rem !important; }

.m45 {
  padding: 2.8125rem !important; }

.pt50 {
  padding-top: 3.125rem !important; }

.pb50 {
  padding-bottom: 3.125rem !important; }

.ptb50 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important; }

.pl50 {
  padding-left: 3.125rem !important; }

.pr50 {
  padding-right: 3.125rem !important; }

.plr50 {
  padding-left: 3.125rem !important;
  padding-right: 3.125rem !important; }

.p50 {
  padding: 3.125rem !important; }

.mt50 {
  margin-top: 3.125rem !important; }

.mb50 {
  margin-bottom: 3.125rem !important; }

.mtb50 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important; }

.ml50 {
  margin-left: 3.125rem !important; }

.mr50 {
  margin-right: 3.125rem !important; }

.mlr50 {
  margin-left: 3.125rem !important;
  margin-right: 3.125rem !important; }

.m50 {
  padding: 3.125rem !important; }

.pt55 {
  padding-top: 3.4375rem !important; }

.pb55 {
  padding-bottom: 3.4375rem !important; }

.ptb55 {
  padding-top: 3.4375rem !important;
  padding-bottom: 3.4375rem !important; }

.pl55 {
  padding-left: 3.4375rem !important; }

.pr55 {
  padding-right: 3.4375rem !important; }

.plr55 {
  padding-left: 3.4375rem !important;
  padding-right: 3.4375rem !important; }

.p55 {
  padding: 3.4375rem !important; }

.mt55 {
  margin-top: 3.4375rem !important; }

.mb55 {
  margin-bottom: 3.4375rem !important; }

.mtb55 {
  margin-top: 3.4375rem !important;
  margin-bottom: 3.4375rem !important; }

.ml55 {
  margin-left: 3.4375rem !important; }

.mr55 {
  margin-right: 3.4375rem !important; }

.mlr55 {
  margin-left: 3.4375rem !important;
  margin-right: 3.4375rem !important; }

.m55 {
  padding: 3.4375rem !important; }

.pt60 {
  padding-top: 3.75rem !important; }

.pb60 {
  padding-bottom: 3.75rem !important; }

.ptb60 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important; }

.pl60 {
  padding-left: 3.75rem !important; }

.pr60 {
  padding-right: 3.75rem !important; }

.plr60 {
  padding-left: 3.75rem !important;
  padding-right: 3.75rem !important; }

.p60 {
  padding: 3.75rem !important; }

.mt60 {
  margin-top: 3.75rem !important; }

.mb60 {
  margin-bottom: 3.75rem !important; }

.mtb60 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important; }

.ml60 {
  margin-left: 3.75rem !important; }

.mr60 {
  margin-right: 3.75rem !important; }

.mlr60 {
  margin-left: 3.75rem !important;
  margin-right: 3.75rem !important; }

.m60 {
  padding: 3.75rem !important; }

.pt65 {
  padding-top: 4.0625rem !important; }

.pb65 {
  padding-bottom: 4.0625rem !important; }

.ptb65 {
  padding-top: 4.0625rem !important;
  padding-bottom: 4.0625rem !important; }

.pl65 {
  padding-left: 4.0625rem !important; }

.pr65 {
  padding-right: 4.0625rem !important; }

.plr65 {
  padding-left: 4.0625rem !important;
  padding-right: 4.0625rem !important; }

.p65 {
  padding: 4.0625rem !important; }

.mt65 {
  margin-top: 4.0625rem !important; }

.mb65 {
  margin-bottom: 4.0625rem !important; }

.mtb65 {
  margin-top: 4.0625rem !important;
  margin-bottom: 4.0625rem !important; }

.ml65 {
  margin-left: 4.0625rem !important; }

.mr65 {
  margin-right: 4.0625rem !important; }

.mlr65 {
  margin-left: 4.0625rem !important;
  margin-right: 4.0625rem !important; }

.m65 {
  padding: 4.0625rem !important; }

.pt70 {
  padding-top: 4.375rem !important; }

.pb70 {
  padding-bottom: 4.375rem !important; }

.ptb70 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important; }

.pl70 {
  padding-left: 4.375rem !important; }

.pr70 {
  padding-right: 4.375rem !important; }

.plr70 {
  padding-left: 4.375rem !important;
  padding-right: 4.375rem !important; }

.p70 {
  padding: 4.375rem !important; }

.mt70 {
  margin-top: 4.375rem !important; }

.mb70 {
  margin-bottom: 4.375rem !important; }

.mtb70 {
  margin-top: 4.375rem !important;
  margin-bottom: 4.375rem !important; }

.ml70 {
  margin-left: 4.375rem !important; }

.mr70 {
  margin-right: 4.375rem !important; }

.mlr70 {
  margin-left: 4.375rem !important;
  margin-right: 4.375rem !important; }

.m70 {
  padding: 4.375rem !important; }

.pt75 {
  padding-top: 4.6875rem !important; }

.pb75 {
  padding-bottom: 4.6875rem !important; }

.ptb75 {
  padding-top: 4.6875rem !important;
  padding-bottom: 4.6875rem !important; }

.pl75 {
  padding-left: 4.6875rem !important; }

.pr75 {
  padding-right: 4.6875rem !important; }

.plr75 {
  padding-left: 4.6875rem !important;
  padding-right: 4.6875rem !important; }

.p75 {
  padding: 4.6875rem !important; }

.mt75 {
  margin-top: 4.6875rem !important; }

.mb75 {
  margin-bottom: 4.6875rem !important; }

.mtb75 {
  margin-top: 4.6875rem !important;
  margin-bottom: 4.6875rem !important; }

.ml75 {
  margin-left: 4.6875rem !important; }

.mr75 {
  margin-right: 4.6875rem !important; }

.mlr75 {
  margin-left: 4.6875rem !important;
  margin-right: 4.6875rem !important; }

.m75 {
  padding: 4.6875rem !important; }

.pt80 {
  padding-top: 5rem !important; }

.pb80 {
  padding-bottom: 5rem !important; }

.ptb80 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important; }

.pl80 {
  padding-left: 5rem !important; }

.pr80 {
  padding-right: 5rem !important; }

.plr80 {
  padding-left: 5rem !important;
  padding-right: 5rem !important; }

.p80 {
  padding: 5rem !important; }

.mt80 {
  margin-top: 5rem !important; }

.mb80 {
  margin-bottom: 5rem !important; }

.mtb80 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important; }

.ml80 {
  margin-left: 5rem !important; }

.mr80 {
  margin-right: 5rem !important; }

.mlr80 {
  margin-left: 5rem !important;
  margin-right: 5rem !important; }

.m80 {
  padding: 5rem !important; }

.pt85 {
  padding-top: 5.3125rem !important; }

.pb85 {
  padding-bottom: 5.3125rem !important; }

.ptb85 {
  padding-top: 5.3125rem !important;
  padding-bottom: 5.3125rem !important; }

.pl85 {
  padding-left: 5.3125rem !important; }

.pr85 {
  padding-right: 5.3125rem !important; }

.plr85 {
  padding-left: 5.3125rem !important;
  padding-right: 5.3125rem !important; }

.p85 {
  padding: 5.3125rem !important; }

.mt85 {
  margin-top: 5.3125rem !important; }

.mb85 {
  margin-bottom: 5.3125rem !important; }

.mtb85 {
  margin-top: 5.3125rem !important;
  margin-bottom: 5.3125rem !important; }

.ml85 {
  margin-left: 5.3125rem !important; }

.mr85 {
  margin-right: 5.3125rem !important; }

.mlr85 {
  margin-left: 5.3125rem !important;
  margin-right: 5.3125rem !important; }

.m85 {
  padding: 5.3125rem !important; }

.pt90 {
  padding-top: 5.625rem !important; }

.pb90 {
  padding-bottom: 5.625rem !important; }

.ptb90 {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important; }

.pl90 {
  padding-left: 5.625rem !important; }

.pr90 {
  padding-right: 5.625rem !important; }

.plr90 {
  padding-left: 5.625rem !important;
  padding-right: 5.625rem !important; }

.p90 {
  padding: 5.625rem !important; }

.mt90 {
  margin-top: 5.625rem !important; }

.mb90 {
  margin-bottom: 5.625rem !important; }

.mtb90 {
  margin-top: 5.625rem !important;
  margin-bottom: 5.625rem !important; }

.ml90 {
  margin-left: 5.625rem !important; }

.mr90 {
  margin-right: 5.625rem !important; }

.mlr90 {
  margin-left: 5.625rem !important;
  margin-right: 5.625rem !important; }

.m90 {
  padding: 5.625rem !important; }

.pt95 {
  padding-top: 5.9375rem !important; }

.pb95 {
  padding-bottom: 5.9375rem !important; }

.ptb95 {
  padding-top: 5.9375rem !important;
  padding-bottom: 5.9375rem !important; }

.pl95 {
  padding-left: 5.9375rem !important; }

.pr95 {
  padding-right: 5.9375rem !important; }

.plr95 {
  padding-left: 5.9375rem !important;
  padding-right: 5.9375rem !important; }

.p95 {
  padding: 5.9375rem !important; }

.mt95 {
  margin-top: 5.9375rem !important; }

.mb95 {
  margin-bottom: 5.9375rem !important; }

.mtb95 {
  margin-top: 5.9375rem !important;
  margin-bottom: 5.9375rem !important; }

.ml95 {
  margin-left: 5.9375rem !important; }

.mr95 {
  margin-right: 5.9375rem !important; }

.mlr95 {
  margin-left: 5.9375rem !important;
  margin-right: 5.9375rem !important; }

.m95 {
  padding: 5.9375rem !important; }

.pt100 {
  padding-top: 6.25rem !important; }

.pb100 {
  padding-bottom: 6.25rem !important; }

.ptb100 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important; }

.pl100 {
  padding-left: 6.25rem !important; }

.pr100 {
  padding-right: 6.25rem !important; }

.plr100 {
  padding-left: 6.25rem !important;
  padding-right: 6.25rem !important; }

.p100 {
  padding: 6.25rem !important; }

.mt100 {
  margin-top: 6.25rem !important; }

.mb100 {
  margin-bottom: 6.25rem !important; }

.mtb100 {
  margin-top: 6.25rem !important;
  margin-bottom: 6.25rem !important; }

.ml100 {
  margin-left: 6.25rem !important; }

.mr100 {
  margin-right: 6.25rem !important; }

.mlr100 {
  margin-left: 6.25rem !important;
  margin-right: 6.25rem !important; }

.m100 {
  padding: 6.25rem !important; }

.is-bold {
  font-weight: bold; }

.has-text-right {
  text-align: right; }

.has-text-left {
  text-align: left; }

.has-text-centered {
  text-align: center; }

.is-uppercase {
  text-transform: uppercase; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.inline-flex {
  display: inline-flex; }

.inline {
  display: inline; }

.is-fullwidth {
  width: 100%; }

.clear {
  clear: both; }

.is-clearfix {
  zoom: 1; }
  .is-clearfix:before, .is-clearfix:after {
    content: " ";
    display: table; }
  .is-clearfix:after {
    clear: both; }

.is-paddingless {
  padding: 0; }

.is-marginless {
  margin: 0; }

.is-pulled-left {
  float: left; }

.is-pulled-right {
  float: right; }

.is-clipped {
  overflow: hidden !important; }

.is-overlay {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.is-disabled {
  pointer-events: none; }

.is-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/**

  @print
--------------------------------------------------------
  base/_print.scss
--------------------------------------------------------

  Print Stylesheet from http://h5bp.com/r

-----------------------------------------------------  */
/* ==========================================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   CREDIT: HTML5 Boilerplate
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group;
    /* h5bp.com/t */ }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

/***

  @elements
---------------------------------------------------------

  Global styling of elements.

---------------------------------------------------------  */
/**

  @body
--------------------------------------------------------
  elements/_body.scss
--------------------------------------------------------

  Body should contain the document's default font and
  background color for the content, if that background
  color is different than the html.

------------------------------------------------------ */
body {
  font-feature-settings: "kern";
  line-height: 1.5;
  color: #1f1f1f;
  background: #fff; }
  @media screen and (max-width: 34.385em) {
    body {
      padding-top: 56px; } }

/**

  @buttons
--------------------------------------------------------
  elements/_buttons.scss
--------------------------------------------------------

  Base settings for buttons.

------------------------------------------------------ */
button:focus, button:active {
  outline: none;
  box-shadow: none;
  border: none; }

.button {
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  background: #332f6e;
  font-weight: bold;
  color: white;
  border: none;
  position: relative;
  border-radius: 5px;
  font-size: 14px;
  text-decoration: none;
  letter-spacing: 0.03em;
  padding: 0.5em 1.25em; }
  .button:hover, .button:focus, .button:active {
    outline: none;
    opacity: .85; }
  .button:after {
    content: '\e800';
    font-family: iconfont;
    position: absolute;
    right: 1.5em;
    top: 50%;
    transform: translateY(-50%); }
  .button.no-arrow:after {
    display: none; }
  .button.black {
    background-color: #1f1f1f; }
  .button.white {
    background-color: white;
    color: #332f6e; }
  .button.bordered {
    color: #332f6e;
    border: 2px solid;
    background: transparent; }
    .button.bordered.white {
      color: white; }
  .button.large {
    font-size: 16px;
    padding: 1.2em 5em; }
    @media screen and (max-width: 34.385em) {
      .button.large {
        font-size: 14px;
        padding: 1em 4em; } }
  .button.x-large {
    padding: 1.8em 5em; }
  .button.md {
    font-size: 14px;
    padding: 1.2em 5em; }

/**

  @code
--------------------------------------------------------
  elements/_code.scss
--------------------------------------------------------

  Code elements like <code> and <pre>

-----------------------------------------------------  */
code {
  vertical-align: middle;
  padding: .25em .5em;
  background: rgba(193, 199, 208, 0.3); }

/**

  @forms
---------------------------------------------------------
  elements/_forms.scss
--------------------------------------------------------

  Base styles for all forms and form related elements

  Below you'll set styles for all forms, and if you have
  any specific kinds of forms, like a search form that you
  create a classname of form--search, put that at the
  bottom of this partial.

  This scss partial is pretty big.  Here's how it is
  organized.

  * Base styling on form elements

  * Styles for form & form modules


--------------------------------------------------------- */
input, textarea, select {
  -webkit-appearance: none;
  padding: 1em 1.5em;
  border-radius: 5px;
  width: 100%;
  color: #1f1f1f;
  background-color: white;
  transition: background-color 0.2s ease-out;
  border: none;
  font-size: 14px;
  font-weight: bold; }
  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder, select::-webkit-input-placeholder {
    color: #8e8e8e; }
  input:-moz-placeholder, textarea:-moz-placeholder, select:-moz-placeholder {
    color: #8e8e8e; }
  input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
    color: #8e8e8e; }
  input:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder {
    color: #8e8e8e; }
  input:focus, textarea:focus, select:focus {
    outline: none; }
    input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder, select:focus::-webkit-input-placeholder {
      opacity: .3; }
    input:focus:-moz-placeholder, textarea:focus:-moz-placeholder, select:focus:-moz-placeholder {
      opacity: .3; }
    input:focus::-moz-placeholder, textarea:focus::-moz-placeholder, select:focus::-moz-placeholder {
      opacity: .3; }
    input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder, select:focus:-ms-input-placeholder {
      opacity: .3; }
  input.m--error, textarea.m--error, select.m--error {
    background-color: #F8D2D2; }

textarea {
  min-height: 10em; }

input[type=radio] {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  padding: 0;
  border-radius: 50%;
  border: 1px solid #444;
  background: transparent; }
  input[type=radio]:checked {
    background: #222; }

select {
  background-color: #f6f6f6;
  position: relative; }

.select {
  position: relative; }
  .select:after {
    font-family: iconfont;
    content: '\e805';
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%); }

/**

  @headings
--------------------------------------------------------
  elements/_headings.scss
--------------------------------------------------------

  Base setting for headings.

------------------------------------------------------ */
.h1 {
  margin-bottom: 1em; }

.h2 {
  margin-bottom: 1em; }

.h3 {
  margin-bottom: 1em; }

.h4 {
  margin-bottom: 1em; }

.h5 {
  margin-bottom: 1em; }

.h6 {
  margin-bottom: 1em; }

/**

  @tables
---------------------------------------------------------
  elements/_tables.scss
--------------------------------------------------------

  Base styling for tables

--------------------------------------------------------- */
table {
  width: 100%;
  border-collapse: collapse;
  border: none; }

/**

   @html
--------------------------------------------------------
  elements/_html.scss
--------------------------------------------------------

  HTML should contain the site's default background color
  and font-size

------------------------------------------------------ */
@media screen and (min-width: 34.385em) {
  html {
    min-width: 1360px; } }

* {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility; }

/**

  @images
---------------------------------------------------------
  elements/_images.scss
--------------------------------------------------------

  Base styling for images, plus some helpful utility
  classes that modify images.

  Note: You won't add any styling to these image classes,
  these are just utility classes

--------------------------------------------------------- */
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: none; }

/* @img--natural
--------------------------------------------------------

  Natural Images are 100% max-width, but their natural width by default

------------------------------------------------------ */
.img--natural {
  width: auto; }

/* @image placement
--------------------------------------------------------

  Here are a few helper classes to position images, particularly
  helpful inside paragraphs.

  Example: Image Right
  <p><img class="img--right" src="http://fillmurray.com/400/300"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

  Example: Image, Natural Width
  <p><img class="img--left" src="http://fillmurray.com/400/300"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

  Example: Image, Center
  <p><img class="img--center" src="http://fillmurray.com/400/300"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

------------------------------------------------------ */
.img--right {
  width: auto;
  float: right;
  margin-bottom: 20px;
  margin-left: 20px; }

.img--left {
  width: auto;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px; }

.img--center {
  width: auto;
  display: block;
  margin-right: auto;
  margin-bottom: 20px;
  margin-left: auto; }

.img--right img,
.img--left img,
.img--center img {
  width: auto; }

/* @img--circle
--------------------------------------------------------

  Popular circle image for bios etc.

  Example: Circle image
  <img class="img--circle" src="http://fillmurray.com/400/400">

------------------------------------------------------ */
.img--circle {
  border-radius: 50%; }

/**

  @icons
---------------------------------------------------------
  elements/_icons.scss
--------------------------------------------------------

  Your icon set may vary. In this partial, you may wish
  to load in a font icon set and add all the associated
  css that goes with it. This would be the case if you
  were using something like Symbolset or IcoMoon for
  example.

--------------------------------------------------------- */
/* @icon
--------------------------------------------------------

  This is a basic icon element. We set some basic
  structural styles and display styles to get the ball
  rolling.

  Example: Open Icon
  <i class="icon icon--open">&plus;</i>

  Example: Close Icon
  <i class="icon icon--close">&times;</i>

------------------------------------------------------ */
/**

  @links
--------------------------------------------------------
  elements/_links.scss
--------------------------------------------------------

  Base settings for links

-------------------------------------------------------- */
/* @anchors
--------------------------------------------------------

  Anchors will have this base styling

------------------------------------------------------ */
a {
  color: inherit;
  text-decoration: none; }
  a:hover, a:focus {
    opacity: .75; }

/* @webkit-tap-highlight
--------------------------------------------------------

  Declare the webkit tap highlight style

------------------------------------------------------ */
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.35); }

/**

  @lists
--------------------------------------------------------
  elements/_lists.scss
--------------------------------------------------------

  Base settings for lists.

-------------------------------------------------------- */
ul li,
ol li {
  line-height: 1;
  list-style: none; }

.post-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-bottom: -40px; }
  @media screen and (max-width: 34.385em) {
    .post-list {
      margin-bottom: -10px; } }
  .post-list.three-columns .__item {
    width: 33.333%; }
    @media screen and (max-width: 34.385em) {
      .post-list.three-columns .__item {
        width: 100%;
        margin-bottom: 20px !important; } }
  @media screen and (max-width: 34.385em) {
    .post-list.three-columns.two-cols-when-mobile .__item {
      width: 100%;
      display: flex;
      margin-bottom: 10px; }
      .post-list.three-columns.two-cols-when-mobile .__item .__thumbnail {
        width: 40%; }
      .post-list.three-columns.two-cols-when-mobile .__item .__bottom {
        width: 60%;
        padding-left: 10px; }
        .post-list.three-columns.two-cols-when-mobile .__item .__bottom .__meta {
          margin-top: 0; }
          .post-list.three-columns.two-cols-when-mobile .__item .__bottom .__meta .__category {
            font-size: 12px; }
          .post-list.three-columns.two-cols-when-mobile .__item .__bottom .__meta .__time {
            font-size: 12px; }
        .post-list.three-columns.two-cols-when-mobile .__item .__bottom .__title {
          font-size: 14px; } }
  .post-list.wider-gap {
    margin-left: -40px;
    margin-bottom: -50px; }
    .post-list.wider-gap .__item {
      padding-left: 40px;
      margin-bottom: 50px; }
  .post-list .__item {
    width: 25%;
    padding-left: 20px;
    margin-bottom: 40px; }
    .post-list .__item .__thumbnail {
      position: relative; }
      .post-list .__item .__thumbnail img {
        width: 100%;
        border-radius: 5px; }
      .post-list .__item .__thumbnail .__text {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 7px;
        color: white;
        font-weight: bold;
        background-color: rgba(51, 47, 110, 0.9);
        text-align: center; }
    .post-list .__item .__title {
      display: block;
      font-family: "游ゴシック体", "Yu Gothic", YuGothic;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.6; }
    .post-list .__item .__meta {
      margin-top: 15px;
      margin-bottom: 15px;
      overflow: hidden; }
      .post-list .__item .__meta .__category {
        float: left;
        font-size: 13px;
        color: #332f6e;
        font-weight: bold; }
      .post-list .__item .__meta .__time {
        font-family: Montserrat, sans-serif;
        color: #888888;
        font-size: 13px;
        float: right;
        font-weight: bold; }

.post-list--sm .__item {
  overflow: hidden; }
  .post-list--sm .__item:not(:last-child) {
    margin-bottom: 12px; }

.post-list--sm .__thumbnail {
  float: left;
  width: 40%;
  padding-right: 10px; }
  .post-list--sm .__thumbnail img {
    border-radius: 5px; }

.post-list--sm .__meta {
  width: 60%;
  float: left;
  font-weight: bold;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic; }
  .post-list--sm .__meta .__categories {
    font-size: 12px;
    color: #332f6e;
    margin-bottom: 10px; }
  .post-list--sm .__meta .__title {
    font-size: 14px; }

.news-list {
  max-width: 1034px;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 34.385em) {
    .news-list.full {
      margin-left: -16px;
      margin-right: -16px; } }
  .news-list .__item {
    border-top: 2px solid #f4f4f4;
    padding: 25px 10px;
    padding-right: 40px;
    font-weight: bold;
    line-height: 1.4;
    position: relative; }
    .news-list .__item:after {
      position: absolute;
      font-family: iconfont;
      content: '\e803';
      right: 20px;
      top: 28px;
      color: #c6c6c6;
      font-size: 0.9em; }
    .news-list .__item.open:after {
      content: '\e807'; }
    @media screen and (max-width: 34.385em) {
      .news-list .__item {
        padding: 16px;
        padding-right: 30px; } }
    .news-list .__item a {
      display: block; }
      @media screen and (min-width: 34.385em) {
        .news-list .__item a {
          display: flex; } }
    .news-list .__item .__title {
      font-size: 15px; }
    .news-list .__item .__time {
      font-size: 12px;
      font-family: "Roboto", sans-serif;
      width: 7em;
      display: inline-block;
      flex: 0 0 7em; }
      @media screen and (max-width: 34.385em) {
        .news-list .__item .__time {
          display: block;
          margin-bottom: 5px; } }
    .news-list .__item .__content {
      margin-top: 1em;
      font-size: 0.9em;
      font-weight: normal;
      line-height: 1.8; }
      .news-list .__item .__content p {
        line-height: inherit; }
    .news-list .__item:last-child {
      border-bottom: 1px solid #f4f4f4; }
    .news-list .__item:hover {
      background-color: #fafafa; }
      .news-list .__item:hover:after {
        color: #332f6e; }

/**

  @quotes
--------------------------------------------------------
  elements/_quotes.scss
--------------------------------------------------------

  Base settings for Blockquotes and pull quotes

-----------------------------------------------------  */
/**

  @text
--------------------------------------------------------
  elements/_text.scss
--------------------------------------------------------

  Some basic styles to paragraphs and
  inline styles

-----------------------------------------------------  */
p {
  line-height: 1.25; }
  p:last-child {
    margin-bottom: 0; }

b,
strong {
  font-weight: bold; }

i,
em {
  font-style: italic; }

/***

  @layout
---------------------------------------------------------

  A large portion of CSS is structural layout. You will use
  these helpers to create the scaffolding that will support
  the elements and modules that make up your site.

---------------------------------------------------------  */
/**

  @container
--------------------------------------------------------
  layout/_container.scss
--------------------------------------------------------

  The .container class is applied to restrict the
  max-width of an element, usually a bit of content.

  Often, you will place a .container div within another
  element that has a background color. This gives you
  a full-bleed background, but the content is contained
  within the max-width.

  Example: Container within a section
  <section class="awesome-section-with-background-color">
    <div class="container">
      <p>Your content goes here... </p>
    </div>
  </section>

------------------------------------------------------ */
.container {
  zoom: 1;
  margin-left: auto;
  margin-right: auto;
  width: 1360px;
  max-width: 100%; }
  .container:before, .container:after {
    content: " ";
    display: table; }
  .container:after {
    clear: both; }

.container--v-center {
  position: relative;
  display: table;
  height: 100%; }
  .container--v-center > * {
    display: table-cell;
    vertical-align: middle; }

.container--ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

/**

  @grids
---------------------------------------------------------
  layout/_grids.scss
--------------------------------------------------------

  Skyline uses a simple grid system.  It starts with
  a wrapper div that can have a classname of .grid-wrap
  or .gw for short.  Inside of that div, you'll nest
  your grid units.  These are divs with classname of
  either .grid or .g for short.

  To specify the responsive behavior of the grid,
  add width classes from layout/_widths.scss.

  Example: Grid with responsive width classes
  <div class="gw has-lg-gutter has-lg-bottom-spacing no-gutter-transition no-bottom-spacing-transition">
    <div class="g sm-one-whole md-one-half  lg-one-third    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-half  lg-one-third    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-third lg-one-third    xl-one-twelfth">...</div>
    <div class="g sm-one-whole md-one-third lg-one-fifth    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-third lg-three-fifths xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-half  lg-one-fifth    xl-one-twelfth">...</div>
    <div class="g sm-one-whole md-one-half  lg-one-sixth    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-third lg-one-sixth    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-third lg-one-sixth    xl-one-twelfth">...</div>
    <div class="g sm-one-whole md-one-third lg-one-sixth    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-half  lg-one-sixth    xl-one-twelfth">...</div>
    <div class="g sm-one-half  md-one-half  lg-one-sixth    xl-one-twelfth">...</div>
  </div>

  For a grid wrap containing equally-sized grid units, like
  a gallery of thumbnails for instance, use the responsive ups
  classes from layout/_widths.scss. With these classes
  applied to the .grid-wrap/.gw, you won't need to specify
  widths on the individual grid units, just on the wrapper.

  Example: Grid with responsive ups classes
  <div class="gw two-up sm-three-up md-four-up lg-six-up">
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
    <div class="g">...</div>
  </div>
  
  NOTE: see some test styles at the bottom of this partial
  Remove them if you don't need them.

------------------------------------------------------ */
.grid,
.g {
  min-height: 1px;
  position: relative;
  display: block;
  width: 100%; }
  @media screen and (min-width: 20.01em) {
    .grid,
    .g {
      float: left; }
      html.flexbox .grid, html.flexbox
      .g {
        float: none; } }

.grid-wrap,
.gw {
  display: block;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none; }
  .grid-wrap.has-n-gutter,
  .gw.has-n-gutter {
    margin-left: 0; }
    .grid-wrap.has-n-gutter > .grid,
    .grid-wrap.has-n-gutter > .g,
    .gw.has-n-gutter > .grid,
    .gw.has-n-gutter > .g {
      padding-left: 0; }
  .grid-wrap.has-xs-gutter,
  .gw.has-xs-gutter {
    margin-left: -10px; }
    .grid-wrap.has-xs-gutter > .grid,
    .grid-wrap.has-xs-gutter > .g,
    .gw.has-xs-gutter > .grid,
    .gw.has-xs-gutter > .g {
      padding-left: 10px; }
  .grid-wrap.has-sm-gutter,
  .gw.has-sm-gutter {
    margin-left: -15px; }
    .grid-wrap.has-sm-gutter > .grid,
    .grid-wrap.has-sm-gutter > .g,
    .gw.has-sm-gutter > .grid,
    .gw.has-sm-gutter > .g {
      padding-left: 15px; }
  .grid-wrap.has-md-gutter,
  .gw.has-md-gutter {
    margin-left: -20px; }
    @media (min-width: 375px) {
      .grid-wrap.has-md-gutter,
      .gw.has-md-gutter {
        margin-left: calc(-15px + ((1vw - 3.75px) * -1.1764705882)); } }
    @media (min-width: 800px) {
      .grid-wrap.has-md-gutter,
      .gw.has-md-gutter {
        margin-left: -20px; } }
    @media (max-width: 375px) {
      .grid-wrap.has-md-gutter,
      .gw.has-md-gutter {
        margin-left: -15px; } }
    .grid-wrap.has-md-gutter > .grid,
    .grid-wrap.has-md-gutter > .g,
    .gw.has-md-gutter > .grid,
    .gw.has-md-gutter > .g {
      padding-left: 20px; }
      @media (min-width: 375px) {
        .grid-wrap.has-md-gutter > .grid,
        .grid-wrap.has-md-gutter > .g,
        .gw.has-md-gutter > .grid,
        .gw.has-md-gutter > .g {
          padding-left: calc(15px + ((1vw - 3.75px) * 1.1764705882)); } }
      @media (min-width: 800px) {
        .grid-wrap.has-md-gutter > .grid,
        .grid-wrap.has-md-gutter > .g,
        .gw.has-md-gutter > .grid,
        .gw.has-md-gutter > .g {
          padding-left: 20px; } }
      @media (max-width: 375px) {
        .grid-wrap.has-md-gutter > .grid,
        .grid-wrap.has-md-gutter > .g,
        .gw.has-md-gutter > .grid,
        .gw.has-md-gutter > .g {
          padding-left: 15px; } }
    .grid-wrap.has-md-gutter.no-gutter-transition,
    .gw.has-md-gutter.no-gutter-transition {
      margin-left: -15px; }
      @media screen and (min-width: 375.01px) {
        .grid-wrap.has-md-gutter.no-gutter-transition,
        .gw.has-md-gutter.no-gutter-transition {
          margin-left: -20px; } }
      .grid-wrap.has-md-gutter.no-gutter-transition > .grid,
      .grid-wrap.has-md-gutter.no-gutter-transition > .g,
      .gw.has-md-gutter.no-gutter-transition > .grid,
      .gw.has-md-gutter.no-gutter-transition > .g {
        padding-left: 15px; }
        @media screen and (min-width: 375.01px) {
          .grid-wrap.has-md-gutter.no-gutter-transition > .grid,
          .grid-wrap.has-md-gutter.no-gutter-transition > .g,
          .gw.has-md-gutter.no-gutter-transition > .grid,
          .gw.has-md-gutter.no-gutter-transition > .g {
            padding-left: 20px; } }
  .grid-wrap.has-lg-gutter,
  .gw.has-lg-gutter {
    margin-left: -25px; }
    @media (min-width: 375px) {
      .grid-wrap.has-lg-gutter,
      .gw.has-lg-gutter {
        margin-left: calc(-20px + ((1vw - 3.75px) * -1.1764705882)); } }
    @media (min-width: 800px) {
      .grid-wrap.has-lg-gutter,
      .gw.has-lg-gutter {
        margin-left: -25px; } }
    @media (max-width: 375px) {
      .grid-wrap.has-lg-gutter,
      .gw.has-lg-gutter {
        margin-left: -20px; } }
    .grid-wrap.has-lg-gutter > .grid,
    .grid-wrap.has-lg-gutter > .g,
    .gw.has-lg-gutter > .grid,
    .gw.has-lg-gutter > .g {
      padding-left: 25px; }
      @media (min-width: 375px) {
        .grid-wrap.has-lg-gutter > .grid,
        .grid-wrap.has-lg-gutter > .g,
        .gw.has-lg-gutter > .grid,
        .gw.has-lg-gutter > .g {
          padding-left: calc(20px + ((1vw - 3.75px) * 1.1764705882)); } }
      @media (min-width: 800px) {
        .grid-wrap.has-lg-gutter > .grid,
        .grid-wrap.has-lg-gutter > .g,
        .gw.has-lg-gutter > .grid,
        .gw.has-lg-gutter > .g {
          padding-left: 25px; } }
      @media (max-width: 375px) {
        .grid-wrap.has-lg-gutter > .grid,
        .grid-wrap.has-lg-gutter > .g,
        .gw.has-lg-gutter > .grid,
        .gw.has-lg-gutter > .g {
          padding-left: 20px; } }
    .grid-wrap.has-lg-gutter.no-gutter-transition,
    .gw.has-lg-gutter.no-gutter-transition {
      margin-left: -20px; }
      @media screen and (min-width: 375.01px) {
        .grid-wrap.has-lg-gutter.no-gutter-transition,
        .gw.has-lg-gutter.no-gutter-transition {
          margin-left: -25px; } }
      .grid-wrap.has-lg-gutter.no-gutter-transition > .grid,
      .grid-wrap.has-lg-gutter.no-gutter-transition > .g,
      .gw.has-lg-gutter.no-gutter-transition > .grid,
      .gw.has-lg-gutter.no-gutter-transition > .g {
        padding-left: 20px; }
        @media screen and (min-width: 375.01px) {
          .grid-wrap.has-lg-gutter.no-gutter-transition > .grid,
          .grid-wrap.has-lg-gutter.no-gutter-transition > .g,
          .gw.has-lg-gutter.no-gutter-transition > .grid,
          .gw.has-lg-gutter.no-gutter-transition > .g {
            padding-left: 25px; } }
  .grid-wrap.has-xl-gutter,
  .gw.has-xl-gutter {
    margin-left: -30px; }
    @media (min-width: 375px) {
      .grid-wrap.has-xl-gutter,
      .gw.has-xl-gutter {
        margin-left: calc(-25px + ((1vw - 3.75px) * -1.1764705882)); } }
    @media (min-width: 800px) {
      .grid-wrap.has-xl-gutter,
      .gw.has-xl-gutter {
        margin-left: -30px; } }
    @media (max-width: 375px) {
      .grid-wrap.has-xl-gutter,
      .gw.has-xl-gutter {
        margin-left: -25px; } }
    .grid-wrap.has-xl-gutter > .grid,
    .grid-wrap.has-xl-gutter > .g,
    .gw.has-xl-gutter > .grid,
    .gw.has-xl-gutter > .g {
      padding-left: 30px; }
      @media (min-width: 375px) {
        .grid-wrap.has-xl-gutter > .grid,
        .grid-wrap.has-xl-gutter > .g,
        .gw.has-xl-gutter > .grid,
        .gw.has-xl-gutter > .g {
          padding-left: calc(25px + ((1vw - 3.75px) * 1.1764705882)); } }
      @media (min-width: 800px) {
        .grid-wrap.has-xl-gutter > .grid,
        .grid-wrap.has-xl-gutter > .g,
        .gw.has-xl-gutter > .grid,
        .gw.has-xl-gutter > .g {
          padding-left: 30px; } }
      @media (max-width: 375px) {
        .grid-wrap.has-xl-gutter > .grid,
        .grid-wrap.has-xl-gutter > .g,
        .gw.has-xl-gutter > .grid,
        .gw.has-xl-gutter > .g {
          padding-left: 25px; } }
    .grid-wrap.has-xl-gutter.no-gutter-transition,
    .gw.has-xl-gutter.no-gutter-transition {
      margin-left: -25px; }
      @media screen and (min-width: 375.01px) {
        .grid-wrap.has-xl-gutter.no-gutter-transition,
        .gw.has-xl-gutter.no-gutter-transition {
          margin-left: -30px; } }
      .grid-wrap.has-xl-gutter.no-gutter-transition > .grid,
      .grid-wrap.has-xl-gutter.no-gutter-transition > .g,
      .gw.has-xl-gutter.no-gutter-transition > .grid,
      .gw.has-xl-gutter.no-gutter-transition > .g {
        padding-left: 25px; }
        @media screen and (min-width: 375.01px) {
          .grid-wrap.has-xl-gutter.no-gutter-transition > .grid,
          .grid-wrap.has-xl-gutter.no-gutter-transition > .g,
          .gw.has-xl-gutter.no-gutter-transition > .grid,
          .gw.has-xl-gutter.no-gutter-transition > .g {
            padding-left: 30px; } }
  .grid-wrap.has-xxl-gutter,
  .gw.has-xxl-gutter {
    margin-left: -35px; }
    @media (min-width: 375px) {
      .grid-wrap.has-xxl-gutter,
      .gw.has-xxl-gutter {
        margin-left: calc(-30px + ((1vw - 3.75px) * -1.1764705882)); } }
    @media (min-width: 800px) {
      .grid-wrap.has-xxl-gutter,
      .gw.has-xxl-gutter {
        margin-left: -35px; } }
    @media (max-width: 375px) {
      .grid-wrap.has-xxl-gutter,
      .gw.has-xxl-gutter {
        margin-left: -30px; } }
    .grid-wrap.has-xxl-gutter > .grid,
    .grid-wrap.has-xxl-gutter > .g,
    .gw.has-xxl-gutter > .grid,
    .gw.has-xxl-gutter > .g {
      padding-left: 35px; }
      @media (min-width: 375px) {
        .grid-wrap.has-xxl-gutter > .grid,
        .grid-wrap.has-xxl-gutter > .g,
        .gw.has-xxl-gutter > .grid,
        .gw.has-xxl-gutter > .g {
          padding-left: calc(30px + ((1vw - 3.75px) * 1.1764705882)); } }
      @media (min-width: 800px) {
        .grid-wrap.has-xxl-gutter > .grid,
        .grid-wrap.has-xxl-gutter > .g,
        .gw.has-xxl-gutter > .grid,
        .gw.has-xxl-gutter > .g {
          padding-left: 35px; } }
      @media (max-width: 375px) {
        .grid-wrap.has-xxl-gutter > .grid,
        .grid-wrap.has-xxl-gutter > .g,
        .gw.has-xxl-gutter > .grid,
        .gw.has-xxl-gutter > .g {
          padding-left: 30px; } }
    .grid-wrap.has-xxl-gutter.no-gutter-transition,
    .gw.has-xxl-gutter.no-gutter-transition {
      margin-left: -30px; }
      @media screen and (min-width: 375.01px) {
        .grid-wrap.has-xxl-gutter.no-gutter-transition,
        .gw.has-xxl-gutter.no-gutter-transition {
          margin-left: -35px; } }
      .grid-wrap.has-xxl-gutter.no-gutter-transition > .grid,
      .grid-wrap.has-xxl-gutter.no-gutter-transition > .g,
      .gw.has-xxl-gutter.no-gutter-transition > .grid,
      .gw.has-xxl-gutter.no-gutter-transition > .g {
        padding-left: 30px; }
        @media screen and (min-width: 375.01px) {
          .grid-wrap.has-xxl-gutter.no-gutter-transition > .grid,
          .grid-wrap.has-xxl-gutter.no-gutter-transition > .g,
          .gw.has-xxl-gutter.no-gutter-transition > .grid,
          .gw.has-xxl-gutter.no-gutter-transition > .g {
            padding-left: 35px; } }
  .grid-wrap.has-n-bottom-spacing,
  .gw.has-n-bottom-spacing {
    margin-bottom: 0; }
    .grid-wrap.has-n-bottom-spacing > .grid,
    .grid-wrap.has-n-bottom-spacing > .g,
    .gw.has-n-bottom-spacing > .grid,
    .gw.has-n-bottom-spacing > .g {
      padding-bottom: 0; }
  .grid-wrap.has-xs-bottom-spacing,
  .gw.has-xs-bottom-spacing {
    margin-bottom: -10px; }
    .grid-wrap.has-xs-bottom-spacing > .grid,
    .grid-wrap.has-xs-bottom-spacing > .g,
    .gw.has-xs-bottom-spacing > .grid,
    .gw.has-xs-bottom-spacing > .g {
      padding-bottom: 10px; }
  .grid-wrap.has-sm-bottom-spacing,
  .gw.has-sm-bottom-spacing {
    margin-bottom: -15px; }
    .grid-wrap.has-sm-bottom-spacing > .grid,
    .grid-wrap.has-sm-bottom-spacing > .g,
    .gw.has-sm-bottom-spacing > .grid,
    .gw.has-sm-bottom-spacing > .g {
      padding-bottom: 15px; }
  .grid-wrap.has-md-bottom-spacing,
  .gw.has-md-bottom-spacing {
    margin-bottom: -20px; }
    @media (min-width: 375px) {
      .grid-wrap.has-md-bottom-spacing,
      .gw.has-md-bottom-spacing {
        margin-bottom: calc(-15px + ((1vw - 3.75px) * -1.1764705882)); } }
    @media (min-width: 800px) {
      .grid-wrap.has-md-bottom-spacing,
      .gw.has-md-bottom-spacing {
        margin-bottom: -20px; } }
    @media (max-width: 375px) {
      .grid-wrap.has-md-bottom-spacing,
      .gw.has-md-bottom-spacing {
        margin-bottom: -15px; } }
    .grid-wrap.has-md-bottom-spacing > .grid,
    .grid-wrap.has-md-bottom-spacing > .g,
    .gw.has-md-bottom-spacing > .grid,
    .gw.has-md-bottom-spacing > .g {
      padding-bottom: 20px; }
      @media (min-width: 375px) {
        .grid-wrap.has-md-bottom-spacing > .grid,
        .grid-wrap.has-md-bottom-spacing > .g,
        .gw.has-md-bottom-spacing > .grid,
        .gw.has-md-bottom-spacing > .g {
          padding-bottom: calc(15px + ((1vw - 3.75px) * 1.1764705882)); } }
      @media (min-width: 800px) {
        .grid-wrap.has-md-bottom-spacing > .grid,
        .grid-wrap.has-md-bottom-spacing > .g,
        .gw.has-md-bottom-spacing > .grid,
        .gw.has-md-bottom-spacing > .g {
          padding-bottom: 20px; } }
      @media (max-width: 375px) {
        .grid-wrap.has-md-bottom-spacing > .grid,
        .grid-wrap.has-md-bottom-spacing > .g,
        .gw.has-md-bottom-spacing > .grid,
        .gw.has-md-bottom-spacing > .g {
          padding-bottom: 15px; } }
    .grid-wrap.has-md-bottom-spacing.no-bottom-spacing-transition,
    .gw.has-md-bottom-spacing.no-bottom-spacing-transition {
      margin-bottom: -15px; }
      @media screen and (min-width: 375.01px) {
        .grid-wrap.has-md-bottom-spacing.no-bottom-spacing-transition,
        .gw.has-md-bottom-spacing.no-bottom-spacing-transition {
          margin-bottom: -20px; } }
      .grid-wrap.has-md-bottom-spacing.no-bottom-spacing-transition > .grid,
      .grid-wrap.has-md-bottom-spacing.no-bottom-spacing-transition > .g,
      .gw.has-md-bottom-spacing.no-bottom-spacing-transition > .grid,
      .gw.has-md-bottom-spacing.no-bottom-spacing-transition > .g {
        padding-bottom: 15px; }
        @media screen and (min-width: 375.01px) {
          .grid-wrap.has-md-bottom-spacing.no-bottom-spacing-transition > .grid,
          .grid-wrap.has-md-bottom-spacing.no-bottom-spacing-transition > .g,
          .gw.has-md-bottom-spacing.no-bottom-spacing-transition > .grid,
          .gw.has-md-bottom-spacing.no-bottom-spacing-transition > .g {
            padding-bottom: 20px; } }
  .grid-wrap.has-lg-bottom-spacing,
  .gw.has-lg-bottom-spacing {
    margin-bottom: -25px; }
    @media (min-width: 375px) {
      .grid-wrap.has-lg-bottom-spacing,
      .gw.has-lg-bottom-spacing {
        margin-bottom: calc(-20px + ((1vw - 3.75px) * -1.1764705882)); } }
    @media (min-width: 800px) {
      .grid-wrap.has-lg-bottom-spacing,
      .gw.has-lg-bottom-spacing {
        margin-bottom: -25px; } }
    @media (max-width: 375px) {
      .grid-wrap.has-lg-bottom-spacing,
      .gw.has-lg-bottom-spacing {
        margin-bottom: -20px; } }
    .grid-wrap.has-lg-bottom-spacing > .grid,
    .grid-wrap.has-lg-bottom-spacing > .g,
    .gw.has-lg-bottom-spacing > .grid,
    .gw.has-lg-bottom-spacing > .g {
      padding-bottom: 25px; }
      @media (min-width: 375px) {
        .grid-wrap.has-lg-bottom-spacing > .grid,
        .grid-wrap.has-lg-bottom-spacing > .g,
        .gw.has-lg-bottom-spacing > .grid,
        .gw.has-lg-bottom-spacing > .g {
          padding-bottom: calc(20px + ((1vw - 3.75px) * 1.1764705882)); } }
      @media (min-width: 800px) {
        .grid-wrap.has-lg-bottom-spacing > .grid,
        .grid-wrap.has-lg-bottom-spacing > .g,
        .gw.has-lg-bottom-spacing > .grid,
        .gw.has-lg-bottom-spacing > .g {
          padding-bottom: 25px; } }
      @media (max-width: 375px) {
        .grid-wrap.has-lg-bottom-spacing > .grid,
        .grid-wrap.has-lg-bottom-spacing > .g,
        .gw.has-lg-bottom-spacing > .grid,
        .gw.has-lg-bottom-spacing > .g {
          padding-bottom: 20px; } }
    .grid-wrap.has-lg-bottom-spacing.no-bottom-spacing-transition,
    .gw.has-lg-bottom-spacing.no-bottom-spacing-transition {
      margin-bottom: -20px; }
      @media screen and (min-width: 375.01px) {
        .grid-wrap.has-lg-bottom-spacing.no-bottom-spacing-transition,
        .gw.has-lg-bottom-spacing.no-bottom-spacing-transition {
          margin-bottom: -25px; } }
      .grid-wrap.has-lg-bottom-spacing.no-bottom-spacing-transition > .grid,
      .grid-wrap.has-lg-bottom-spacing.no-bottom-spacing-transition > .g,
      .gw.has-lg-bottom-spacing.no-bottom-spacing-transition > .grid,
      .gw.has-lg-bottom-spacing.no-bottom-spacing-transition > .g {
        padding-bottom: 20px; }
        @media screen and (min-width: 375.01px) {
          .grid-wrap.has-lg-bottom-spacing.no-bottom-spacing-transition > .grid,
          .grid-wrap.has-lg-bottom-spacing.no-bottom-spacing-transition > .g,
          .gw.has-lg-bottom-spacing.no-bottom-spacing-transition > .grid,
          .gw.has-lg-bottom-spacing.no-bottom-spacing-transition > .g {
            padding-bottom: 25px; } }
  .grid-wrap.has-xl-bottom-spacing,
  .gw.has-xl-bottom-spacing {
    margin-bottom: -30px; }
    @media (min-width: 375px) {
      .grid-wrap.has-xl-bottom-spacing,
      .gw.has-xl-bottom-spacing {
        margin-bottom: calc(-25px + ((1vw - 3.75px) * -1.1764705882)); } }
    @media (min-width: 800px) {
      .grid-wrap.has-xl-bottom-spacing,
      .gw.has-xl-bottom-spacing {
        margin-bottom: -30px; } }
    @media (max-width: 375px) {
      .grid-wrap.has-xl-bottom-spacing,
      .gw.has-xl-bottom-spacing {
        margin-bottom: -25px; } }
    .grid-wrap.has-xl-bottom-spacing > .grid,
    .grid-wrap.has-xl-bottom-spacing > .g,
    .gw.has-xl-bottom-spacing > .grid,
    .gw.has-xl-bottom-spacing > .g {
      padding-bottom: 30px; }
      @media (min-width: 375px) {
        .grid-wrap.has-xl-bottom-spacing > .grid,
        .grid-wrap.has-xl-bottom-spacing > .g,
        .gw.has-xl-bottom-spacing > .grid,
        .gw.has-xl-bottom-spacing > .g {
          padding-bottom: calc(25px + ((1vw - 3.75px) * 1.1764705882)); } }
      @media (min-width: 800px) {
        .grid-wrap.has-xl-bottom-spacing > .grid,
        .grid-wrap.has-xl-bottom-spacing > .g,
        .gw.has-xl-bottom-spacing > .grid,
        .gw.has-xl-bottom-spacing > .g {
          padding-bottom: 30px; } }
      @media (max-width: 375px) {
        .grid-wrap.has-xl-bottom-spacing > .grid,
        .grid-wrap.has-xl-bottom-spacing > .g,
        .gw.has-xl-bottom-spacing > .grid,
        .gw.has-xl-bottom-spacing > .g {
          padding-bottom: 25px; } }
    .grid-wrap.has-xl-bottom-spacing.no-bottom-spacing-transition,
    .gw.has-xl-bottom-spacing.no-bottom-spacing-transition {
      margin-bottom: -25px; }
      @media screen and (min-width: 375.01px) {
        .grid-wrap.has-xl-bottom-spacing.no-bottom-spacing-transition,
        .gw.has-xl-bottom-spacing.no-bottom-spacing-transition {
          margin-bottom: -30px; } }
      .grid-wrap.has-xl-bottom-spacing.no-bottom-spacing-transition > .grid,
      .grid-wrap.has-xl-bottom-spacing.no-bottom-spacing-transition > .g,
      .gw.has-xl-bottom-spacing.no-bottom-spacing-transition > .grid,
      .gw.has-xl-bottom-spacing.no-bottom-spacing-transition > .g {
        padding-bottom: 25px; }
        @media screen and (min-width: 375.01px) {
          .grid-wrap.has-xl-bottom-spacing.no-bottom-spacing-transition > .grid,
          .grid-wrap.has-xl-bottom-spacing.no-bottom-spacing-transition > .g,
          .gw.has-xl-bottom-spacing.no-bottom-spacing-transition > .grid,
          .gw.has-xl-bottom-spacing.no-bottom-spacing-transition > .g {
            padding-bottom: 30px; } }
  .grid-wrap.has-xxl-bottom-spacing,
  .gw.has-xxl-bottom-spacing {
    margin-bottom: -35px; }
    @media (min-width: 375px) {
      .grid-wrap.has-xxl-bottom-spacing,
      .gw.has-xxl-bottom-spacing {
        margin-bottom: calc(-30px + ((1vw - 3.75px) * -1.1764705882)); } }
    @media (min-width: 800px) {
      .grid-wrap.has-xxl-bottom-spacing,
      .gw.has-xxl-bottom-spacing {
        margin-bottom: -35px; } }
    @media (max-width: 375px) {
      .grid-wrap.has-xxl-bottom-spacing,
      .gw.has-xxl-bottom-spacing {
        margin-bottom: -30px; } }
    .grid-wrap.has-xxl-bottom-spacing > .grid,
    .grid-wrap.has-xxl-bottom-spacing > .g,
    .gw.has-xxl-bottom-spacing > .grid,
    .gw.has-xxl-bottom-spacing > .g {
      padding-bottom: 35px; }
      @media (min-width: 375px) {
        .grid-wrap.has-xxl-bottom-spacing > .grid,
        .grid-wrap.has-xxl-bottom-spacing > .g,
        .gw.has-xxl-bottom-spacing > .grid,
        .gw.has-xxl-bottom-spacing > .g {
          padding-bottom: calc(30px + ((1vw - 3.75px) * 1.1764705882)); } }
      @media (min-width: 800px) {
        .grid-wrap.has-xxl-bottom-spacing > .grid,
        .grid-wrap.has-xxl-bottom-spacing > .g,
        .gw.has-xxl-bottom-spacing > .grid,
        .gw.has-xxl-bottom-spacing > .g {
          padding-bottom: 35px; } }
      @media (max-width: 375px) {
        .grid-wrap.has-xxl-bottom-spacing > .grid,
        .grid-wrap.has-xxl-bottom-spacing > .g,
        .gw.has-xxl-bottom-spacing > .grid,
        .gw.has-xxl-bottom-spacing > .g {
          padding-bottom: 30px; } }
    .grid-wrap.has-xxl-bottom-spacing.no-bottom-spacing-transition,
    .gw.has-xxl-bottom-spacing.no-bottom-spacing-transition {
      margin-bottom: -30px; }
      @media screen and (min-width: 375.01px) {
        .grid-wrap.has-xxl-bottom-spacing.no-bottom-spacing-transition,
        .gw.has-xxl-bottom-spacing.no-bottom-spacing-transition {
          margin-bottom: -35px; } }
      .grid-wrap.has-xxl-bottom-spacing.no-bottom-spacing-transition > .grid,
      .grid-wrap.has-xxl-bottom-spacing.no-bottom-spacing-transition > .g,
      .gw.has-xxl-bottom-spacing.no-bottom-spacing-transition > .grid,
      .gw.has-xxl-bottom-spacing.no-bottom-spacing-transition > .g {
        padding-bottom: 30px; }
        @media screen and (min-width: 375.01px) {
          .grid-wrap.has-xxl-bottom-spacing.no-bottom-spacing-transition > .grid,
          .grid-wrap.has-xxl-bottom-spacing.no-bottom-spacing-transition > .g,
          .gw.has-xxl-bottom-spacing.no-bottom-spacing-transition > .grid,
          .gw.has-xxl-bottom-spacing.no-bottom-spacing-transition > .g {
            padding-bottom: 35px; } }

/**

  @hide
---------------------------------------------------------
  layout/_hide.scss
--------------------------------------------------------

  Utility state classes to hide things in different ways

--------------------------------------------------------- */
.is-hidden {
  display: none !important;
  visibility: hidden !important; }

.is-visually-hidden {
  visibility: hidden; }

@media screen and (max-width: 34.385em) {
  .hide-if-mobile {
    display: none !important; } }

@media screen and (min-width: 34.385em) {
  .hide-if-pc {
    display: none !important; } }

/**

  @media
--------------------------------------------------------
  layout/_media.scss
--------------------------------------------------------

  The 'media' object is a common pattern in OOCSS.
  It consists of a wrapper element with a classname of
  .media and then two child elements, one that contains
  an image or video that is floated, and the other
  contains text.

  This used commonly in bios, or comments where you
  have an avatar to one site, and the comment next to it.

  Source: http://www.stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code/

-----------------------------------------------------  */
/* @media objects
---------------------------------------------------------

  Example: Media object, left
  <article class="media">
    <div class="media__left one-fifth">
      <img src="http://fillmurray.com/300/300">
    </div>
    <div class="media__body">
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    </div>
  </article>

  Example: Media object, right
  <article class="media">
    <div class="media__right one-fifth">
      <img src="http://fillmurray.com/300/300">
    </div>
    <div class="media__body">
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    </div>
  </article>

---------------------------------------------------------  */
.media {
  display: block;
  zoom: 1;
  margin-bottom: 1em; }
  .media:before, .media:after {
    content: " ";
    display: table; }
  .media:after {
    clear: both; }

.media__left {
  float: left;
  margin-right: 1em; }

.media__right {
  float: right;
  margin-left: 1em; }

.media__left img,
.media__right img {
  display: block; }

.media__body {
  overflow: hidden; }

.media__body,
.media__body > :last-child {
  margin-bottom: 0; }

/**

  @ups
--------------------------------------------------------
  layout/_ups.scss
--------------------------------------------------------

  UPS?

  Yep, ups.  These are global classes that will set
  their direct children to a specific width.  You will
  mainly use these in grids, and maybe with tabs or
  navigations.

  For instance: four-up will set each child to 25%

  Set the ups configuration variables below in this partial
  to specify how many or how few classes you want available
  in your project.

  Example: Ups and grids
  <div class="gw two-up">
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
  </div><!--/gw-->
  <div class="gw three-up">
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
  </div><!--/gw-->

  Ups work like widths in that they have responsive behavior.  If
  you prepend an up class with a responsive prefix, like (s for small
  or m for medium) the behaviour will only kick in at those
  viewports.

  For example, if you want your gallery to be two-up at the smallest
  viewport, four-up at medium, six-up at large, and eight-up on the largest then use
  this.

  Example: Responsive Ups
  <div class="gw two-up m-four-up l-six-up xl-eight-up">
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
    <div class="g"><img src="http://fillmurray.com/500/300"></div>
  </div><!--/gw-->
  
  

-----------------------------------------------------  */
/*

              Widths at default Viewport

            ---------------------------------------------------- */
.one-up > * {
  width: 100%; }

.two-up > * {
  width: 50%; }

.three-up > * {
  width: 33.3333333333%; }

.four-up > * {
  width: 25%; }

.five-up > * {
  width: 20%; }

.six-up > * {
  width: 16.6666666667%; }

.seven-up > * {
  width: 14.2857142857%; }

.eight-up > * {
  width: 12.5%; }

.nine-up > * {
  width: 11.1111111111%; }

.ten-up > * {
  width: 10%; }

.eleven-up > * {
  width: 9.0909090909%; }

.twelve-up > * {
  width: 8.3333333333%; }

/*

              Responsive ups at $breakpoint__xs

            ---------------------------------------------------- */
@media screen and (min-width: 20.01em) {
  .xs-one-up > * {
    width: 100%; }
  .xs-two-up > * {
    width: 50%; }
  .xs-three-up > * {
    width: 33.3333333333%; }
  .xs-four-up > * {
    width: 25%; }
  .xs-five-up > * {
    width: 20%; }
  .xs-six-up > * {
    width: 16.6666666667%; }
  .xs-seven-up > * {
    width: 14.2857142857%; }
  .xs-eight-up > * {
    width: 12.5%; }
  .xs-nine-up > * {
    width: 11.1111111111%; }
  .xs-ten-up > * {
    width: 10%; }
  .xs-eleven-up > * {
    width: 9.0909090909%; }
  .xs-twelve-up > * {
    width: 8.3333333333%; } }

/*

              Responsive ups at $breakpoint__sm

            ---------------------------------------------------- */
@media screen and (min-width: 34.385em) {
  .sm-one-up > * {
    width: 100%; }
  .sm-two-up > * {
    width: 50%; }
  .sm-three-up > * {
    width: 33.3333333333%; }
  .sm-four-up > * {
    width: 25%; }
  .sm-five-up > * {
    width: 20%; }
  .sm-six-up > * {
    width: 16.6666666667%; }
  .sm-seven-up > * {
    width: 14.2857142857%; }
  .sm-eight-up > * {
    width: 12.5%; }
  .sm-nine-up > * {
    width: 11.1111111111%; }
  .sm-ten-up > * {
    width: 10%; }
  .sm-eleven-up > * {
    width: 9.0909090909%; }
  .sm-twelve-up > * {
    width: 8.3333333333%; } }

/*

              Responsive ups at $breakpoint__md

            ---------------------------------------------------- */
@media screen and (min-width: 48.01em) {
  .md-one-up > * {
    width: 100%; }
  .md-two-up > * {
    width: 50%; }
  .md-three-up > * {
    width: 33.3333333333%; }
  .md-four-up > * {
    width: 25%; }
  .md-five-up > * {
    width: 20%; }
  .md-six-up > * {
    width: 16.6666666667%; }
  .md-seven-up > * {
    width: 14.2857142857%; }
  .md-eight-up > * {
    width: 12.5%; }
  .md-nine-up > * {
    width: 11.1111111111%; }
  .md-ten-up > * {
    width: 10%; }
  .md-eleven-up > * {
    width: 9.0909090909%; }
  .md-twelve-up > * {
    width: 8.3333333333%; } }

/*

              Responsive ups at $breakpoint__lg

            ---------------------------------------------------- */
@media screen and (min-width: 64.01em) {
  .lg-one-up > * {
    width: 100%; }
  .lg-two-up > * {
    width: 50%; }
  .lg-three-up > * {
    width: 33.3333333333%; }
  .lg-four-up > * {
    width: 25%; }
  .lg-five-up > * {
    width: 20%; }
  .lg-six-up > * {
    width: 16.6666666667%; }
  .lg-seven-up > * {
    width: 14.2857142857%; }
  .lg-eight-up > * {
    width: 12.5%; }
  .lg-nine-up > * {
    width: 11.1111111111%; }
  .lg-ten-up > * {
    width: 10%; }
  .lg-eleven-up > * {
    width: 9.0909090909%; }
  .lg-twelve-up > * {
    width: 8.3333333333%; } }

/*

              Responsive ups at $breakpoint__xl

            ---------------------------------------------------- */
@media screen and (min-width: 78.51em) {
  .xl-one-up > * {
    width: 100%; }
  .xl-two-up > * {
    width: 50%; }
  .xl-three-up > * {
    width: 33.3333333333%; }
  .xl-four-up > * {
    width: 25%; }
  .xl-five-up > * {
    width: 20%; }
  .xl-six-up > * {
    width: 16.6666666667%; }
  .xl-seven-up > * {
    width: 14.2857142857%; }
  .xl-eight-up > * {
    width: 12.5%; }
  .xl-nine-up > * {
    width: 11.1111111111%; }
  .xl-ten-up > * {
    width: 10%; }
  .xl-eleven-up > * {
    width: 9.0909090909%; }
  .xl-twelve-up > * {
    width: 8.3333333333%; } }

/*

              Responsive ups at $breakpoint__xxl

            ---------------------------------------------------- */
@media screen and (min-width: 100.01em) {
  .xxl-one-up > * {
    width: 100%; }
  .xxl-two-up > * {
    width: 50%; }
  .xxl-three-up > * {
    width: 33.3333333333%; }
  .xxl-four-up > * {
    width: 25%; }
  .xxl-five-up > * {
    width: 20%; }
  .xxl-six-up > * {
    width: 16.6666666667%; }
  .xxl-seven-up > * {
    width: 14.2857142857%; }
  .xxl-eight-up > * {
    width: 12.5%; }
  .xxl-nine-up > * {
    width: 11.1111111111%; }
  .xxl-ten-up > * {
    width: 10%; }
  .xxl-eleven-up > * {
    width: 9.0909090909%; }
  .xxl-twelve-up > * {
    width: 8.3333333333%; } }

/**

  @widths
---------------------------------------------------------
  layout/_widths.scss
--------------------------------------------------------

  Global Width Classes like .one-third or .seven-twelfths
  and responsive widths like .sm-one-third or .xl-one-half

  If you append the width class with a size indicator, the
  size will only be applied if the viewport is at least
  at that breakpoint size.

  Set the widths configuration variables below in this partial
  to specify how many or how few classes you want available
  in your project.

  Requires:
  * base/_vars.scss and the breakpoint mixin from
  * base/_mixin-breakpoints.scss

---------------------------------------------------------  */
/*

              Widths at default Viewport

            ---------------------------------------------------- */
.one-whole {
  width: 100%; }

.one-half {
  width: 50%; }

.two-halves {
  width: 100%; }

.one-third {
  width: 33.3333333333%; }

.two-thirds {
  width: 66.6666666667%; }

.three-thirds {
  width: 100%; }

.one-fourth {
  width: 25%; }

.two-fourths {
  width: 50%; }

.three-fourths {
  width: 75%; }

.four-fourths {
  width: 100%; }

.one-fifth {
  width: 20%; }

.two-fifths {
  width: 40%; }

.three-fifths {
  width: 60%; }

.four-fifths {
  width: 80%; }

.five-fifths {
  width: 100%; }

.one-sixth {
  width: 16.6666666667%; }

.two-sixths {
  width: 33.3333333333%; }

.three-sixths {
  width: 50%; }

.four-sixths {
  width: 66.6666666667%; }

.five-sixths {
  width: 83.3333333333%; }

.six-sixths {
  width: 100%; }

.one-seventh {
  width: 14.2857142857%; }

.two-sevenths {
  width: 28.5714285714%; }

.three-sevenths {
  width: 42.8571428571%; }

.four-sevenths {
  width: 57.1428571429%; }

.five-sevenths {
  width: 71.4285714286%; }

.six-sevenths {
  width: 85.7142857143%; }

.seven-sevenths {
  width: 100%; }

.one-eighth {
  width: 12.5%; }

.two-eighths {
  width: 25%; }

.three-eighths {
  width: 37.5%; }

.four-eighths {
  width: 50%; }

.five-eighths {
  width: 62.5%; }

.six-eighths {
  width: 75%; }

.seven-eighths {
  width: 87.5%; }

.eight-eighths {
  width: 100%; }

.one-ninth {
  width: 11.1111111111%; }

.two-ninths {
  width: 22.2222222222%; }

.three-ninths {
  width: 33.3333333333%; }

.four-ninths {
  width: 44.4444444444%; }

.five-ninths {
  width: 55.5555555556%; }

.six-ninths {
  width: 66.6666666667%; }

.seven-ninths {
  width: 77.7777777778%; }

.eight-ninths {
  width: 88.8888888889%; }

.nine-ninths {
  width: 100%; }

.one-tenth {
  width: 10%; }

.two-tenths {
  width: 20%; }

.three-tenths {
  width: 30%; }

.four-tenths {
  width: 40%; }

.five-tenths {
  width: 50%; }

.six-tenths {
  width: 60%; }

.seven-tenths {
  width: 70%; }

.eight-tenths {
  width: 80%; }

.nine-tenths {
  width: 90%; }

.ten-tenths {
  width: 100%; }

.one-eleventh {
  width: 9.0909090909%; }

.two-elevenths {
  width: 18.1818181818%; }

.three-elevenths {
  width: 27.2727272727%; }

.four-elevenths {
  width: 36.3636363636%; }

.five-elevenths {
  width: 45.4545454545%; }

.six-elevenths {
  width: 54.5454545455%; }

.seven-elevenths {
  width: 63.6363636364%; }

.eight-elevenths {
  width: 72.7272727273%; }

.nine-elevenths {
  width: 81.8181818182%; }

.ten-elevenths {
  width: 90.9090909091%; }

.eleven-elevenths {
  width: 100%; }

.one-twelfth {
  width: 8.3333333333%; }

.two-twelfths {
  width: 16.6666666667%; }

.three-twelfths {
  width: 25%; }

.four-twelfths {
  width: 33.3333333333%; }

.five-twelfths {
  width: 41.6666666667%; }

.six-twelfths {
  width: 50%; }

.seven-twelfths {
  width: 58.3333333333%; }

.eight-twelfths {
  width: 66.6666666667%; }

.nine-twelfths {
  width: 75%; }

.ten-twelfths {
  width: 83.3333333333%; }

.eleven-twelfths {
  width: 91.6666666667%; }

.twelve-twelfths {
  width: 100%; }

/*

              Responsive widths at $breakpoint__sm

            ---------------------------------------------------- */
@media screen and (min-width: 34.385em) {
  .sm-one-whole {
    width: 100%; }
  .sm-one-half {
    width: 50%; }
  .sm-two-halves {
    width: 100%; }
  .sm-one-third {
    width: 33.3333333333%; }
  .sm-two-thirds {
    width: 66.6666666667%; }
  .sm-three-thirds {
    width: 100%; }
  .sm-one-fourth {
    width: 25%; }
  .sm-two-fourths {
    width: 50%; }
  .sm-three-fourths {
    width: 75%; }
  .sm-four-fourths {
    width: 100%; }
  .sm-one-fifth {
    width: 20%; }
  .sm-two-fifths {
    width: 40%; }
  .sm-three-fifths {
    width: 60%; }
  .sm-four-fifths {
    width: 80%; }
  .sm-five-fifths {
    width: 100%; }
  .sm-one-sixth {
    width: 16.6666666667%; }
  .sm-two-sixths {
    width: 33.3333333333%; }
  .sm-three-sixths {
    width: 50%; }
  .sm-four-sixths {
    width: 66.6666666667%; }
  .sm-five-sixths {
    width: 83.3333333333%; }
  .sm-six-sixths {
    width: 100%; }
  .sm-one-seventh {
    width: 14.2857142857%; }
  .sm-two-sevenths {
    width: 28.5714285714%; }
  .sm-three-sevenths {
    width: 42.8571428571%; }
  .sm-four-sevenths {
    width: 57.1428571429%; }
  .sm-five-sevenths {
    width: 71.4285714286%; }
  .sm-six-sevenths {
    width: 85.7142857143%; }
  .sm-seven-sevenths {
    width: 100%; }
  .sm-one-eighth {
    width: 12.5%; }
  .sm-two-eighths {
    width: 25%; }
  .sm-three-eighths {
    width: 37.5%; }
  .sm-four-eighths {
    width: 50%; }
  .sm-five-eighths {
    width: 62.5%; }
  .sm-six-eighths {
    width: 75%; }
  .sm-seven-eighths {
    width: 87.5%; }
  .sm-eight-eighths {
    width: 100%; }
  .sm-one-ninth {
    width: 11.1111111111%; }
  .sm-two-ninths {
    width: 22.2222222222%; }
  .sm-three-ninths {
    width: 33.3333333333%; }
  .sm-four-ninths {
    width: 44.4444444444%; }
  .sm-five-ninths {
    width: 55.5555555556%; }
  .sm-six-ninths {
    width: 66.6666666667%; }
  .sm-seven-ninths {
    width: 77.7777777778%; }
  .sm-eight-ninths {
    width: 88.8888888889%; }
  .sm-nine-ninths {
    width: 100%; }
  .sm-one-tenth {
    width: 10%; }
  .sm-two-tenths {
    width: 20%; }
  .sm-three-tenths {
    width: 30%; }
  .sm-four-tenths {
    width: 40%; }
  .sm-five-tenths {
    width: 50%; }
  .sm-six-tenths {
    width: 60%; }
  .sm-seven-tenths {
    width: 70%; }
  .sm-eight-tenths {
    width: 80%; }
  .sm-nine-tenths {
    width: 90%; }
  .sm-ten-tenths {
    width: 100%; }
  .sm-one-eleventh {
    width: 9.0909090909%; }
  .sm-two-elevenths {
    width: 18.1818181818%; }
  .sm-three-elevenths {
    width: 27.2727272727%; }
  .sm-four-elevenths {
    width: 36.3636363636%; }
  .sm-five-elevenths {
    width: 45.4545454545%; }
  .sm-six-elevenths {
    width: 54.5454545455%; }
  .sm-seven-elevenths {
    width: 63.6363636364%; }
  .sm-eight-elevenths {
    width: 72.7272727273%; }
  .sm-nine-elevenths {
    width: 81.8181818182%; }
  .sm-ten-elevenths {
    width: 90.9090909091%; }
  .sm-eleven-elevenths {
    width: 100%; }
  .sm-one-twelfth {
    width: 8.3333333333%; }
  .sm-two-twelfths {
    width: 16.6666666667%; }
  .sm-three-twelfths {
    width: 25%; }
  .sm-four-twelfths {
    width: 33.3333333333%; }
  .sm-five-twelfths {
    width: 41.6666666667%; }
  .sm-six-twelfths {
    width: 50%; }
  .sm-seven-twelfths {
    width: 58.3333333333%; }
  .sm-eight-twelfths {
    width: 66.6666666667%; }
  .sm-nine-twelfths {
    width: 75%; }
  .sm-ten-twelfths {
    width: 83.3333333333%; }
  .sm-eleven-twelfths {
    width: 91.6666666667%; }
  .sm-twelve-twelfths {
    width: 100%; } }

/*

              Responsive widths at $breakpoint__md

            ---------------------------------------------------- */
@media screen and (min-width: 48.01em) {
  .md-one-whole {
    width: 100%; }
  .md-one-half {
    width: 50%; }
  .md-two-halves {
    width: 100%; }
  .md-one-third {
    width: 33.3333333333%; }
  .md-two-thirds {
    width: 66.6666666667%; }
  .md-three-thirds {
    width: 100%; }
  .md-one-fourth {
    width: 25%; }
  .md-two-fourths {
    width: 50%; }
  .md-three-fourths {
    width: 75%; }
  .md-four-fourths {
    width: 100%; }
  .md-one-fifth {
    width: 20%; }
  .md-two-fifths {
    width: 40%; }
  .md-three-fifths {
    width: 60%; }
  .md-four-fifths {
    width: 80%; }
  .md-five-fifths {
    width: 100%; }
  .md-one-sixth {
    width: 16.6666666667%; }
  .md-two-sixths {
    width: 33.3333333333%; }
  .md-three-sixths {
    width: 50%; }
  .md-four-sixths {
    width: 66.6666666667%; }
  .md-five-sixths {
    width: 83.3333333333%; }
  .md-six-sixths {
    width: 100%; }
  .md-one-seventh {
    width: 14.2857142857%; }
  .md-two-sevenths {
    width: 28.5714285714%; }
  .md-three-sevenths {
    width: 42.8571428571%; }
  .md-four-sevenths {
    width: 57.1428571429%; }
  .md-five-sevenths {
    width: 71.4285714286%; }
  .md-six-sevenths {
    width: 85.7142857143%; }
  .md-seven-sevenths {
    width: 100%; }
  .md-one-eighth {
    width: 12.5%; }
  .md-two-eighths {
    width: 25%; }
  .md-three-eighths {
    width: 37.5%; }
  .md-four-eighths {
    width: 50%; }
  .md-five-eighths {
    width: 62.5%; }
  .md-six-eighths {
    width: 75%; }
  .md-seven-eighths {
    width: 87.5%; }
  .md-eight-eighths {
    width: 100%; }
  .md-one-ninth {
    width: 11.1111111111%; }
  .md-two-ninths {
    width: 22.2222222222%; }
  .md-three-ninths {
    width: 33.3333333333%; }
  .md-four-ninths {
    width: 44.4444444444%; }
  .md-five-ninths {
    width: 55.5555555556%; }
  .md-six-ninths {
    width: 66.6666666667%; }
  .md-seven-ninths {
    width: 77.7777777778%; }
  .md-eight-ninths {
    width: 88.8888888889%; }
  .md-nine-ninths {
    width: 100%; }
  .md-one-tenth {
    width: 10%; }
  .md-two-tenths {
    width: 20%; }
  .md-three-tenths {
    width: 30%; }
  .md-four-tenths {
    width: 40%; }
  .md-five-tenths {
    width: 50%; }
  .md-six-tenths {
    width: 60%; }
  .md-seven-tenths {
    width: 70%; }
  .md-eight-tenths {
    width: 80%; }
  .md-nine-tenths {
    width: 90%; }
  .md-ten-tenths {
    width: 100%; }
  .md-one-eleventh {
    width: 9.0909090909%; }
  .md-two-elevenths {
    width: 18.1818181818%; }
  .md-three-elevenths {
    width: 27.2727272727%; }
  .md-four-elevenths {
    width: 36.3636363636%; }
  .md-five-elevenths {
    width: 45.4545454545%; }
  .md-six-elevenths {
    width: 54.5454545455%; }
  .md-seven-elevenths {
    width: 63.6363636364%; }
  .md-eight-elevenths {
    width: 72.7272727273%; }
  .md-nine-elevenths {
    width: 81.8181818182%; }
  .md-ten-elevenths {
    width: 90.9090909091%; }
  .md-eleven-elevenths {
    width: 100%; }
  .md-one-twelfth {
    width: 8.3333333333%; }
  .md-two-twelfths {
    width: 16.6666666667%; }
  .md-three-twelfths {
    width: 25%; }
  .md-four-twelfths {
    width: 33.3333333333%; }
  .md-five-twelfths {
    width: 41.6666666667%; }
  .md-six-twelfths {
    width: 50%; }
  .md-seven-twelfths {
    width: 58.3333333333%; }
  .md-eight-twelfths {
    width: 66.6666666667%; }
  .md-nine-twelfths {
    width: 75%; }
  .md-ten-twelfths {
    width: 83.3333333333%; }
  .md-eleven-twelfths {
    width: 91.6666666667%; }
  .md-twelve-twelfths {
    width: 100%; } }

/*

              Responsive widths at $breakpoint__lg

            ---------------------------------------------------- */
@media screen and (min-width: 64.01em) {
  .lg-one-whole {
    width: 100%; }
  .lg-one-half {
    width: 50%; }
  .lg-two-halves {
    width: 100%; }
  .lg-one-third {
    width: 33.3333333333%; }
  .lg-two-thirds {
    width: 66.6666666667%; }
  .lg-three-thirds {
    width: 100%; }
  .lg-one-fourth {
    width: 25%; }
  .lg-two-fourths {
    width: 50%; }
  .lg-three-fourths {
    width: 75%; }
  .lg-four-fourths {
    width: 100%; }
  .lg-one-fifth {
    width: 20%; }
  .lg-two-fifths {
    width: 40%; }
  .lg-three-fifths {
    width: 60%; }
  .lg-four-fifths {
    width: 80%; }
  .lg-five-fifths {
    width: 100%; }
  .lg-one-sixth {
    width: 16.6666666667%; }
  .lg-two-sixths {
    width: 33.3333333333%; }
  .lg-three-sixths {
    width: 50%; }
  .lg-four-sixths {
    width: 66.6666666667%; }
  .lg-five-sixths {
    width: 83.3333333333%; }
  .lg-six-sixths {
    width: 100%; }
  .lg-one-seventh {
    width: 14.2857142857%; }
  .lg-two-sevenths {
    width: 28.5714285714%; }
  .lg-three-sevenths {
    width: 42.8571428571%; }
  .lg-four-sevenths {
    width: 57.1428571429%; }
  .lg-five-sevenths {
    width: 71.4285714286%; }
  .lg-six-sevenths {
    width: 85.7142857143%; }
  .lg-seven-sevenths {
    width: 100%; }
  .lg-one-eighth {
    width: 12.5%; }
  .lg-two-eighths {
    width: 25%; }
  .lg-three-eighths {
    width: 37.5%; }
  .lg-four-eighths {
    width: 50%; }
  .lg-five-eighths {
    width: 62.5%; }
  .lg-six-eighths {
    width: 75%; }
  .lg-seven-eighths {
    width: 87.5%; }
  .lg-eight-eighths {
    width: 100%; }
  .lg-one-ninth {
    width: 11.1111111111%; }
  .lg-two-ninths {
    width: 22.2222222222%; }
  .lg-three-ninths {
    width: 33.3333333333%; }
  .lg-four-ninths {
    width: 44.4444444444%; }
  .lg-five-ninths {
    width: 55.5555555556%; }
  .lg-six-ninths {
    width: 66.6666666667%; }
  .lg-seven-ninths {
    width: 77.7777777778%; }
  .lg-eight-ninths {
    width: 88.8888888889%; }
  .lg-nine-ninths {
    width: 100%; }
  .lg-one-tenth {
    width: 10%; }
  .lg-two-tenths {
    width: 20%; }
  .lg-three-tenths {
    width: 30%; }
  .lg-four-tenths {
    width: 40%; }
  .lg-five-tenths {
    width: 50%; }
  .lg-six-tenths {
    width: 60%; }
  .lg-seven-tenths {
    width: 70%; }
  .lg-eight-tenths {
    width: 80%; }
  .lg-nine-tenths {
    width: 90%; }
  .lg-ten-tenths {
    width: 100%; }
  .lg-one-eleventh {
    width: 9.0909090909%; }
  .lg-two-elevenths {
    width: 18.1818181818%; }
  .lg-three-elevenths {
    width: 27.2727272727%; }
  .lg-four-elevenths {
    width: 36.3636363636%; }
  .lg-five-elevenths {
    width: 45.4545454545%; }
  .lg-six-elevenths {
    width: 54.5454545455%; }
  .lg-seven-elevenths {
    width: 63.6363636364%; }
  .lg-eight-elevenths {
    width: 72.7272727273%; }
  .lg-nine-elevenths {
    width: 81.8181818182%; }
  .lg-ten-elevenths {
    width: 90.9090909091%; }
  .lg-eleven-elevenths {
    width: 100%; }
  .lg-one-twelfth {
    width: 8.3333333333%; }
  .lg-two-twelfths {
    width: 16.6666666667%; }
  .lg-three-twelfths {
    width: 25%; }
  .lg-four-twelfths {
    width: 33.3333333333%; }
  .lg-five-twelfths {
    width: 41.6666666667%; }
  .lg-six-twelfths {
    width: 50%; }
  .lg-seven-twelfths {
    width: 58.3333333333%; }
  .lg-eight-twelfths {
    width: 66.6666666667%; }
  .lg-nine-twelfths {
    width: 75%; }
  .lg-ten-twelfths {
    width: 83.3333333333%; }
  .lg-eleven-twelfths {
    width: 91.6666666667%; }
  .lg-twelve-twelfths {
    width: 100%; } }

/*

              Responsive widths at $breakpoint__xl

            ---------------------------------------------------- */
@media screen and (min-width: 78.51em) {
  .xl-one-whole {
    width: 100%; }
  .xl-one-half {
    width: 50%; }
  .xl-two-halves {
    width: 100%; }
  .xl-one-third {
    width: 33.3333333333%; }
  .xl-two-thirds {
    width: 66.6666666667%; }
  .xl-three-thirds {
    width: 100%; }
  .xl-one-fourth {
    width: 25%; }
  .xl-two-fourths {
    width: 50%; }
  .xl-three-fourths {
    width: 75%; }
  .xl-four-fourths {
    width: 100%; }
  .xl-one-fifth {
    width: 20%; }
  .xl-two-fifths {
    width: 40%; }
  .xl-three-fifths {
    width: 60%; }
  .xl-four-fifths {
    width: 80%; }
  .xl-five-fifths {
    width: 100%; }
  .xl-one-sixth {
    width: 16.6666666667%; }
  .xl-two-sixths {
    width: 33.3333333333%; }
  .xl-three-sixths {
    width: 50%; }
  .xl-four-sixths {
    width: 66.6666666667%; }
  .xl-five-sixths {
    width: 83.3333333333%; }
  .xl-six-sixths {
    width: 100%; }
  .xl-one-seventh {
    width: 14.2857142857%; }
  .xl-two-sevenths {
    width: 28.5714285714%; }
  .xl-three-sevenths {
    width: 42.8571428571%; }
  .xl-four-sevenths {
    width: 57.1428571429%; }
  .xl-five-sevenths {
    width: 71.4285714286%; }
  .xl-six-sevenths {
    width: 85.7142857143%; }
  .xl-seven-sevenths {
    width: 100%; }
  .xl-one-eighth {
    width: 12.5%; }
  .xl-two-eighths {
    width: 25%; }
  .xl-three-eighths {
    width: 37.5%; }
  .xl-four-eighths {
    width: 50%; }
  .xl-five-eighths {
    width: 62.5%; }
  .xl-six-eighths {
    width: 75%; }
  .xl-seven-eighths {
    width: 87.5%; }
  .xl-eight-eighths {
    width: 100%; }
  .xl-one-ninth {
    width: 11.1111111111%; }
  .xl-two-ninths {
    width: 22.2222222222%; }
  .xl-three-ninths {
    width: 33.3333333333%; }
  .xl-four-ninths {
    width: 44.4444444444%; }
  .xl-five-ninths {
    width: 55.5555555556%; }
  .xl-six-ninths {
    width: 66.6666666667%; }
  .xl-seven-ninths {
    width: 77.7777777778%; }
  .xl-eight-ninths {
    width: 88.8888888889%; }
  .xl-nine-ninths {
    width: 100%; }
  .xl-one-tenth {
    width: 10%; }
  .xl-two-tenths {
    width: 20%; }
  .xl-three-tenths {
    width: 30%; }
  .xl-four-tenths {
    width: 40%; }
  .xl-five-tenths {
    width: 50%; }
  .xl-six-tenths {
    width: 60%; }
  .xl-seven-tenths {
    width: 70%; }
  .xl-eight-tenths {
    width: 80%; }
  .xl-nine-tenths {
    width: 90%; }
  .xl-ten-tenths {
    width: 100%; }
  .xl-one-eleventh {
    width: 9.0909090909%; }
  .xl-two-elevenths {
    width: 18.1818181818%; }
  .xl-three-elevenths {
    width: 27.2727272727%; }
  .xl-four-elevenths {
    width: 36.3636363636%; }
  .xl-five-elevenths {
    width: 45.4545454545%; }
  .xl-six-elevenths {
    width: 54.5454545455%; }
  .xl-seven-elevenths {
    width: 63.6363636364%; }
  .xl-eight-elevenths {
    width: 72.7272727273%; }
  .xl-nine-elevenths {
    width: 81.8181818182%; }
  .xl-ten-elevenths {
    width: 90.9090909091%; }
  .xl-eleven-elevenths {
    width: 100%; }
  .xl-one-twelfth {
    width: 8.3333333333%; }
  .xl-two-twelfths {
    width: 16.6666666667%; }
  .xl-three-twelfths {
    width: 25%; }
  .xl-four-twelfths {
    width: 33.3333333333%; }
  .xl-five-twelfths {
    width: 41.6666666667%; }
  .xl-six-twelfths {
    width: 50%; }
  .xl-seven-twelfths {
    width: 58.3333333333%; }
  .xl-eight-twelfths {
    width: 66.6666666667%; }
  .xl-nine-twelfths {
    width: 75%; }
  .xl-ten-twelfths {
    width: 83.3333333333%; }
  .xl-eleven-twelfths {
    width: 91.6666666667%; }
  .xl-twelve-twelfths {
    width: 100%; } }

/*

              Responsive widths at $breakpoint__xxl

            ---------------------------------------------------- */
@media screen and (min-width: 100.01em) {
  .xxl-one-whole {
    width: 100%; }
  .xxl-one-half {
    width: 50%; }
  .xxl-two-halves {
    width: 100%; }
  .xxl-one-third {
    width: 33.3333333333%; }
  .xxl-two-thirds {
    width: 66.6666666667%; }
  .xxl-three-thirds {
    width: 100%; }
  .xxl-one-fourth {
    width: 25%; }
  .xxl-two-fourths {
    width: 50%; }
  .xxl-three-fourths {
    width: 75%; }
  .xxl-four-fourths {
    width: 100%; }
  .xxl-one-fifth {
    width: 20%; }
  .xxl-two-fifths {
    width: 40%; }
  .xxl-three-fifths {
    width: 60%; }
  .xxl-four-fifths {
    width: 80%; }
  .xxl-five-fifths {
    width: 100%; }
  .xxl-one-sixth {
    width: 16.6666666667%; }
  .xxl-two-sixths {
    width: 33.3333333333%; }
  .xxl-three-sixths {
    width: 50%; }
  .xxl-four-sixths {
    width: 66.6666666667%; }
  .xxl-five-sixths {
    width: 83.3333333333%; }
  .xxl-six-sixths {
    width: 100%; }
  .xxl-one-seventh {
    width: 14.2857142857%; }
  .xxl-two-sevenths {
    width: 28.5714285714%; }
  .xxl-three-sevenths {
    width: 42.8571428571%; }
  .xxl-four-sevenths {
    width: 57.1428571429%; }
  .xxl-five-sevenths {
    width: 71.4285714286%; }
  .xxl-six-sevenths {
    width: 85.7142857143%; }
  .xxl-seven-sevenths {
    width: 100%; }
  .xxl-one-eighth {
    width: 12.5%; }
  .xxl-two-eighths {
    width: 25%; }
  .xxl-three-eighths {
    width: 37.5%; }
  .xxl-four-eighths {
    width: 50%; }
  .xxl-five-eighths {
    width: 62.5%; }
  .xxl-six-eighths {
    width: 75%; }
  .xxl-seven-eighths {
    width: 87.5%; }
  .xxl-eight-eighths {
    width: 100%; }
  .xxl-one-ninth {
    width: 11.1111111111%; }
  .xxl-two-ninths {
    width: 22.2222222222%; }
  .xxl-three-ninths {
    width: 33.3333333333%; }
  .xxl-four-ninths {
    width: 44.4444444444%; }
  .xxl-five-ninths {
    width: 55.5555555556%; }
  .xxl-six-ninths {
    width: 66.6666666667%; }
  .xxl-seven-ninths {
    width: 77.7777777778%; }
  .xxl-eight-ninths {
    width: 88.8888888889%; }
  .xxl-nine-ninths {
    width: 100%; }
  .xxl-one-tenth {
    width: 10%; }
  .xxl-two-tenths {
    width: 20%; }
  .xxl-three-tenths {
    width: 30%; }
  .xxl-four-tenths {
    width: 40%; }
  .xxl-five-tenths {
    width: 50%; }
  .xxl-six-tenths {
    width: 60%; }
  .xxl-seven-tenths {
    width: 70%; }
  .xxl-eight-tenths {
    width: 80%; }
  .xxl-nine-tenths {
    width: 90%; }
  .xxl-ten-tenths {
    width: 100%; }
  .xxl-one-eleventh {
    width: 9.0909090909%; }
  .xxl-two-elevenths {
    width: 18.1818181818%; }
  .xxl-three-elevenths {
    width: 27.2727272727%; }
  .xxl-four-elevenths {
    width: 36.3636363636%; }
  .xxl-five-elevenths {
    width: 45.4545454545%; }
  .xxl-six-elevenths {
    width: 54.5454545455%; }
  .xxl-seven-elevenths {
    width: 63.6363636364%; }
  .xxl-eight-elevenths {
    width: 72.7272727273%; }
  .xxl-nine-elevenths {
    width: 81.8181818182%; }
  .xxl-ten-elevenths {
    width: 90.9090909091%; }
  .xxl-eleven-elevenths {
    width: 100%; }
  .xxl-one-twelfth {
    width: 8.3333333333%; }
  .xxl-two-twelfths {
    width: 16.6666666667%; }
  .xxl-three-twelfths {
    width: 25%; }
  .xxl-four-twelfths {
    width: 33.3333333333%; }
  .xxl-five-twelfths {
    width: 41.6666666667%; }
  .xxl-six-twelfths {
    width: 50%; }
  .xxl-seven-twelfths {
    width: 58.3333333333%; }
  .xxl-eight-twelfths {
    width: 66.6666666667%; }
  .xxl-nine-twelfths {
    width: 75%; }
  .xxl-ten-twelfths {
    width: 83.3333333333%; }
  .xxl-eleven-twelfths {
    width: 91.6666666667%; }
  .xxl-twelve-twelfths {
    width: 100%; } }

/***

  @modules
---------------------------------------------------------

  Reusable objects that are combinations
  of elements and other modules.

  When you create a new module, make sure the partial is
  in the modules directory, and you import it below.

  Comment out any partial you don't need for this app.

---------------------------------------------------------  */
/**

   @site
--------------------------------------------------------
  modules/_site.scss
--------------------------------------------------------

  We use the naming convention of .site-* for global
  modules like the header, main, and footer.

  Sometimes it's beneficial to have a wrapping element
  around the site, directly following the <body>. In
  this case, we call that .site.

------------------------------------------------------ */
/**

  @site-header
--------------------------------------------------------
  modules/_site-header.scss
--------------------------------------------------------

  Site-wide header

------------------------------------------------------ */
@media screen and (min-width: 34.385em) {
  .side-header {
    padding-top: 15px; } }

@media screen and (max-width: 34.385em) {
  .side-header {
    padding: 10px 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    background-color: white; } }

.side-header .__left {
  float: left; }
  .side-header .__left > .__description {
    font-size: 12px;
    font-family: "游ゴシック体", "Yu Gothic", YuGothic;
    font-weight: bold; }
  @media screen and (min-width: 34.385em) {
    .side-header .__left .__logo {
      margin-top: 10px; } }
  @media screen and (max-width: 34.385em) {
    .side-header .__left .__logo {
      width: 80px; } }
  .side-header .__left .__logo > .__description {
    font-size: 11px;
    margin-left: 20px;
    color: #777777; }

.side-header .__right {
  float: right; }
  .side-header .__right .__top {
    display: flex;
    align-items: center; }
    .side-header .__right .__top .__tel {
      font-family: "Roboto", sans-serif;
      font-size: 24px;
      font-weight: 600;
      margin-right: 25px; }
      .side-header .__right .__top .__tel .__md {
        font-size: 18px; }
      .side-header .__right .__top .__tel .__sm {
        font-size: 12px;
        font-family: "游ゴシック体", "Yu Gothic", YuGothic;
        color: #777777;
        margin-left: 4px; }
    .side-header .__right .__top .__buttons {
      display: flex; }
      .side-header .__right .__top .__buttons .button {
        display: block;
        width: 9em;
        text-align: center; }
        .side-header .__right .__top .__buttons .button:not(:last-child) {
          margin-right: 5px; }
  .side-header .__right .__bottom .__menu {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px; }
    .side-header .__right .__bottom .__menu > li {
      position: relative;
      font-weight: bold;
      font-family: "游ゴシック体", "Yu Gothic", YuGothic;
      font-size: 15px; }
      .side-header .__right .__bottom .__menu > li > a {
        padding: 10px 0;
        display: inline-block; }
      .side-header .__right .__bottom .__menu > li:not(:last-child) {
        margin-right: 30px; }
      .side-header .__right .__bottom .__menu > li:hover .sub-menu {
        visibility: visible; }
    .side-header .__right .__bottom .__menu .sub-menu {
      position: absolute;
      visibility: hidden;
      left: 0;
      width: 16em;
      border-radius: 5px;
      overflow: hidden;
      z-index: 2;
      top: calc(1em + 20px); }
      .side-header .__right .__bottom .__menu .sub-menu > .menu-item {
        background-color: rgba(18, 11, 88, 0.8);
        padding: 1em;
        color: white;
        font-size: 14px; }
        .side-header .__right .__bottom .__menu .sub-menu > .menu-item:hover {
          background-color: rgba(18, 11, 88, 0.9); }
        .side-header .__right .__bottom .__menu .sub-menu > .menu-item:not(:last-child) {
          border-bottom: 1px solid #17105c; }
  .side-header .__right .__menu {
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    font-weight: bold;
    margin-top: 5px; }
    .side-header .__right .__menu.open + .__sub-menu {
      visibility: visible;
      opacity: 1; }
    .side-header .__right .__menu .menu-item-has-children:after {
      content: '\e808';
      color: #8482b5;
      margin-left: 5px;
      font-family: iconfont; }
    .side-header .__right .__menu .__icon {
      display: inline-block;
      vertical-align: middle; }
      .side-header .__right .__menu .__icon .__line {
        background-color: #332f6e;
        height: 2px;
        width: 20px;
        display: block;
        margin-left: auto; }
        .side-header .__right .__menu .__icon .__line:not(:last-child) {
          margin-bottom: 3px; }
        .side-header .__right .__menu .__icon .__line:nth-child(2) {
          width: 15px; }
        .side-header .__right .__menu .__icon .__line:nth-child(3) {
          width: 10px; }
    .side-header .__right .__menu .__text {
      vertical-align: middle;
      display: inline-block;
      margin-left: 6px; }
  .side-header .__right .__sub-menu {
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    position: fixed;
    top: 56px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: rgba(51, 47, 110, 0.95);
    color: white;
    font-weight: bold;
    font-size: 15px;
    overflow-y: auto; }
    .side-header .__right .__sub-menu .__menu {
      margin: 0; }
      .side-header .__right .__sub-menu .__menu > li {
        border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
      .side-header .__right .__sub-menu .__menu a {
        padding: 20px 16px;
        display: block;
        font-size: 15px; }
      .side-header .__right .__sub-menu .__menu .sub-menu {
        display: none;
        margin-bottom: 15px; }
        .side-header .__right .__sub-menu .__menu .sub-menu a {
          font-size: 13px;
          padding-left: 40px;
          display: block;
          border-bottom: none;
          padding-top: 10px;
          padding-bottom: 10px; }
      .side-header .__right .__sub-menu .__menu > li {
        position: relative; }
        .side-header .__right .__sub-menu .__menu > li:after {
          content: '\e800';
          font-family: iconfont;
          position: absolute;
          right: 1.5em;
          top: 21px; }
      .side-header .__right .__sub-menu .__menu .menu-item-has-children:after {
        content: '\e803';
        font-family: iconfont; }
      .side-header .__right .__sub-menu .__menu .menu-item-has-children.is-open:after {
        content: '\e807'; }
      .side-header .__right .__sub-menu .__menu .menu-item-has-children.is-open .sub-menu {
        display: block; }
    .side-header .__right .__sub-menu .__bottom {
      padding: 0 16px; }
    .side-header .__right .__sub-menu .__tel {
      font-size: 24px;
      font-family: "Roboto", sans-serif;
      text-align: center;
      margin-top: 50px;
      margin-bottom: 20px; }
      .side-header .__right .__sub-menu .__tel .__md {
        font-size: 18px; }
      .side-header .__right .__sub-menu .__tel .__sm {
        font-size: 12px;
        display: block; }
    .side-header .__right .__sub-menu .__buttons {
      text-align: center; }
      .side-header .__right .__sub-menu .__buttons .button {
        background-color: #0e073d;
        display: block; }
    .side-header .__right .__sub-menu .__links {
      margin-top: 50px; }
      .side-header .__right .__sub-menu .__links li a {
        line-height: 1.5; }
    .side-header .__right .__sub-menu .__sns {
      text-align: center;
      margin-top: 20px;
      margin-bottom: 50px; }
      .side-header .__right .__sub-menu .__sns a {
        display: inline-block;
        padding: 2px; }

.sticky-site-header {
  position: fixed;
  top: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 20px 0;
  width: 100%;
  z-index: 20;
  transform: translateY(-100%);
  transition: 0.16s ease-out;
  opacity: 0;
  zoom: 1; }
  .sticky-site-header.show {
    transform: translateY(0);
    opacity: 1; }
  .sticky-site-header:before, .sticky-site-header:after {
    content: " ";
    display: table; }
  .sticky-site-header:after {
    clear: both; }
  .sticky-site-header .__menu {
    float: right;
    margin-top: 5px; }
    .sticky-site-header .__menu .menu-item-has-children:after {
      content: '\e808';
      color: #8482b5;
      margin-left: 5px;
      font-family: iconfont; }
    .sticky-site-header .__menu > li {
      float: left;
      font-weight: bold;
      font-size: 15px;
      position: relative; }
      .sticky-site-header .__menu > li > a {
        padding: 10px 0;
        display: inline-block; }
      .sticky-site-header .__menu > li:not(:last-child) {
        margin-right: 2em; }
      .sticky-site-header .__menu > li:hover .sub-menu {
        visibility: visible; }
      .sticky-site-header .__menu > li .sub-menu {
        position: absolute;
        visibility: hidden;
        left: 0;
        width: 16em;
        border-radius: 5px;
        overflow: hidden;
        z-index: 2;
        top: calc(1em + 20px); }
        .sticky-site-header .__menu > li .sub-menu > .menu-item {
          background-color: rgba(18, 11, 88, 0.8);
          padding: 1em;
          color: white;
          font-size: 14px; }
          .sticky-site-header .__menu > li .sub-menu > .menu-item:hover {
            background-color: rgba(18, 11, 88, 0.9); }
          .sticky-site-header .__menu > li .sub-menu > .menu-item:not(:last-child) {
            border-bottom: 1px solid #17105c; }
  .sticky-site-header .__logo {
    float: left; }
    .sticky-site-header .__logo img {
      width: 90px; }

/**

  @site-main
--------------------------------------------------------
  modules/_site-main.scss
--------------------------------------------------------

  The site's main content

------------------------------------------------------ */
.site-main {
  display: block; }

/**

  @site-footer
--------------------------------------------------------
  modules/_site-footer.scss
--------------------------------------------------------

  The site's global footer

------------------------------------------------------ */
.site-footer {
  padding: 90px 0; }
  @media screen and (max-width: 34.385em) {
    .site-footer {
      padding-left: 16px;
      padding-right: 16px; } }
  .site-footer .__section-1 {
    overflow: hidden; }
    @media screen and (max-width: 34.385em) {
      .site-footer .__section-1 {
        text-align: center; } }
    .site-footer .__section-1 .__left {
      float: left; }
      @media screen and (min-width: 34.385em) {
        .site-footer .__section-1 .__left .__logo {
          margin-right: 30px; } }
      .site-footer .__section-1 .__left .__description {
        font-size: 14px;
        font-weight: bold;
        font-family: "游ゴシック体", "Yu Gothic", YuGothic;
        margin-top: 20px; }
    .site-footer .__section-1 .__right {
      float: right;
      width: 690px;
      padding: 20px;
      border-radius: 5px;
      border: 2px solid #eeeeee; }
      .site-footer .__section-1 .__right .__description {
        font-size: 14px;
        font-weight: bold;
        font-family: "游ゴシック体", "Yu Gothic", YuGothic;
        text-align: center; }
      .site-footer .__section-1 .__right .__partner_list {
        text-align: center;
        margin-top: 30px; }
        .site-footer .__section-1 .__right .__partner_list .__item {
          display: inline-block; }
          .site-footer .__section-1 .__right .__partner_list .__item:not(:last-child) {
            margin-right: 20px; }
          .site-footer .__section-1 .__right .__partner_list .__item img {
            height: 40px;
            width: auto; }
  .site-footer .__section-2 {
    margin-top: 80px;
    display: flex;
    font-family: "游ゴシック体", "Yu Gothic", YuGothic;
    margin-bottom: 90px; }
    .site-footer .__section-2 .__section {
      width: 25%; }
      .site-footer .__section-2 .__section:last-child .__list .__item {
        font-size: 16px;
        font-weight: bold; }
        .site-footer .__section-2 .__section:last-child .__list .__item:before {
          display: none; }
        .site-footer .__section-2 .__section:last-child .__list .__item .button {
          display: block;
          padding-left: 0;
          padding-right: 0;
          text-align: center; }
      .site-footer .__section-2 .__section .__title {
        font-weight: bold;
        font-size: 15px; }
      .site-footer .__section-2 .__section .__list {
        margin-top: 10px; }
        .site-footer .__section-2 .__section .__list .__item {
          padding: 7px 0;
          font-size: 15px; }
          .site-footer .__section-2 .__section .__list .__item:before {
            content: '-';
            display: inline-block;
            margin-right: 10px;
            font-weight: bold;
            color: #bbbbbb; }
  .site-footer .__section-3 {
    font-size: 15px;
    font-family: "游ゴシック体", "Yu Gothic", YuGothic;
    overflow: hidden; }
    @media screen and (max-width: 34.385em) {
      .site-footer .__section-3 {
        font-size: 13px; } }
    @media screen and (min-width: 34.385em) {
      .site-footer .__section-3 .__address {
        margin-left: 20px; }
        .site-footer .__section-3 .__address .__text {
          margin-right: 1em; }
        .site-footer .__section-3 .__address .button:after {
          display: none; } }
    @media screen and (max-width: 34.385em) {
      .site-footer .__section-3 .__address {
        line-height: 2; }
        .site-footer .__section-3 .__address .__item {
          margin-bottom: 20px; }
          .site-footer .__section-3 .__address .__item .__text {
            display: block;
            margin-bottom: 1em; }
          .site-footer .__section-3 .__address .__item .__button {
            display: block;
            text-align: center; }
            .site-footer .__section-3 .__address .__item .__button .button:after {
              content: '\f08e';
              font-family: iconfont;
              position: relative;
              margin-left: 0.5em;
              right: auto; } }
    @media screen and (min-width: 34.385em) {
      .site-footer .__section-3 .__left {
        float: left;
        display: flex;
        align-items: center; } }
    .site-footer .__section-3 .__left .__sns {
      text-align: center; }
      @media screen and (max-width: 34.385em) {
        .site-footer .__section-3 .__left .__sns {
          margin-top: 20px;
          margin-bottom: 20px; } }
    .site-footer .__section-3 .__right {
      font-size: 13px; }
      @media screen and (min-width: 34.385em) {
        .site-footer .__section-3 .__right {
          float: right; } }
      @media screen and (max-width: 34.385em) {
        .site-footer .__section-3 .__right {
          margin-top: 40px;
          text-align: center; } }

/**

  @pages
--------------------------------------------------------
  modules/_pages.scss
--------------------------------------------------------

  Pages contain content and can have styled headers
  and footers if you choose.

-----------------------------------------------------  */
.page__header {
  padding: 20px 0;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.025);
  border-bottom: 1px solid #cfd2d2; }

[class^="icon-"]:after, [class*=" icon-"]:after, .icon, .icon-link-ext:after {
  font-family: "iconfont";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  text-align: center;
  vertical-align: middle;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-left: 1em; }

.icon-right-open-big:before {
  content: '\e800'; }

/* '' */
.icon-left-open-big:before {
  content: '\e801'; }

/* '' */
.icon-folder:before {
  content: '\e802'; }

/* '' */
.icon-plus-1:before {
  content: '\e803'; }

/* '' */
.icon-tag:before {
  content: '\e804'; }

/* '' */
.icon-down-dir:before {
  content: '\e805'; }

/* '' */
.icon-link-ext:after {
  content: '\f08e' !important; }

/* '' */
.icon-circle-empty:before {
  content: '\f10c'; }

/* '' */
.icon-get-pocket:before {
  content: '\f265'; }

/* '' */
.icon-twitter:before {
  content: '\f309'; }

/* '' */
.icon-facebook:before {
  content: '\f30c'; }

/* '' */
.icon-minus-1:before {
  content: '\e807'; }

.icon-down-open-1:before {
  content: '\e808'; }

/* '' */
/* '' */
/**

  @blocks
--------------------------------------------------------
  modules/_blocks.scss
--------------------------------------------------------

  Blocks contain sections of content.  Blocks can have
  different visual styling, but usually have the same
  structure; one spacing unit margin at the bottom,
  and some have borders, and padding, etc.

-----------------------------------------------------  */
.block {
  margin-bottom: 40px; }

.block--ends {
  margin-top: 40px;
  margin-bottom: 40px; }

@media screen and (max-width: 34.385em) {
  body.single #content > .container, body.archive #content > .container, body.blog #content > .container {
    padding-left: 16px;
    padding-right: 16px; } }

.main {
  zoom: 1; }
  .main:before, .main:after {
    content: " ";
    display: table; }
  .main:after {
    clear: both; }
  @media screen and (min-width: 34.385em) {
    .main > .__left {
      float: left;
      width: 955px; } }
  .main > .__left > .__top {
    zoom: 1; }
    .main > .__left > .__top:before, .main > .__left > .__top:after {
      content: " ";
      display: table; }
    .main > .__left > .__top:after {
      clear: both; }
  .main .__ctas {
    margin-left: -20px;
    overflow: hidden;
    margin-top: 60px;
    margin-bottom: 60px; }
    .main .__ctas .__cta-1, .main .__ctas .__cta-2 {
      height: 470px;
      padding-left: 20px;
      text-align: center; }
      @media screen and (min-width: 34.385em) {
        .main .__ctas .__cta-1, .main .__ctas .__cta-2 {
          float: left;
          width: 50%; } }
      @media screen and (max-width: 34.385em) {
        .main .__ctas .__cta-1, .main .__ctas .__cta-2 {
          height: auto; } }
      .main .__ctas .__cta-1 .__inner, .main .__ctas .__cta-2 .__inner {
        height: 100%; }
    .main .__ctas .__cta-1 .__inner {
      padding: 40px;
      background-color: #f6f6f6;
      border-radius: 5px; }
      @media screen and (max-width: 34.385em) {
        .main .__ctas .__cta-1 .__inner {
          padding: 30px 20px; } }
      .main .__ctas .__cta-1 .__inner .__thumbnail {
        margin-bottom: 20px; }
        @media screen and (max-width: 34.385em) {
          .main .__ctas .__cta-1 .__inner .__thumbnail {
            width: 126px;
            margin-left: auto;
            margin-right: auto; } }
      .main .__ctas .__cta-1 .__inner .__button {
        margin-top: 20px; }
      .main .__ctas .__cta-1 .__inner .__text {
        font-weight: bold;
        font-family: "游ゴシック体", "Yu Gothic", YuGothic;
        font-size: 18px; }
        @media screen and (max-width: 34.385em) {
          .main .__ctas .__cta-1 .__inner .__text {
            font-size: 14px; } }
    @media screen and (max-width: 34.385em) {
      .main .__ctas .__cta-2 {
        margin-top: 20px; } }
    .main .__ctas .__cta-2 .__inner {
      padding: 40px;
      border: 2px solid #f6f6f6;
      border-radius: 5px; }
      @media screen and (max-width: 34.385em) {
        .main .__ctas .__cta-2 .__inner {
          padding: 30px 20px; } }
      .main .__ctas .__cta-2 .__inner .__thumbnail {
        text-align: center;
        margin-bottom: 20px; }
      .main .__ctas .__cta-2 .__inner .__text-1 {
        font-weight: bold;
        font-family: "游ゴシック体", "Yu Gothic", YuGothic;
        font-size: 18px;
        margin-top: 20px; }
        @media screen and (max-width: 34.385em) {
          .main .__ctas .__cta-2 .__inner .__text-1 {
            font-size: 14px; } }
      .main .__ctas .__cta-2 .__inner .__text-2 {
        margin-top: 20px;
        font-family: "游ゴシック体", "Yu Gothic", YuGothic;
        font-size: 14px; }
        @media screen and (max-width: 34.385em) {
          .main .__ctas .__cta-2 .__inner .__text-2 {
            font-size: 13px; } }
      .main .__ctas .__cta-2 .__inner .__buttons {
        margin-top: 30px;
        overflow: hidden;
        margin-left: -8px;
        margin-bottom: -8px; }
        .main .__ctas .__cta-2 .__inner .__buttons .__button {
          padding-left: 8px;
          padding-bottom: 8px;
          line-height: 2.4; }
          @media screen and (min-width: 34.385em) {
            .main .__ctas .__cta-2 .__inner .__buttons .__button {
              float: left;
              width: 50%; } }
          .main .__ctas .__cta-2 .__inner .__buttons .__button:last-child {
            width: 100%; }
          .main .__ctas .__cta-2 .__inner .__buttons .__button .button {
            display: block; }
  .main .__sns {
    width: 80px;
    float: left;
    margin-right: 80px; }
  @media screen and (min-width: 34.385em) {
    .main .__middle {
      float: left;
      width: 795px; } }
  .main .__middle .__share {
    border: 3px solid #eeeeee;
    padding: 40px;
    border-radius: 5px; }
    @media screen and (max-width: 34.385em) {
      .main .__middle .__share {
        padding: 20px;
        margin-left: -16px;
        margin-right: -16px; } }
    .main .__middle .__share .__top {
      font-family: "游ゴシック体", "Yu Gothic", YuGothic;
      font-weight: bold;
      font-size: 18px;
      border-bottom: 1px solid #f2f2f2;
      line-height: 1;
      padding-bottom: 1em;
      margin-bottom: 1em; }
      @media screen and (max-width: 34.385em) {
        .main .__middle .__share .__top {
          line-height: 1.5;
          text-align: center;
          font-size: 15px; } }
    .main .__middle .__share .__bottom {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
  .main .__middle .__author-section {
    overflow: hidden;
    margin-top: 60px;
    margin-bottom: 60px; }
    .main .__middle .__author-section .__avatar {
      margin-right: 20px; }
      .main .__middle .__author-section .__avatar img {
        width: 110px;
        height: 110px;
        border-radius: 100%; }
      @media screen and (min-width: 34.385em) {
        .main .__middle .__author-section .__avatar {
          float: left; } }
      @media screen and (max-width: 34.385em) {
        .main .__middle .__author-section .__avatar {
          margin-bottom: 20px;
          text-align: center; } }
    @media screen and (min-width: 34.385em) {
      .main .__middle .__author-section .__right {
        float: right;
        width: calc(100% - 130px); } }
    .main .__middle .__author-section .__right .__author-name {
      font-family: "游ゴシック体", "Yu Gothic", YuGothic;
      font-weight: bold;
      font-size: 16px;
      line-height: 1;
      margin-bottom: 1em; }
    .main .__middle .__author-section .__right .__description {
      font-size: 14px;
      line-height: 1.7; }
  .main .__middle .__taxonomy {
    margin-top: 60px;
    margin-bottom: 60px; }
    .main .__middle .__taxonomy .__categories, .main .__middle .__taxonomy .__tags {
      position: relative;
      font-weight: bold;
      font-size: 14px;
      font-family: "游ゴシック体", "Yu Gothic", YuGothic; }
      .main .__middle .__taxonomy .__categories:not(:last-child), .main .__middle .__taxonomy .__tags:not(:last-child) {
        margin-bottom: 20px; }
      .main .__middle .__taxonomy .__categories:after, .main .__middle .__taxonomy .__tags:after {
        content: '';
        position: absolute;
        background-color: #f6f6f6;
        width: 28px;
        height: 28px;
        border-radius: 100%;
        left: -8px;
        top: -4px;
        z-index: 1; }
    .main .__middle .__taxonomy .__categories:before {
      content: '\e802';
      font-family: iconfont;
      color: #332f6e;
      z-index: 2;
      position: relative;
      margin-right: 2em; }
    .main .__middle .__taxonomy .__tags:before {
      content: '\e804';
      font-family: iconfont;
      color: #332f6e;
      z-index: 2;
      position: relative;
      margin-right: 2em; }
    .main .__middle .__taxonomy .__tags > a:not(:first-child) {
      display: inline-block;
      margin-left: 1.5em; }
  @media screen and (max-width: 34.385em) {
    .main .sidebar {
      margin-top: 50px; } }
  @media screen and (min-width: 34.385em) {
    .main .sidebar {
      float: right;
      width: 325px; } }
  .main .sidebar .__widget:not(:last-child) {
    margin-bottom: 50px; }
  .main .sidebar .__widget > .__title {
    font-size: 16px;
    margin-bottom: 1em; }
  .main .sidebar .__widget.category .__categories .__item {
    background-color: #f6f6f6;
    font-size: 14px;
    font-weight: bold;
    font-family: "游ゴシック体", "Yu Gothic", YuGothic;
    padding: 20px;
    margin-bottom: 2px; }
    .main .sidebar .__widget.category .__categories .__item .__count {
      float: right; }

.article .__header {
  margin-bottom: 50px; }
  @media screen and (max-width: 34.385em) {
    .article .__header {
      margin-bottom: 20px; } }
  .article .__header > .__title {
    font-size: 28px;
    font-weight: bold;
    font-family: "游ゴシック体", "Yu Gothic", YuGothic;
    margin-bottom: 20px; }
    @media screen and (max-width: 34.385em) {
      .article .__header > .__title {
        font-size: 20px; } }
  .article .__header .__meta {
    zoom: 1; }
    .article .__header .__meta:before, .article .__header .__meta:after {
      content: " ";
      display: table; }
    .article .__header .__meta:after {
      clear: both; }
    .article .__header .__meta .__left {
      float: left; }
      .article .__header .__meta .__left .__category {
        padding: 0;
        border-right: 0; }
      .article .__header .__meta .__left .__avatar {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 100%; }
      .article .__header .__meta .__left .__author-name {
        font-size: 14px;
        font-family: "游ゴシック体", "Yu Gothic", YuGothic;
        font-weight: bold; }
    .article .__header .__meta .__right {
      margin-top: 12px; }
      @media screen and (min-width: 34.385em) {
        .article .__header .__meta .__right {
          float: right; } }
    .article .__header .__meta .__category {
      color: #332f6e;
      display: inline-block;
      font-size: 15px;
      font-weight: bold;
      position: relative;
      padding-right: 20px;
      border-right: 1px dotted #d7d7d7;
      margin-right: 20px;
      margin-left: 10px; }
      @media screen and (max-width: 34.385em) {
        .article .__header .__meta .__category {
          font-size: 13px;
          margin-bottom: 5px; } }
      .article .__header .__meta .__category:before {
        content: '\e802';
        font-family: iconfont;
        position: relative;
        margin-right: 1em;
        z-index: 2; }
      .article .__header .__meta .__category:after {
        content: '';
        position: absolute;
        background-color: #f6f6f6;
        width: 28px;
        height: 28px;
        border-radius: 100%;
        left: -8px;
        top: -4px;
        z-index: 1; }
    .article .__header .__meta .__time {
      font-family: "Roboto", sans-serif; }
      @media screen and (max-width: 34.385em) {
        .article .__header .__meta .__time {
          font-size: 13px; } }
  .article .__header .__thumbnail {
    margin-top: 25px;
    margin-bottom: 25px;
    overflow: hidden;
    position: relative; }
    @media screen and (min-width: 34.385em) {
      .article .__header .__thumbnail {
        border-radius: 5px; } }
    @media screen and (max-width: 34.385em) {
      .article .__header .__thumbnail {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: -16px;
        margin-right: -16px; } }
    .article .__header .__thumbnail img {
      width: 100%; }
    .article .__header .__thumbnail .__text {
      position: absolute;
      background-color: rgba(51, 47, 110, 0.9);
      color: white;
      left: 0;
      bottom: 0;
      padding: 15px;
      font-weight: bold; }
      .article .__header .__thumbnail .__text .__text-1 {
        font-size: 16px; }
      .article .__header .__thumbnail .__text .__text-2 {
        font-size: 25px; }
  .article .__header .case-info {
    margin-top: 40px; }
    @media screen and (max-width: 34.385em) {
      .article .__header .case-info {
        margin-top: 20px; } }

.case-info {
  margin-left: -40px;
  margin-top: 60px;
  zoom: 1; }
  @media screen and (max-width: 34.385em) {
    .case-info {
      margin-top: 30px; } }
  .case-info:before, .case-info:after {
    content: " ";
    display: table; }
  .case-info:after {
    clear: both; }
  .case-info > .__right, .case-info > .__left {
    padding-left: 40px;
    width: 50%;
    float: left; }
    @media screen and (max-width: 34.385em) {
      .case-info > .__right, .case-info > .__left {
        width: 100%; } }
  @media screen and (max-width: 34.385em) {
    .case-info > .__right {
      margin-top: 20px; } }
  .case-info > .__right .__author {
    display: flex;
    align-items: center; }
    .case-info > .__right .__author .__avatar {
      width: 60px; }
      .case-info > .__right .__author .__avatar img {
        border-radius: 100%; }
    .case-info > .__right .__author .__right {
      font-weight: bold;
      padding-left: 20px; }
      .case-info > .__right .__author .__right .__title {
        font-size: 12px;
        color: #332f6e; }
      .case-info > .__right .__author .__right .__author-name {
        font-size: 16px; }
  .case-info > .__right .__info {
    background-color: #f6f6f6;
    padding: 15px 20px;
    border-radius: 5px;
    margin-top: 10px;
    font-weight: bold; }
    @media screen and (max-width: 34.385em) {
      .case-info > .__right .__info {
        margin-left: -16px;
        margin-right: -16px;
        border-radius: 0; } }
    .case-info > .__right .__info > .__title {
      font-size: 14px;
      margin-bottom: 1em; }
    .case-info > .__right .__info .__services .__item {
      line-height: 1.5; }
      .case-info > .__right .__info .__services .__item a {
        display: block;
        position: relative;
        padding: 10px 0; }
        .case-info > .__right .__info .__services .__item a:after {
          content: '\e800';
          font-family: iconfont;
          position: absolute;
          right: 1.5em;
          top: 50%;
          transform: translateY(-50%);
          font-size: 12px; }
      .case-info > .__right .__info .__services .__item:hover {
        color: #332f6e; }
  .case-info tr:last-child td, .case-info tr:last-child th {
    border-bottom: 1px solid #dddddd; }
  .case-info th {
    text-align: left; }
  .case-info td, .case-info th {
    font-size: 14px;
    padding: 1.3em 0;
    border-top: 1px solid #dddddd; }
    @media screen and (max-width: 34.385em) {
      .case-info td, .case-info th {
        font-size: 13px; } }

.breadcrumb {
  font-size: 12px;
  color: #999999;
  font-weight: bold;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic;
  margin-top: 15px;
  margin-bottom: 60px;
  white-space: nowrap; }
  @media screen and (max-width: 34.385em) {
    .breadcrumb {
      font-size: 10px;
      margin-top: 10px;
      margin-bottom: 20px;
      width: 100%;
      overflow: auto; } }
  .breadcrumb li {
    position: relative;
    display: inline-block;
    margin-right: 35px; }
    .breadcrumb li i {
      display: none; }
    @media screen and (max-width: 34.385em) {
      .breadcrumb li {
        margin-right: 20px; } }
    .breadcrumb li a {
      text-decoration: underline; }
    .breadcrumb li:not(:last-child):after {
      content: '>';
      position: absolute;
      right: -22px; }
      @media screen and (max-width: 34.385em) {
        .breadcrumb li:not(:last-child):after {
          right: -15px; } }

.archive-container > .__title {
  font-size: 48px;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic;
  text-align: center;
  position: relative;
  margin-top: 80px;
  margin-bottom: 80px; }
  @media screen and (max-width: 34.385em) {
    .archive-container > .__title {
      margin-top: 40px;
      font-size: 24px; } }
  .archive-container > .__title span {
    position: relative;
    z-index: 2; }
  .archive-container > .__title:before {
    content: 'BLOG';
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 0;
    color: #fafafa;
    font-size: 170px;
    transform: translateY(-50%); }
    body.post-type-archive-case .archive-container > .__title:before {
      content: 'WORKS'; }
    body.blog .archive-container > .__title:before {
      content: 'NEWS'; }
    @media screen and (max-width: 48.01em) {
      .archive-container > .__title:before {
        font-size: 80px; } }

.archive-container > .__category-select {
  width: 340px;
  margin-top: 100px;
  margin-bottom: 70px;
  margin-left: auto;
  margin-right: auto; }
  @media screen and (max-width: 34.385em) {
    .archive-container > .__category-select {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 40px; } }

.page-numbers-container {
  margin-top: 100px;
  text-align: center; }
  .page-numbers-container .page-numbers {
    font-size: 16px;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
    overflow: hidden;
    display: inline-block; }
    .page-numbers-container .page-numbers li {
      float: left; }
      .page-numbers-container .page-numbers li:not(:last-child) {
        margin-right: 10px; }
      .page-numbers-container .page-numbers li .page-numbers {
        line-height: 1;
        padding: 10px 14px;
        display: inline-block;
        border-radius: 5px; }
      .page-numbers-container .page-numbers li .current {
        background-color: #000045;
        color: white; }

/**

  @thumbnails
--------------------------------------------------------
  modules/_thumbnails.scss
--------------------------------------------------------

  Thumbnails are usually found within a grid or gallery,
  as a teaser or preview of more content. The common
  use is to have an image on top of a title and text
  below, while the entire thumbnail is an anchor link.

  Example: Thumbnail
  <a class="thumbnail" href="/some/article">
    <img class="thumbnail__media" src="http://fillmurray.com/400/200">
    <div class="thumbnail__caption">
      <h2>A caption title here</h2>
      <p>Some more text follows to tease the rest of
      the content</p>
    </div>
  </a>

-----------------------------------------------------  */
.thumbnail {
  display: block;
  padding: 10px;
  background: rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.15);
  text-decoration: none; }

.thumbnail__media {
  margin-bottom: 10px; }

.thumbnail__caption {
  font-size: 85%; }
  .thumbnail__caption h1, .thumbnail__caption h2, .thumbnail__caption h3, .thumbnail__caption h4, .thumbnail__caption h5, .thumbnail__caption h5 {
    margin-bottom: 5px; }

.article__content a {
  color: #00bfff;
  text-decoration: underline; }

.article__content strong {
  font-weight: bold;
  color: #ff7a00; }

.article__content p {
  font-size: 16px;
  line-height: 1.8;
  margin-bottom: 2em; }
  @media screen and (max-width: 34.385em) {
    .article__content p {
      font-size: 15px; } }

.article__content h2 {
  font-size: 26px;
  padding-top: 1em;
  padding-bottom: 1em;
  margin-bottom: 2em;
  margin-top: 2em;
  border-top: 3px solid #332f6e;
  border-bottom: 1px solid #f6f6f6; }
  @media screen and (max-width: 34.385em) {
    .article__content h2 {
      font-size: 20px; } }

.article__content h3 {
  font-size: 22px !important;
  margin-top: 2em; }
  @media screen and (max-width: 34.385em) {
    .article__content h3 {
      font-size: 18px; } }

.article__content h4 {
  font-size: 18px;
  border-left: 3px solid #332f6e;
  padding: 0.5em 0.9em;
  position: relative;
  margin-bottom: 2em;
  margin-top: 2em; }
  .article__content h4:before {
    content: '';
    position: absolute;
    height: 50%;
    width: 3px;
    left: -3px;
    bottom: 0;
    background-color: #f6f6f6;
    z-index: 1; }
  @media screen and (max-width: 34.385em) {
    .article__content h4 {
      font-size: 16px; } }

.article__content h5 {
  font-size: 16px;
  margin-bottom: 2em;
  margin-top: 2em; }
  @media screen and (max-width: 34.385em) {
    .article__content h5 {
      font-size: 15px; } }

.article__content h6 {
  margin-bottom: 2em;
  margin-top: 2em; }

.article__content ul, .article__content ol {
  font-size: 16px;
  margin: 2em 0;
  list-style: none; }
  @media screen and (max-width: 34.385em) {
    .article__content ul, .article__content ol {
      font-size: 14px; } }
  .article__content ul li, .article__content ol li {
    line-height: 1.5;
    position: relative;
    margin-bottom: 0.5em; }
  .article__content ul ul, .article__content ul ol, .article__content ol ul, .article__content ol ol {
    margin-left: 1em;
    margin-bottom: 0.5em; }

.article__content ul li {
  padding-left: 1.6em; }
  .article__content ul li:before {
    content: '';
    position: absolute;
    left: 0;
    width: 0.8em;
    height: 0.8em;
    border-radius: 100%;
    border: 2px solid #332f6e;
    top: 0.3em; }

.article__content ol {
  counter-reset: ol; }
  .article__content ol li {
    counter-increment: ol;
    display: flex; }
    .article__content ol li:before {
      content: counter(ol) ". ";
      margin-right: 0.8em;
      font-weight: bold;
      font-family: "Roboto", sans-serif;
      color: #332f6e; }

.article__content table td, .article__content table th {
  padding: 1em; }
  @media screen and (max-width: 34.385em) {
    .article__content table td, .article__content table th {
      font-size: 14px;
      padding: 0.6em; } }

.article__content table th {
  background-color: #f6f6f6;
  font-weight: bold;
  font-size: 16px; }

.article__content table td {
  background-color: #fff;
  font-size: 13px; }

.article__content blockquote {
  background-color: #f6f6f6;
  margin: 3em auto;
  padding: 2.3em;
  border-radius: 5px;
  font-size: 14px;
  position: relative; }
  .article__content blockquote .__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0.3em; }
  .article__content blockquote .__text {
    line-height: 1.8; }
  .article__content blockquote .__ref {
    text-align: right;
    margin-top: 1em; }
  .article__content blockquote.blockquote-style-2 {
    background-color: white;
    border: 3px solid #f6f6f6; }
    .article__content blockquote.blockquote-style-2:before {
      content: '“';
      font-family: "Roboto", sans-serif;
      position: absolute;
      left: 30px;
      top: -0.2em;
      font-size: 78px;
      line-height: 1; }
  .article__content blockquote.blockquote-style-3, .article__content blockquote.blockquote-style-4 {
    border-color: #76ce00;
    border-width: 2px;
    border-style: solid;
    background-color: white; }
    .article__content blockquote.blockquote-style-3:before, .article__content blockquote.blockquote-style-4:before {
      content: 'POINT';
      color: #76ce00;
      font-family: "Roboto", sans-serif;
      position: absolute;
      right: 30px;
      padding: 0 1em;
      background-color: #fff;
      font-weight: bold;
      top: -0.5em;
      font-size: 20px;
      line-height: 1;
      letter-spacing: 0.05em; }
  .article__content blockquote.blockquote-style-4 {
    border-color: #ff7a00; }
    .article__content blockquote.blockquote-style-4:before {
      content: 'ATTENTION';
      color: #ff7a00; }

.article__content strong.pink-highlighter {
  box-shadow: inset 0 -0.6em 0 #ff96c7; }

.article__content strong.yellow-highlighter {
  box-shadow: inset 0 -0.6em 0 #ffef3a; }

.article__content strong.cyan-highlighter {
  box-shadow: inset 0 -0.6em 0 #3aefff; }

.article__content strong.green-highlighter {
  box-shadow: inset 0 -0.6em 0 #3aff76; }

.article__content .focused-block {
  background-color: #f3f3f3;
  border-width: 3px;
  border-style: solid;
  border-color: #cdcdcd;
  margin: 1.5em auto;
  padding: 1.5em;
  font-size: 16px; }
  @media screen and (max-width: 34.385em) {
    .article__content .focused-block {
      padding: 1em; } }
  @media (min-width: 375px) {
    .article__content .focused-block {
      font-size: calc(15px + ((1vw - 3.75px) * 0.2352941176)); } }
  @media (min-width: 800px) {
    .article__content .focused-block {
      font-size: 16px; } }
  @media (max-width: 375px) {
    .article__content .focused-block {
      font-size: 15px; } }

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #D6D6D6;
    display: inline-block;
    cursor: pointer;
    border-radius: 3px; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: #869791;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 10px;
      height: 10px;
      margin: 5px 7px;
      background: #D6D6D6;
      display: block;
      -webkit-backface-visibility: visible;
      transition: opacity 200ms ease;
      border-radius: 30px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #869791; }

/*------------------------------------*    # Home
\*------------------------------------*/
.home-slider {
  position: relative; }
  @media screen and (min-width: 34.385em) {
    .home-slider {
      margin-top: 10px;
      height: 643px; } }
  @media screen and (max-width: 34.385em) {
    .home-slider {
      height: 435px; } }
  .home-slider .__images {
    overflow: hidden;
    height: 100%; }
    @media screen and (min-width: 34.385em) {
      .home-slider .__images {
        border-radius: 10px; } }
    .home-slider .__images .owl-stage-outer, .home-slider .__images .owl-stage, .home-slider .__images .owl-item {
      height: 100%; }
    .home-slider .__images .__item {
      height: 100%;
      background-size: cover; }
  .home-slider .__text {
    font-family: "游ゴシック体", "Yu Gothic", YuGothic;
    font-weight: bold;
    position: absolute;
    width: 100%;
    z-index: 1; }
    @media screen and (max-width: 34.385em) {
      .home-slider .__text {
        padding: 5px 20px;
        border-left: 4px solid #fad90b;
        left: 0;
        bottom: 60px; } }
    @media screen and (min-width: 34.385em) {
      .home-slider .__text {
        text-align: center;
        width: 80%;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%); } }
    .home-slider .__text .__title {
      font-size: 22px;
      color: white;
      margin-bottom: 0.5em; }
      @media screen and (min-width: 34.385em) {
        .home-slider .__text .__title {
          font-size: 42px;
          margin-bottom: 1em; } }
    @media screen and (max-width: 34.385em) {
      .home-slider .__text .__button {
        color: white;
        font-size: 13px; } }
    @media screen and (min-width: 34.385em) {
      .home-slider .__text .__button {
        font-size: 16px;
        border: 2px solid #fad90b;
        background-color: white;
        padding: 1em 2em;
        border-radius: 100px;
        display: inline-block; } }

.section_1 {
  background-color: white;
  position: relative;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
  border-radius: 10px;
  font-weight: bold;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic;
  text-align: center; }
  @media screen and (min-width: 34.385em) {
    .section_1 {
      margin-top: -50px;
      width: 1256px; } }
  @media screen and (max-width: 34.385em) {
    .section_1 {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: -30px;
      padding: 20px 23px; } }
  .section_1 .__text {
    font-size: 18px; }
    @media screen and (max-width: 34.385em) {
      .section_1 .__text {
        font-size: 14px; } }
  .section_1 .__partner_list {
    text-align: center;
    margin-top: 30px; }
    @media screen and (max-width: 34.385em) {
      .section_1 .__partner_list {
        margin-top: 10px; } }
    .section_1 .__partner_list .__item {
      display: inline-block;
      padding: 0 10px; }
      .section_1 .__partner_list .__item img {
        height: 60px;
        width: auto; }
        @media screen and (max-width: 34.385em) {
          .section_1 .__partner_list .__item img {
            height: 45px;
            margin-top: 20px; } }

.section-style-1 {
  padding: 90px 0; }
  @media screen and (max-width: 34.385em) {
    .section-style-1 {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 35px;
      padding-bottom: 35px; } }
  .section-style-1 .__readmore {
    margin-top: 60px;
    text-align: center; }
    @media screen and (max-width: 34.385em) {
      .section-style-1 .__readmore {
        margin-top: 30px; } }

.heading-style-1 {
  font-size: 18px;
  font-weight: bold;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic;
  text-align: center;
  margin-bottom: 25px; }
  @media screen and (max-width: 34.385em) {
    .heading-style-1 {
      font-size: 14px;
      text-align: left; } }
  .heading-style-1:after {
    content: '';
    display: block;
    width: 2em;
    height: 2px;
    background-color: #332f6e;
    margin-top: 15px; }
    @media screen and (min-width: 34.385em) {
      .heading-style-1:after {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
        height: 3px; } }

.heading-style-2 {
  font-size: 36px;
  font-weight: bold;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 0.8em; }
  @media screen and (max-width: 34.385em) {
    .heading-style-2 {
      font-size: 18px;
      text-align: left;
      line-height: 1.6; }
      .heading-style-2 br {
        display: none; } }
  .heading-style-2 p {
    line-height: inherit; }

.heading-style-3, .article__content h3 {
  padding-top: 1.2em;
  margin-bottom: 2em;
  position: relative;
  border-top: 3px solid #f6f6f6;
  font-size: 20px; }
  @media screen and (max-width: 34.385em) {
    .heading-style-3, .article__content h3 {
      font-size: 15px;
      margin-bottom: 1em; } }
  .heading-style-3:before, .article__content h3:before {
    background-color: #332f6e;
    content: '';
    display: block;
    width: 2em;
    height: 3px;
    position: absolute;
    top: -3px; }

.paragraph-style-1 {
  color: #1f1f1f;
  font-size: 14px;
  margin-bottom: 40px;
  line-height: 1.8; }
  @media screen and (max-width: 34.385em) {
    .paragraph-style-1 {
      font-size: 13px;
      margin-bottom: 30px; } }
  @media screen and (min-width: 34.385em) {
    .paragraph-style-1 {
      text-align: center; } }
  .paragraph-style-1 p {
    line-height: inherit; }

.case-container {
  position: relative; }
  .case-container .case-number {
    background-color: #ff7700;
    color: white;
    width: 113px;
    height: 113px;
    border-radius: 200px;
    text-align: center;
    font-size: 13px;
    font-family: "游ゴシック体", "Yu Gothic", YuGothic;
    font-weight: bold;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 30px;
    top: -80px;
    z-index: 1; }
    .case-container .case-number .__number {
      font-family: "Roboto", sans-serif;
      font-size: 52px;
      font-weight: bold; }

@media screen and (min-width: 34.385em) {
  .box-style-1-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -40px;
    margin-bottom: -50px; } }

@media screen and (max-width: 34.385em) {
  .box-style-1-container .box-style-1.two-cols-when-mobile {
    display: flex; }
    .box-style-1-container .box-style-1.two-cols-when-mobile .__bottom {
      padding-left: 10px; }
    .box-style-1-container .box-style-1.two-cols-when-mobile .__text {
      width: 100%;
      text-align: center;
      padding: 5px !important;
      line-height: 1; }
      .box-style-1-container .box-style-1.two-cols-when-mobile .__text .__text-1 {
        font-size: 11px !important;
        display: inline-block; }
      .box-style-1-container .box-style-1.two-cols-when-mobile .__text .__text-2 {
        font-size: 14px !important;
        display: inline-block; } }

@media screen and (max-width: 34.385em) {
  .box-style-1-container .box-style-1 {
    margin-bottom: 30px; } }

@media screen and (min-width: 34.385em) {
  .box-style-1-container .box-style-1 {
    width: 33.33%;
    padding-left: 40px;
    margin-bottom: 50px; } }

.box-style-1-container .box-style-1 .__thumbnail {
  position: relative;
  margin-bottom: 20px;
  display: block;
  border-radius: 5px;
  overflow: hidden; }
  @media screen and (max-width: 34.385em) {
    .box-style-1-container .box-style-1 .__thumbnail {
      margin-bottom: 15px; } }
  .box-style-1-container .box-style-1 .__thumbnail img {
    width: 100%; }
  .box-style-1-container .box-style-1 .__thumbnail .__text {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 13px;
    background-color: rgba(51, 47, 110, 0.9);
    color: white;
    font-weight: bold; }
    @media screen and (max-width: 34.385em) {
      .box-style-1-container .box-style-1 .__thumbnail .__text .__text-1 {
        font-size: 13px; } }
    .box-style-1-container .box-style-1 .__thumbnail .__text .__text-2 {
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      font-size: 34px;
      line-height: 1; }
      @media screen and (max-width: 34.385em) {
        .box-style-1-container .box-style-1 .__thumbnail .__text .__text-2 {
          font-size: 26px; } }
      .box-style-1-container .box-style-1 .__thumbnail .__text .__text-2 .__sm {
        font-size: 19px; }
        @media screen and (max-width: 34.385em) {
          .box-style-1-container .box-style-1 .__thumbnail .__text .__text-2 .__sm {
            font-size: 14px; } }

.box-style-1-container .box-style-1 .__tag {
  font-size: 13px;
  color: #332f6e;
  font-weight: bold;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic;
  display: block;
  margin-bottom: 5px; }
  @media screen and (max-width: 34.385em) {
    .box-style-1-container .box-style-1 .__tag {
      font-size: 12px; } }

.box-style-1-container .box-style-1 .__link {
  font-size: 16px;
  font-weight: bold; }
  @media screen and (max-width: 34.385em) {
    .box-style-1-container .box-style-1 .__link {
      font-size: 14px; } }

.paragraph-style-2 {
  font-size: 16px;
  text-align: center;
  margin-bottom: 80px; }
  @media screen and (max-width: 34.385em) {
    .paragraph-style-2 {
      font-size: 13px;
      text-align: left; } }

@media screen and (max-width: 34.385em) {
  .service-section .__above-sub-section {
    margin-bottom: 200px; } }

.service-section .__sub-section {
  background-color: #f6f6f6;
  padding: 60px 0; }
  @media screen and (max-width: 34.385em) {
    .service-section .__sub-section {
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 30px; } }
  .service-section .__sub-section .__header {
    zoom: 1; }
    .service-section .__sub-section .__header:before, .service-section .__sub-section .__header:after {
      content: " ";
      display: table; }
    .service-section .__sub-section .__header:after {
      clear: both; }
    @media screen and (max-width: 34.385em) {
      .service-section .__sub-section .__header {
        margin-bottom: 30px; } }
    @media screen and (min-width: 34.385em) {
      .service-section .__sub-section .__header .__left {
        float: left;
        width: 827px; } }
    .service-section .__sub-section .__header .__left .__title {
      font-family: "游ゴシック体", "Yu Gothic", YuGothic;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 2em; }
      @media screen and (max-width: 34.385em) {
        .service-section .__sub-section .__header .__left .__title {
          font-size: 18px;
          margin-bottom: 1em;
          margin-top: 2em; } }
    .service-section .__sub-section .__header .__left .__description p {
      line-height: 1.6;
      font-size: 16px; }
      @media screen and (max-width: 34.385em) {
        .service-section .__sub-section .__header .__left .__description p {
          font-size: 13px; } }
      .service-section .__sub-section .__header .__left .__description p:not(:last-child) {
        margin-bottom: 1em; }
    @media screen and (min-width: 34.385em) {
      .service-section .__sub-section .__header .__image {
        float: right;
        margin-top: -120px; } }
    @media screen and (max-width: 34.385em) {
      .service-section .__sub-section .__header .__image {
        margin-top: -200px; } }

@media screen and (min-width: 34.385em) {
  .service-section .__services {
    margin-left: -20px;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    width: 100%; } }

.service-section .__services .__service {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic;
  font-weight: bold; }
  .service-section .__services .__service:last-child .__inner {
    box-shadow: none !important; }
  @media screen and (max-width: 34.385em) {
    .service-section .__services .__service {
      margin-bottom: 10px; }
      .service-section .__services .__service:nth-child(5) img {
        height: 47px; } }
  @media screen and (min-width: 34.385em) {
    .service-section .__services .__service {
      padding-left: 20px;
      width: 50%;
      margin-bottom: 20px;
      display: flex; } }
  .service-section .__services .__service .__inner {
    background-color: white;
    padding: 50px;
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 5px;
    position: relative; }
    .service-section .__services .__service .__inner .__arrow {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 37px;
      height: 37px;
      background-color: #ffe000;
      border-radius: 3px;
      visibility: hidden; }
      .service-section .__services .__service .__inner .__arrow:after {
        content: '\e800';
        font-family: iconfont;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        color: white; }
    .service-section .__services .__service .__inner:hover {
      box-shadow: 0 0 0 3px #ffe000 inset; }
      .service-section .__services .__service .__inner:hover .__arrow {
        visibility: visible; }
    @media screen and (max-width: 34.385em) {
      .service-section .__services .__service .__inner {
        padding: 16px; } }
    .service-section .__services .__service .__inner .__icon {
      width: 115px;
      flex: 1 0 115px; }
      @media screen and (max-width: 34.385em) {
        .service-section .__services .__service .__inner .__icon {
          flex: 0 0 15%;
          text-align: center; } }
    @media screen and (max-width: 34.385em) {
      .service-section .__services .__service .__inner .__right {
        padding-left: 20px; } }
  .service-section .__services .__service .__sub-title {
    color: #332f6e;
    font-size: 14px;
    margin-bottom: 1em; }
    @media screen and (max-width: 34.385em) {
      .service-section .__services .__service .__sub-title {
        font-size: 11px; } }
  .service-section .__services .__service .__title {
    font-size: 26px;
    margin-bottom: 0.7em;
    line-height: 1.4; }
    @media screen and (max-width: 34.385em) {
      .service-section .__services .__service .__title {
        font-size: 14px; } }
  .service-section .__services .__service .__description {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.7; }
  .service-section .__services .__service:last-child {
    text-align: center; }
    .service-section .__services .__service:last-child .__inner {
      background-color: #eeeeee; }
      .service-section .__services .__service:last-child .__inner .__right {
        width: 100%; }
      .service-section .__services .__service:last-child .__inner .__title {
        font-size: 20px; }
      .service-section .__services .__service:last-child .__inner .__button {
        margin-top: 20px; }

.blog-section .__button {
  text-align: center;
  margin-top: 40px; }

@media screen and (min-width: 34.385em) {
  .about-us-section .__about {
    display: flex;
    margin-left: -40px; } }

@media screen and (min-width: 34.385em) {
  .about-us-section .__about .__item {
    padding-left: 40px;
    width: 33.333%;
    flex: 1 0 33.333%; } }

.about-us-section .__about .__item .__thumbnail {
  width: 100%;
  display: block;
  margin-bottom: 20px; }
  @media screen and (max-width: 34.385em) {
    .about-us-section .__about .__item .__thumbnail {
      margin-bottom: 10px; } }

.about-us-section .__about .__item .__title {
  font-size: 18px;
  font-weight: bold;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic;
  text-align: center; }
  @media screen and (max-width: 34.385em) {
    .about-us-section .__about .__item .__title {
      font-size: 14px;
      margin-bottom: 30px; } }
  .about-us-section .__about .__item .__title:after {
    content: '';
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 15px;
    width: 3em;
    height: 2px;
    background-color: #eeeeee; }
    @media screen and (max-width: 34.385em) {
      .about-us-section .__about .__item .__title:after {
        display: none; } }

.about-us-section .__about .__item .__description {
  font-family: "游ゴシック体", "Yu Gothic", YuGothic;
  font-size: 14px;
  line-height: 1.7; }

.about-us-section .__recruit {
  text-align: center;
  margin: 130px auto; }
  @media screen and (max-width: 34.385em) {
    .about-us-section .__recruit {
      margin: 50px auto; } }

@media screen and (min-width: 34.385em) {
  .about-us-section .__news {
    width: 870px;
    float: left; } }

.about-us-section .__news .__header {
  overflow: hidden;
  font-family: "游ゴシック体", "Yu Gothic", YuGothic;
  font-weight: bold; }
  .about-us-section .__news .__header .__title {
    font-size: 24px;
    margin-bottom: 1em;
    float: left; }
    @media screen and (max-width: 34.385em) {
      .about-us-section .__news .__header .__title {
        font-size: 18px; } }
  .about-us-section .__news .__header .__link {
    float: right;
    font-size: 14px; }
    .about-us-section .__news .__header .__link:after {
      content: '\e800';
      font-family: iconfont;
      color: #ffe000;
      font-weight: bold;
      margin-left: 1em; }

@media screen and (min-width: 34.385em) {
  .about-us-section .__facebook {
    float: right;
    width: 400px; } }

.about-us-section .__facebook .__title {
  font-size: 28px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 1em;
  font-weight: bold;
  line-height: 1; }
  @media screen and (max-width: 34.385em) {
    .about-us-section .__facebook .__title {
      font-size: 20px;
      margin-top: 40px; } }

.cta-section {
  padding: 75px 20px;
  background-image: url("../images/cta.jpg");
  background-size: cover;
  background-position: top center;
  color: white;
  text-align: center;
  background-repeat: no-repeat; }
  @media screen and (min-width: 34.385em) {
    .cta-section {
      border-radius: 5px; } }
  @media screen and (max-width: 34.385em) {
    .cta-section {
      padding: 30px 20px;
      padding-top: 90px; } }
  .cta-section .__title {
    font-family: "游ゴシック体", "Yu Gothic", YuGothic;
    font-weight: bold;
    font-size: 32px; }
    @media screen and (max-width: 34.385em) {
      .cta-section .__title {
        font-size: 20px; } }
    .cta-section .__title:after {
      content: '';
      display: block;
      width: 1em;
      height: 3px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0.8em;
      margin-bottom: 0.8em;
      background-color: white; }
  .cta-section .__description {
    font-size: 16px;
    margin-bottom: 40px; }
    @media screen and (max-width: 34.385em) {
      .cta-section .__description {
        font-size: 13px; } }
  @media screen and (min-width: 34.385em) {
    .cta-section .__buttons {
      display: flex;
      justify-content: center; } }
  .cta-section .__buttons .button {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 34.385em) {
      .cta-section .__buttons .button {
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        display: block; } }
    @media screen and (min-width: 34.385em) {
      .cta-section .__buttons .button:not(:last-child) {
        margin-right: 20px; } }
    @media screen and (max-width: 34.385em) {
      .cta-section .__buttons .button:not(:last-child) {
        margin-bottom: 10px; } }
  .cta-section .__buttons .__tel {
    font-family: "Roboto", sans-serif;
    font-size: 28px;
    line-height: 1; }
    .cta-section .__buttons .__tel .__sm {
      font-size: 13px; }

.sticky-cta {
  position: fixed;
  bottom: 0;
  color: white;
  z-index: 2;
  right: calc((100% - 1360px) / 2);
  text-align: right; }
  .sticky-cta .__inner {
    border-radius: 5px 5px 0 0;
    display: inline-block;
    background-color: rgba(51, 47, 110, 0.95);
    font-weight: bold;
    padding: 20px;
    right: 0;
    transform: translateY(133px);
    transition: 0.3s; }
    .sticky-cta .__inner:hover {
      transform: translateY(0); }
    .sticky-cta .__inner .__top {
      display: flex; }
      .sticky-cta .__inner .__top img {
        width: 40px;
        height: 40px; }
      .sticky-cta .__inner .__top .__text {
        padding-left: 10px;
        font-size: 14px;
        text-align: left; }
    .sticky-cta .__inner .__bottom {
      text-align: center;
      border-top: 1px solid rgba(255, 255, 255, 0.3);
      margin-top: 11px;
      padding-top: 11px; }
      .sticky-cta .__inner .__bottom .__tel {
        font-size: 19px; }
      .sticky-cta .__inner .__bottom .__time {
        font-size: 11px;
        margin-bottom: 11px; }
      .sticky-cta .__inner .__bottom .button {
        font-size: 13px;
        padding: 1em 3.1em; }
        .sticky-cta .__inner .__bottom .button:after {
          font-size: 11px; }
