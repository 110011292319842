/**

  @lists
--------------------------------------------------------
  elements/_lists.scss
--------------------------------------------------------

  Base settings for lists.

-------------------------------------------------------- */

ul,
ol {
    li {
        line-height: 1;
        list-style: none;
    }
}

.post-list {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-bottom: -40px;

    @include bp-mobile {
        margin-bottom: -10px;
    }

    &.three-columns {
        .__item {
            width: 33.333%;
            @include bp-mobile {
                width: 100%;
                margin-bottom: 20px !important;
            }

        }

        &.two-cols-when-mobile {
            .__item {
                @include bp-mobile {
                    width: 100%;
                    display: flex;
                    margin-bottom: 10px;
                    .__thumbnail {
                        width: 40%;
                    }
                    .__bottom {
                        width: 60%;
                        padding-left: 10px;
                        .__meta {
                            margin-top: 0;
                            .__category {
                                font-size: 12px;
                            }
                            .__time {
                                font-size: 12px;
                            }
                        }
                        .__title {
                            font-size: 14px;
                        }
                    }
                }
            }

        }
    }

    &.wider-gap {
        margin-left: -40px;
        margin-bottom: -50px;
        .__item {
            padding-left: 40px;
            margin-bottom: 50px;
        }
    }

    .__item {
        width: 25%;
        padding-left: 20px;
        margin-bottom: 40px;

        .__thumbnail {
            position: relative;
            img {
                width: 100%;
                border-radius: 5px;
            }
            .__text {
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                padding: 7px;
                color: white;
                font-weight: bold;
                background-color: rgba(51, 47, 110, 0.9);
                text-align: center;
                .__text-1 {

                }
                .__text-2 {

                }
            }
        }
        .__title {
            display: block;
            font-family: $YuGothic;
            font-weight: bold;
            font-size: 16px;
            line-height: 1.6;
        }
        .__meta {
            margin-top: 15px;
            margin-bottom: 15px;
            overflow: hidden;
            .__category {
                float: left;
                font-size: 13px;
                color: $color__purple;
                font-weight: bold;
            }
            .__time {
                font-family: Montserrat, sans-serif;
                color: #888888;
                font-size: 13px;
                float: right;
                font-weight: bold;
            }
        }
    }
}

.post-list--sm {
    .__item {
        overflow: hidden;
        &:not(:last-child) {
            margin-bottom: 12px;
        }
    }

    .__thumbnail {
        float: left;
        width: 40%;
        padding-right: 10px;
        img {
            border-radius: 5px;
        }
    }

    .__meta {
        width: 60%;
        float: left;
        font-weight: bold;
        font-family: $YuGothic;
        .__categories {
            font-size: 12px;
            color: $color__purple;
            margin-bottom: 10px;
        }
        .__title {
            font-size: 14px;
        }
    }
}

.news-list {
    max-width: 1034px;
    margin-left: auto;
    margin-right: auto;

    @include bp-mobile {
        &.full {
            margin-left: -16px;
            margin-right: -16px;
        }
    }
    .__item {
        border-top: 2px solid #f4f4f4;
        padding: 25px 10px;
        padding-right: 40px;
        font-weight: bold;
        line-height: 1.4;
        position: relative;

        &:after {
            position: absolute;
            font-family: iconfont;
            content: '\e803';
            right: 20px;
            top: 28px;
            color: #c6c6c6;
            font-size: 0.9em;
        }

        &.open {
            &:after {
                content: '\e807';
            }
        }

        @include bp-mobile {
            padding: 16px;
            padding-right: 30px;

        }
        a {
            display: block;
            @include bp-tablet-and-desktop {
                display: flex;
            }
        }
        .__title {
            font-size: 15px;
        }
        .__time {
            font-size: 12px;
            font-family: $Roboto;
            width: 7em;
            display: inline-block;
            flex: 0 0 7em;
            @include bp-mobile {
                display: block;
                margin-bottom: 5px;
            }
        }
        .__content {
            margin-top: 1em;
            font-size: 0.9em;
            font-weight: normal;
            line-height: 1.8;
            p {
                line-height: inherit;
            }
        }
        &:last-child {
            border-bottom: 1px solid #f4f4f4;
        }
        &:hover {
            background-color: #fafafa;

            &:after {
                color: $color__purple;
            }
        }
    }
}