/**

  @text
--------------------------------------------------------
  elements/_text.scss
--------------------------------------------------------

  Some basic styles to paragraphs and
  inline styles

-----------------------------------------------------  */

// paragraphs

p {
    line-height: 1.25;
    &:last-child {
        margin-bottom: 0;
    }
}

// inline

b,
strong {
    // NOTE: use bold font-face if available instead of 'font-weight: bold'
    font-weight: bold;
}

i,
em {
    // NOTE: use italic font-face if available instead of 'font-style: italic'
    font-style: italic;
}