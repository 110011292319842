/**

  @site-header
--------------------------------------------------------
  modules/_site-header.scss
--------------------------------------------------------

  Site-wide header

------------------------------------------------------ */

.side-header {
    @include bp-tablet-and-desktop {
        padding-top: 15px;
    }
    @include bp-mobile {
        padding: 10px 16px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 20;
        background-color: white;
    }
    .__left {
        float: left;
        > .__description {
            font-size: 12px;
            font-family: $YuGothic;
            font-weight: bold;
        }
        .__logo {

            @include bp-tablet-and-desktop {
                margin-top: 10px;
            }

            @include bp-mobile {
                width: 80px;
            }

            > .__description {
                font-size: 11px;
                margin-left: 20px;
                color: $color__grey;
            }
        }
    }
    .__right {
        float: right;
        .__top {
            display: flex;
            align-items: center;
            .__tel {
                font-family: $Roboto;
                font-size: 24px;
                font-weight: 600;
                margin-right: 25px;
                .__md {
                    font-size: 18px;
                }
                .__sm {
                    font-size: 12px;
                    font-family: $YuGothic;
                    color: $color__grey;
                    margin-left: 4px;
                }
            }
            .__buttons {
                display: flex;
                .button {
                    display: block;
                    width: 9em;
                    text-align: center;
                    &:not(:last-child) {
                        margin-right: 5px;
                    }
                }
            }
        }
        .__bottom {
            .__menu {
                display: flex;
                justify-content: flex-end;
                margin-top: 10px;
                > li {
                    position: relative;
                    font-weight: bold;
                    font-family: $YuGothic;
                    font-size: 15px;
                    > a {
                        padding: 10px 0;
                        display: inline-block;
                    }
                    &:not(:last-child) {
                        margin-right: 30px;
                    }

                    &:hover {
                        .sub-menu {
                            visibility: visible;
                        }
                    }
                }

                .sub-menu {
                    position: absolute;
                    visibility: hidden;
                    left: 0;
                    width: 16em;
                    border-radius: 5px;
                    overflow: hidden;
                    z-index: 2;
                    top: calc(1em + 20px);
                    > .menu-item {
                        background-color: rgba(18, 11, 88, 0.8);
                        padding: 1em;
                        color: white;
                        font-size: 14px;
                        &:hover {
                            background-color: rgba(18, 11, 88, 0.9);
                        }
                        &:not(:last-child) {
                            border-bottom: 1px solid #17105c;
                        }
                    }
                }
            }
        }

        .__menu {
            font-family: $Roboto;
            font-size: 13px;
            font-weight: bold;
            margin-top: 5px;

            &.open {
                + .__sub-menu {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .menu-item-has-children {
                &:after {
                    content: '\e808';
                    color: #8482b5;
                    margin-left: 5px;
                    font-family: iconfont;
                }
            }
            .__icon {
                display: inline-block;
                vertical-align: middle;
                .__line {
                    background-color: $color__purple;
                    height: 2px;
                    width: 20px;
                    display: block;
                    margin-left: auto;
                    &:not(:last-child) {
                        margin-bottom: 3px;
                    }
                    &:nth-child(2) {
                        width: 15px;
                    }
                    &:nth-child(3) {
                        width: 10px;
                    }
                }
            }
            .__text {
                vertical-align: middle;
                display: inline-block;
                margin-left: 6px;
            }
        }

        .__sub-menu {
            visibility: hidden;
            opacity: 0;
            transition: 0.2s;
            position: fixed;
            top: 56px;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 20;
            background-color: rgba(51, 47, 110, 0.95);
            color: white;
            font-weight: bold;
            font-size: 15px;
            overflow-y: auto;
            .__menu {
                margin: 0;
                > li {
                    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
                }
                a {
                    padding: 20px 16px;
                    display: block;

                    font-size: 15px;
                }

                .sub-menu {
                    display: none;
                    margin-bottom: 15px;
                    a {
                        font-size: 13px;
                        padding-left: 40px;
                        display: block;
                        border-bottom: none;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }
                }

                > li {
                    position: relative;

                    &:after {
                        content: '\e800';
                        font-family: iconfont;
                        position: absolute;
                        right: 1.5em;
                        top: 21px;
                    }
                }
                .menu-item-has-children {
                    &:after {
                        content: '\e803';
                        font-family: iconfont;
                    }

                    &.is-open {
                        &:after {
                            content: '\e807';
                        }
                        .sub-menu {
                            display: block;
                        }
                    }
                }
            }
            .__bottom {
                padding: 0 16px;
            }
            .__tel {
                font-size: 24px;
                font-family: $Roboto;
                text-align: center;
                margin-top: 50px;
                margin-bottom: 20px;

                .__md {
                    font-size: 18px;
                }
                .__sm {
                    font-size: 12px;
                    display: block;
                }
            }
            .__buttons {

                text-align: center;
                .button {
                    background-color: #0e073d;
                    display: block;
                }
            }
            .__links {
                margin-top: 50px;
                li {
                    a {
                        line-height: 1.5;
                    }
                }
            }

            .__sns {
                text-align: center;
                margin-top: 20px;
                margin-bottom: 50px;
                a {
                    display: inline-block;
                    padding: 2px;
                }
            }
        }
    }
}

.sticky-site-header {
    position: fixed;
    top: 0;
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px 0;
    width: 100%;
    z-index: 20;
    transform: translateY(-100%);
    transition: 0.16s ease-out;
    opacity: 0;
    &.show {
        transform: translateY(0);
        opacity: 1;
    }
    @include clearfix;
    .__menu {
        float: right;
        margin-top: 5px;

        .menu-item-has-children {
            &:after {
                content: '\e808';
                color: #8482b5;
                margin-left: 5px;
                font-family: iconfont;
            }
        }

        > li {
            float: left;
            font-weight: bold;
            font-size: 15px;
            position: relative;
            > a {
                padding: 10px 0;
                display: inline-block;
            }

            &:not(:last-child) {
                margin-right: 2em;
            }

            &:hover {
                .sub-menu {
                    visibility: visible;
                }
            }

            .sub-menu {
                position: absolute;
                visibility: hidden;
                left: 0;
                width: 16em;
                border-radius: 5px;
                overflow: hidden;
                z-index: 2;
                top: calc(1em + 20px);
                > .menu-item {
                    background-color: rgba(18, 11, 88, 0.8);
                    padding: 1em;
                    color: white;
                    font-size: 14px;
                    &:hover {
                        background-color: rgba(18, 11, 88, 0.9);
                    }
                    &:not(:last-child) {
                        border-bottom: 1px solid #17105c;
                    }
                }
            }

        }
    }
    .__logo {
        float: left;
        img {
            width: 90px;
        }
    }
}