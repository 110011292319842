/**

  @tables
---------------------------------------------------------
  elements/_tables.scss
--------------------------------------------------------

  Base styling for tables

--------------------------------------------------------- */


table {
    width: 100%;
    border-collapse: collapse;
    border: none;
}

th {
    // Add custom styles below...

}

th,
td {
    // Add custom styles below...

}