/**

  @forms
---------------------------------------------------------
  elements/_forms.scss
--------------------------------------------------------

  Base styles for all forms and form related elements

  Below you'll set styles for all forms, and if you have
  any specific kinds of forms, like a search form that you
  create a classname of form--search, put that at the
  bottom of this partial.

  This scss partial is pretty big.  Here's how it is
  organized.

  * Base styling on form elements

  * Styles for form & form modules


--------------------------------------------------------- */

// Input elements
input, textarea, select {
    -webkit-appearance: none;
    padding: 1em 1.5em;
    border-radius: 5px;
    width: 100%;
    color: $color__black;
    background-color: white;
    transition: background-color 0.2s ease-out;
    border: none;
    font-size: 14px;
    font-weight: bold;

    // placeholder
    $input-placeholder: #8e8e8e;
    &::-webkit-input-placeholder {
        color: $input-placeholder;
        @extend %serif--light;
    }
    &:-moz-placeholder {
        color: $input-placeholder;
        @extend %serif--light;
    }
    &::-moz-placeholder {
        color: $input-placeholder;
    }
    &:-ms-input-placeholder {
        color: $input-placeholder;
    }

    // Placeholders on focus
    &:focus {
        outline: none;
        &::-webkit-input-placeholder {
            opacity: .3;
        }
        &:-moz-placeholder {
            opacity: .3;
        }
        &::-moz-placeholder {
            opacity: .3;
        }
        &:-ms-input-placeholder {
            opacity: .3;
        }
    }

    &.m--error {
        background-color: #F8D2D2;
    }
}

textarea {
    min-height: 10em;
}

input[type=radio] {
    -webkit-appearance: none;
    width: 1em;
    height: 1em;
    padding: 0;
    border-radius: 50%;
    border: 1px solid #444;
    background: transparent;
    &:checked {
        background: #222;
    }
}

select {
    background-color: #f6f6f6;
    position: relative;
}

.select {
    position: relative;
    &:after {
        font-family: iconfont;
        content: '\e805';
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
}