/**

  @images
---------------------------------------------------------
  elements/_images.scss
--------------------------------------------------------

  Base styling for images, plus some helpful utility
  classes that modify images.

  Note: You won't add any styling to these image classes,
  these are just utility classes

--------------------------------------------------------- */

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle; // Remove the gap between images and the bottom of their containers: h5bp.com/i/440
    border: none;
}

/* @img--natural
--------------------------------------------------------

  Natural Images are 100% max-width, but their natural width by default

------------------------------------------------------ */

.img--natural {
    width: auto;
}

/* @image placement
--------------------------------------------------------

  Here are a few helper classes to position images, particularly
  helpful inside paragraphs.

  Example: Image Right
  <p><img class="img--right" src="http://fillmurray.com/400/300"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

  Example: Image, Natural Width
  <p><img class="img--left" src="http://fillmurray.com/400/300"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

  Example: Image, Center
  <p><img class="img--center" src="http://fillmurray.com/400/300"> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p><p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>

------------------------------------------------------ */

.img--right {
    width: auto;
    float: right;
    margin-bottom: 1*$unit;
    margin-left: 1*$unit;
}

.img--left {
    width: auto;
    float: left;
    margin-right: 1*$unit;
    margin-bottom: 1*$unit;
}

.img--center {
    width: auto;
    display: block;
    margin-right: auto;
    margin-bottom: 1*$unit;
    margin-left: auto;
}

// if these classes are used on an element that contains an image
// make the image its natural width
.img--right,
.img--left,
.img--center {
    img {
        width: auto;
    }
}

/* @img--circle
--------------------------------------------------------

  Popular circle image for bios etc.

  Example: Circle image
  <img class="img--circle" src="http://fillmurray.com/400/400">

------------------------------------------------------ */

.img--circle {
    border-radius: 50%;
}