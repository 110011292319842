[class^="icon-"]:after, [class*=" icon-"]:after, .icon {
    font-family: "iconfont";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    //width: 1em;
    text-align: center;
    vertical-align: middle;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: 1em;
}

.icon-right-open-big:before {
    content: '\e800';
}

/* '' */
.icon-left-open-big:before {
    content: '\e801';
}

/* '' */
.icon-folder:before {
    content: '\e802';
}

/* '' */
.icon-plus-1:before {
    content: '\e803';
}

/* '' */
.icon-tag:before {
    content: '\e804';
}

/* '' */
.icon-down-dir:before {
    content: '\e805';
}

/* '' */
.icon-link-ext:after {
    content: '\f08e' !important;
    @extend .icon;
}

/* '' */
.icon-circle-empty:before {
    content: '\f10c';
}

/* '' */
.icon-get-pocket:before {
    content: '\f265';
}

/* '' */
.icon-twitter:before {
    content: '\f309';
}

/* '' */
.icon-facebook:before {
    content: '\f30c';
}

/* '' */
.icon-minus-1:before {
    content: '\e807';
}

.icon-down-open-1:before {
    content: '\e808';
}

/* '' */

/* '' */