/**

  @clearfix mixin
---------------------------------------------------------
  base/_clearfix.scss
--------------------------------------------------------

  Use @include clearfix(); in your CSS

  Usage: clearfix mixin
  .element-to-clearfix {
    @include cleafix();
  }


--------------------------------------------------------- */

@mixin clearfix() {
    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
    // For IE 6/7 (trigger hasLayout)
    zoom: 1;
}