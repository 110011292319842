.archive-container {
    > .__title {
        font-size: 48px;
        font-family: $YuGothic;
        text-align: center;
        position: relative;
        margin-top: 80px;
        margin-bottom: 80px;

        @include bp-mobile {
            margin-top: 40px;
            font-size: 24px;
        }

        span {
            position: relative;
            z-index: 2;
        }

        &:before {
            content: 'BLOG';
            position: absolute;
            text-align: center;
            width: 100%;
            top: 50%;
            left: 0;
            color: #fafafa;
            font-size: 170px;
            transform: translateY(-50%);

            @at-root body.post-type-archive-case & {
                content: 'WORKS';
            }

            @at-root body.blog & {
                content: 'NEWS';
            }

            @include bp-tablet {
                font-size: 80px;
            }
        }
    }

    > .__category-select {
        width: 340px;
        margin-top: 100px;
        margin-bottom: 70px;
        margin-left: auto;
        margin-right: auto;
        @include bp-mobile {
            width: 100%;
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
}

.page-numbers-container {
    margin-top: 100px;
    text-align: center;
    .page-numbers {
        font-size: 16px;
        font-weight: bold;
        font-family: $Roboto;
        overflow: hidden;
        display: inline-block;
        li {
            float: left;
            &:not(:last-child) {
                margin-right: 10px;
            }
            .page-numbers {
                line-height: 1;
                padding: 10px 14px;
                display: inline-block;
                border-radius: 5px;
            }
            .current {
                background-color: #000045;
                color: white;
            }
        }
    }
}

