@mixin responsive-property($max-amount,$min-amount,$max-viewport-width,$min-viewport-width,$properties) {
    @media (min-width: $min-viewport-width) {
        & {
            $amount-difference: $max-amount - $min-amount;
            $viewport-width-difference: $max-viewport-width - $min-viewport-width;
            @each $property in $properties {
                #{$property}: calc(#{$min-amount} + ((1vw - #{$min-viewport-width / 100}) * #{100 * $amount-difference / $viewport-width-difference}));
            }
        }
    }

    // Stop font scaling above $max-viewport-width
    @media (min-width: $max-viewport-width) {
        & {
            @each $property in $properties {
                #{$property}: $max-amount;
            }
        }
    }

    // Set the minimum amount under $min-viewport-width
    @media (max-width: $min-viewport-width) {
        & {
            @each $property in $properties {
                #{$property}: $min-amount;
            }
        }
    }
}


// A handy helper mixin for `responsive-property`.
@mixin property($properties,$max-amount,$min-amount: false) {
    @if not $min-amount {
        $min-amount: $max-amount / 2;
    }

    // Fallback for browsers that does not support `calc()`.
    @each $property in $properties {
        #{$property}: $max-amount;
    }

    @include responsive-property($max-amount, $min-amount, $start-shrinking-width, $stop-shrinking-width, $properties);
}