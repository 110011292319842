/**

  @site-footer
--------------------------------------------------------
  modules/_site-footer.scss
--------------------------------------------------------

  The site's global footer

------------------------------------------------------ */

.site-footer {
    padding: 90px 0;
    @include bp-mobile {
        padding-left: 16px;
        padding-right: 16px;
    }
    .__section-1 {
        overflow: hidden;
        @include bp-mobile {
            text-align: center;
        }
        .__left {
            float: left;
            .__logo {
                @include bp-tablet-and-desktop {
                    margin-right: 30px;
                }
            }
            .__description {
                font-size: 14px;
                font-weight: bold;
                font-family: $YuGothic;
                margin-top: 20px;
            }
        }
        .__right {
            float: right;
            width: 690px;
            padding: 20px;
            border-radius: 5px;
            border: 2px solid #eeeeee;
            .__description {
                font-size: 14px;
                font-weight: bold;
                font-family: $YuGothic;
                text-align: center;
            }
            .__partner_list {
                text-align: center;
                margin-top: 30px;
                .__item {
                    display: inline-block;
                    &:not(:last-child) {
                        margin-right: 20px;
                    }
                    img {
                        height: 40px;
                        width: auto;
                    }
                }
            }
        }
    }

    .__section-2 {
        margin-top: 80px;
        display: flex;
        font-family: $YuGothic;
        margin-bottom: 90px;
        .__section {
            width: 25%;
            &:last-child {
                .__list {
                    .__item {
                        font-size: 16px;
                        font-weight: bold;
                        &:before {
                            display: none;
                        }

                        .button {
                            display: block;
                            padding-left: 0;
                            padding-right: 0;
                            text-align: center;
                        }
                    }
                }
            }
            .__title {
                font-weight: bold;
                font-size: 15px;
            }
            .__list {
                margin-top: 10px;
                .__item {
                    padding: 7px 0;
                    font-size: 15px;
                    &:before {
                        content: '-';
                        display: inline-block;
                        margin-right: 10px;
                        font-weight: bold;
                        color: #bbbbbb;
                    }
                }
            }
        }
    }

    .__section-3 {
        font-size: 15px;
        font-family: $YuGothic;
        overflow: hidden;
        @include bp-mobile {
            font-size: 13px;
        }
        .__address {
            @include bp-tablet-and-desktop {
                margin-left: 20px;
                .__text {
                    margin-right: 1em;
                }

                .button {
                    &:after {
                        display: none;
                    }
                }
            }
            @include bp-mobile {
                line-height: 2;
                .__item {
                    margin-bottom: 20px;
                    .__text {
                        display: block;
                        margin-bottom: 1em;
                    }
                    .__button {
                        display: block;
                        text-align: center;
                        .button {
                            &:after {
                                content: '\f08e';
                                font-family: iconfont;
                                position: relative;
                                margin-left: 0.5em;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }

        .__left {

            @include bp-tablet-and-desktop {
                float: left;
                display: flex;
                align-items: center;
            }

            .__sns {
                text-align: center;
                @include bp-mobile {
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
        }

        .__right {
            font-size: 13px;
            @include bp-tablet-and-desktop {
                float: right;
            }
            @include bp-mobile {
                margin-top: 40px;
                text-align: center;
            }
        }
    }
}