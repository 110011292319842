/**

  @quotes
--------------------------------------------------------
  elements/_quotes.scss
--------------------------------------------------------

  Base settings for Blockquotes and pull quotes

-----------------------------------------------------  */

blockquote {
    // Any custom styles below...
}

.pull-quote {
    // Any custom styles below...
}




