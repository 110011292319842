/**

  @variables
---------------------------------------------------------
  base/_vars.scss
--------------------------------------------------------

  Global variables are defined here.  Define your colors,
  spacing and grid settings.

-----------------------------------------------------  */

/* @colors
--------------------------------------------------------

  All color variables are defined here.

  We use real color names here.  Although naming something
  like 'brand' is in fact more generic and reusable, we've
  found that you end up creating variables like $brand-2,
  $brand-alt, $brand-complimentary etc.  While coding,
  it's too easy to forget what those mean.

  If the design changes drastically and the brand changes
  from, let's say, blue to green, just do a global
  find/replace - that's what text editors are for.

------------------------------------------------------ */

// Colors
//
$color__black: #1f1f1f;
$color__grey: #777777;
$color__grey--light: #cfd2d2;
$color__offwhite: #f4f4f4;
$color__white: #fff;

$color__green: #a2bb60;
$color__salmon: #eb6d58;
$color__sky-blue: #94c1c8;
$color__blue: #0D699C;
$color__purple: #332f6e;
$color__navy: #151e23;
$color__brown: #76400b;

$color__error: red;
$color__warning: yellow;
$color__info: blue;
$color__success: green;

/* @breakpoints
---------------------------------------------------------

  Define global breakpoints as xs, sm, md, lg, xl, xxl

--------------------------------------------------------- */

// Define breakpoints map to help with some other functions
// and mixins.  Very fancy :)
//
$breakpoints: (
        n: 0,
        xs: 20em,
        sm: 34.375em,
        md: 48em,
        lg: 64em,
        xl: 78.5em,
        xxl: 100em
);

// Define individual variables
//
// Extra Small: Small mobile
$breakpoint__xs: map-get($breakpoints, xs);

// Small: Larger mobile
$breakpoint__sm: map-get($breakpoints, sm);

// Medium: Tablet
$breakpoint__md: map-get($breakpoints, md);

// Large: Landscape tablet, beginning desk size
$breakpoint__lg: map-get($breakpoints, lg);

// Extra Large: Large Desk size
$breakpoint__xl: map-get($breakpoints, xl);

// Double Extra Large: Huge Desk size
$breakpoint__xxl: map-get($breakpoints, xxl);

// Define grid gutters width
$gutters: (
        n: 0,
        xs: 10px,
        sm: 15px,
        md: (20px, 15px),
        lg: (25px, 20px),
        xl: (30px, 25px),
        xxl: (35px, 30px)
);

// Define grid bottom spacing
// If you don't define this variable,
// the value of `$gutters` will be used instead.
$grid-bottom-spacings: (
        n: 0,
        xs: 10px,
        sm: 15px,
        md: (20px, 15px),
        lg: (25px, 20px),
        xl: (30px, 25px),
        xxl: (35px, 30px)
);

/* @global spacing unit
---------------------------------------------------------

  Define an em value for $unit.  $unit is a helpful little
  variable that serves to keep your spacing consistent.

  Most often, 1 $unit is equal to your baseline height.  So
  if your baseline height is 1.125ems (which is 18px), that's
  the value of 1 $unit.

--------------------------------------------------------- */

$unit: 20px;

/* @root font size
---------------------------------------------------------

  The font-size of `<html>` element.

--------------------------------------------------------- */

$root-font-size: 16px;

$site-max-width: 1360px;

$iPhone6-width: 375px;

$start-shrinking-width: 800px;

$stop-shrinking-width: $iPhone6-width;