/**

  @links
--------------------------------------------------------
  elements/_links.scss
--------------------------------------------------------

  Base settings for links

-------------------------------------------------------- */


/* @anchors
--------------------------------------------------------

  Anchors will have this base styling

------------------------------------------------------ */

a {
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus {
        opacity: .75;
    }
}

/* @webkit-tap-highlight
--------------------------------------------------------

  Declare the webkit tap highlight style

------------------------------------------------------ */

body {
    -webkit-tap-highlight-color: rgba(0, 0, 0, .35);
}