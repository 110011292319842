/****

  MAIN STYLESHEET for [* Site Name Here *]

---------------------------------------------------------

  BUILT WITH SKYLINE: EVOLVING CSS ARCHITECTURE

  See README.md for info and credits


  TABLE OF CONTENTS
  @toc

  - Base ............ foundations, mixins, functions, vars
  - Elements ........ single elements
  - Layout .......... layout objects like grids, containers
  - Modules ......... objects created by combining elements

---------------------------------------------------------  */







/***

  @base
---------------------------------------------------------

  Your CSS begins with some global variables, a reset which
  is basically Normalize, some mixins, and a print stylesheet.

-----------------------------------------------------  */

  // Vars
  //
  @import "base/vars";

  // Reset
  //
  @import "base/reset";

  // Mixins
  //
  @import "base/mixins/helpers";
  @import "base/mixins/breakpoints";
  @import "base/mixins/clearfix";
  @import "base/mixins/font-face-importer";
  @import "base/mixins/responsive-property";

  // Global
  //
  @import "base/fonts";

  // Development Helpers
  //
  // @import "base/dev-helpers";

  // Utility
  //
  @import "base/helpers";

  // Print
  //
  @import "base/print";





/***

  @elements
---------------------------------------------------------

  Global styling of elements.

---------------------------------------------------------  */



  @import "elements/body";
  @import "elements/buttons";
  @import "elements/code";
  @import "elements/forms";
  @import "elements/headings";
  @import "elements/tables";
  @import "elements/html";
  @import "elements/images";
  @import "elements/icons";
  @import "elements/links";
  @import "elements/lists";
  @import "elements/quotes";
  @import "elements/text";







/***

  @layout
---------------------------------------------------------

  A large portion of CSS is structural layout. You will use
  these helpers to create the scaffolding that will support
  the elements and modules that make up your site.

---------------------------------------------------------  */

  @import "layout/container";
  @import "layout/grids";
  @import "layout/hide";
  @import "layout/media";
  @import "layout/ups";
  @import "layout/widths";





/***

  @modules
---------------------------------------------------------

  Reusable objects that are combinations
  of elements and other modules.

  When you create a new module, make sure the partial is
  in the modules directory, and you import it below.

  Comment out any partial you don't need for this app.

---------------------------------------------------------  */


  // Site Modules
  //
  @import "modules/site";
  @import "modules/site-header";
  @import "modules/site-main";
  @import "modules/site-footer";


  // Page Template Module
  //
  @import "modules/pages";


  // Component Modules
  //
  @import "modules/iconfont";
  @import "modules/blocks";
  @import "modules/single";
  @import "modules/breadcrumb";
  @import "modules/archive";
  @import "modules/thumbnails";
  @import "modules/article";
  @import "modules/owl-carousel/owl.carousel";
  @import "modules/owl-carousel/owl.theme.default";
