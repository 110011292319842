@include utility();

.is-bold {
    font-weight: bold;
}

.has-text-right {
    text-align: right;
}

.has-text-left {
    text-align: left;
}

.has-text-centered {
    text-align: center;
}

.is-uppercase {
    text-transform: uppercase;
}

.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline-flex {
    display: inline-flex;
}

.inline {
    display: inline;
}

.is-fullwidth {
    width: 100%;
}

.clear {
    clear: both;
}

.is-clearfix {
    @include clearfix;
}

.is-paddingless {
    padding: 0;
}

.is-marginless {
    margin: 0;
}

.is-pulled-left {
    float: left;
}

.is-pulled-right {
    float: right;
}

.is-clipped {
    overflow: hidden !important;
}

.is-overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
}

.is-disabled {
    pointer-events: none;
}

.is-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}