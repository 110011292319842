/**

  @thumbnails
--------------------------------------------------------
  modules/_thumbnails.scss
--------------------------------------------------------

  Thumbnails are usually found within a grid or gallery,
  as a teaser or preview of more content. The common
  use is to have an image on top of a title and text
  below, while the entire thumbnail is an anchor link.

  Example: Thumbnail
  <a class="thumbnail" href="/some/article">
    <img class="thumbnail__media" src="http://fillmurray.com/400/200">
    <div class="thumbnail__caption">
      <h2>A caption title here</h2>
      <p>Some more text follows to tease the rest of
      the content</p>
    </div>
  </a>

-----------------------------------------------------  */

.thumbnail {
  display: block;
  padding: .5*$unit;
  background: rgba(0, 0, 0, .1);
  border: 1px solid rgba(0, 0, 0, .15);
  text-decoration: none;
}

.thumbnail__media {
  margin-bottom: .5*$unit;
}

.thumbnail__caption {
  font-size: 85%;
  h1, h2, h3, h4, h5, h5 {
   margin-bottom: .25*$unit;
  }
}