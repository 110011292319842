@import "screen";

/*------------------------------------*\
    # Home
\*------------------------------------*/

.home-slider {

    position: relative;

    @include bp-tablet-and-desktop {
        margin-top: 10px;
        height: 643px;
    }

    @include bp-mobile {
        height: 435px;
    }

    .__images {
        overflow: hidden;
        height: 100%;
        @include bp-tablet-and-desktop {
            border-radius: 10px;
        }
        .owl-stage-outer, .owl-stage, .owl-item {
            height: 100%;
        }
        .__item {
            height: 100%;
            background-size: cover;
        }
    }

    .__text {
        font-family: $YuGothic;
        font-weight: bold;
        position: absolute;
        width: 100%;
        z-index: 1;

        @include bp-mobile {
            padding: 5px 20px;
            border-left: 4px solid #fad90b;
            left: 0;
            bottom: 60px;

        }

        @include bp-tablet-and-desktop {
            text-align: center;
            width: 80%;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
        }
        .__title {
            font-size: 22px;
            color: white;
            margin-bottom: 0.5em;
            @include bp-tablet-and-desktop {
                font-size: 42px;
                margin-bottom: 1em;
            }
        }
        .__button {
            @include bp-mobile {
                color: white;
                font-size: 13px;
            }
            @include bp-tablet-and-desktop {
                font-size: 16px;
                border: 2px solid #fad90b;
                background-color: white;
                padding: 1em 2em;
                border-radius: 100px;
                display: inline-block;
            }
        }
    }
}

.section_1 {
    background-color: white;
    position: relative;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    padding: 40px;
    border-radius: 10px;
    font-weight: bold;
    font-family: $YuGothic;
    text-align: center;

    @include bp-tablet-and-desktop {
        margin-top: -50px;
        width: 1256px;
    }

    @include bp-mobile {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: -30px;
        padding: 20px 23px;
    }

    .__text {
        font-size: 18px;
        @include bp-mobile {
            font-size: 14px;
        }
    }
    .__partner_list {
        text-align: center;
        margin-top: 30px;

        @include bp-mobile {
            margin-top: 10px;
        }

        .__item {
            display: inline-block;
            padding: 0 10px;
            img {
                height: 60px;
                width: auto;
                @include bp-mobile {
                    height: 45px;
                    margin-top: 20px;
                }
            }
        }
    }
}

.section-style-1 {
    padding: 90px 0;
    @include bp-mobile {
        padding-left: 16px;
        padding-right: 16px;
        padding-top: 35px;
        padding-bottom: 35px;
    }
    .__readmore {
        margin-top: 60px;
        text-align: center;
        @include bp-mobile {
            margin-top: 30px;
        }
    }
}

.heading-style-1 {
    font-size: 18px;
    font-weight: bold;
    font-family: $YuGothic;
    text-align: center;
    margin-bottom: 25px;
    @include bp-mobile {
        font-size: 14px;
        text-align: left;
    }
    &:after {
        content: '';
        display: block;
        width: 2em;
        height: 2px;
        background-color: $color__purple;
        margin-top: 15px;

        @include bp-tablet-and-desktop {
            margin-left: auto;
            margin-right: auto;
            margin-top: 20px;
            height: 3px;
        }
    }
}

.heading-style-2 {
    font-size: 36px;
    font-weight: bold;
    font-family: $YuGothic;
    text-align: center;
    line-height: 1.4;
    margin-bottom: 0.8em;
    @include bp-mobile {
        font-size: 18px;
        text-align: left;
        line-height: 1.6;
        br {
            display: none;
        }
    }
    p {
        line-height: inherit;
    }
}

.heading-style-3 {
    padding-top: 1.2em;
    margin-bottom: 2em;
    position: relative;
    border-top: 3px solid #f6f6f6;
    font-size: 20px;
    @include bp-mobile {
        font-size: 15px;
        margin-bottom: 1em;
    }
    &:before {
        background-color: $color__purple;
        content: '';
        display: block;
        width: 2em;
        height: 3px;
        position: absolute;
        top: -3px;
    }
}

.paragraph-style-1 {
    color: #1f1f1f;
    font-size: 14px;
    margin-bottom: 40px;
    line-height: 1.8;

    @include bp-mobile {
        font-size: 13px;
        margin-bottom: 30px;
    }

    @include bp-tablet-and-desktop {
        text-align: center;
    }

    p {
        line-height: inherit;
    }
}

.case-container {
    position: relative;
    .case-number {
        background-color: #ff7700;
        color: white;
        width: 113px;
        height: 113px;
        border-radius: 200px;
        text-align: center;
        font-size: 13px;
        font-family: $YuGothic;
        font-weight: bold;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 30px;
        top: -80px;
        z-index: 1;
        .__number {
            font-family: $Roboto;
            font-size: 52px;
            font-weight: bold;
        }
    }
}

.box-style-1-container {

    @include bp-tablet-and-desktop {
        display: flex;
        flex-wrap: wrap;
        margin-left: -40px;
        margin-bottom: -50px;
    }

    .box-style-1 {

        &.two-cols-when-mobile {
            @include bp-mobile {
                display: flex;
                .__bottom {
                    padding-left: 10px;
                }
                .__text {
                    width: 100%;
                    text-align: center;
                    padding: 5px !important;
                    line-height: 1;
                    .__text-1 {
                        font-size: 11px !important;
                        display: inline-block;
                    }
                    .__text-2 {
                        font-size: 14px !important;
                        display: inline-block;
                    }
                }
            }
        }
        @include bp-mobile {
            margin-bottom: 30px;
        }
        @include bp-tablet-and-desktop {
            width: 33.33%;
            padding-left: 40px;
            margin-bottom: 50px;
        }

        .__thumbnail {
            position: relative;
            margin-bottom: 20px;
            display: block;
            border-radius: 5px;
            overflow: hidden;
            @include bp-mobile {
                margin-bottom: 15px;
            }
            img {
                width: 100%;
            }
            .__text {
                position: absolute;
                left: 0;
                bottom: 0;
                padding: 13px;
                background-color: rgba(51, 47, 110, 0.9);
                color: white;
                font-weight: bold;
                .__text-1 {
                    @include bp-mobile {
                        font-size: 13px;
                    }
                }
                .__text-2 {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: bold;
                    font-size: 34px;
                    line-height: 1;
                    @include bp-mobile {
                        font-size: 26px;
                    }
                    .__sm {
                        font-size: 19px;
                        @include bp-mobile {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        .__tag {
            font-size: 13px;
            color: $color__purple;
            font-weight: bold;
            font-family: $YuGothic;
            display: block;
            margin-bottom: 5px;
            @include bp-mobile {
                font-size: 12px;
            }
        }
        .__link {
            font-size: 16px;
            font-weight: bold;
            @include bp-mobile {
                font-size: 14px;
            }
        }
    }
}

.paragraph-style-2 {
    font-size: 16px;
    text-align: center;
    margin-bottom: 80px;
    @include bp-mobile {
        font-size: 13px;
        text-align: left;
    }
}

.service-section {
    .__above-sub-section {
        @include bp-mobile {
            margin-bottom: 200px;
        }
    }
    .__sub-section {
        background-color: #f6f6f6;
        padding: 60px 0;
        @include bp-mobile {
            margin-left: -16px;
            margin-right: -16px;
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 30px;
        }
        .__header {
            @include clearfix;
            @include bp-mobile {
                margin-bottom: 30px;
            }
            .__left {
                @include bp-tablet-and-desktop {
                    float: left;
                    width: 827px;
                }
                .__title {
                    font-family: $YuGothic;
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 2em;
                    @include bp-mobile {
                        font-size: 18px;
                        margin-bottom: 1em;
                        margin-top: 2em;
                    }
                }
                .__description {
                    p {
                        line-height: 1.6;
                        font-size: 16px;
                        @include bp-mobile {
                            font-size: 13px;
                        }
                        &:not(:last-child) {
                            margin-bottom: 1em;
                        }
                    }
                }
            }
            .__image {

                @include bp-tablet-and-desktop {
                    float: right;
                    margin-top: -120px;
                }
                @include bp-mobile {
                    margin-top: -200px;
                }
            }
        }
    }

    .__services {

        @include bp-tablet-and-desktop {
            margin-left: -20px;
            margin-top: 50px;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }

        .__service {

            font-family: $YuGothic;
            font-weight: bold;

            &:last-child {
                .__inner {
                    box-shadow: none !important;
                }
            }

            @include bp-mobile {
                margin-bottom: 10px;
                &:nth-child(5) {
                    img {
                        height: 47px;
                    }
                }
            }
            @include bp-tablet-and-desktop {
                padding-left: 20px;
                width: 50%;
                margin-bottom: 20px;
                display: flex;
            }

            .__inner {
                background-color: white;
                padding: 50px;
                display: flex;
                align-items: center;
                width: 100%;
                border-radius: 5px;
                position: relative;
                .__arrow {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    width: 37px;
                    height: 37px;
                    background-color: #ffe000;
                    border-radius: 3px;
                    visibility: hidden;
                    &:after {
                        content: '\e800';
                        font-family: iconfont;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateY(-50%) translateX(-50%);
                        color: white;
                    }
                }

                &:hover {
                    box-shadow: 0 0 0 3px #ffe000 inset;
                    .__arrow {
                        visibility: visible;
                    }
                }
                @include bp-mobile {
                    padding: 16px;
                }

                .__icon {
                    width: 115px;
                    flex: 1 0 115px;

                    @include bp-mobile {
                        flex: 0 0 15%;
                        text-align: center;
                    }
                }

                .__right {
                    @include bp-mobile {
                        //width: 100%;
                        padding-left: 20px;
                    }
                }
            }

            .__sub-title {
                color: $color__purple;
                font-size: 14px;
                margin-bottom: 1em;
                @include bp-mobile {
                    font-size: 11px;
                }
            }

            .__title {
                font-size: 26px;
                margin-bottom: 0.7em;
                line-height: 1.4;
                @include bp-mobile {
                    font-size: 14px;
                }
            }

            .__description {
                font-size: 14px;
                font-weight: normal;
                line-height: 1.7;
            }

            &:last-child {
                text-align: center;
                .__inner {
                    background-color: #eeeeee;
                    .__right {
                        width: 100%;
                    }
                    .__title {
                        font-size: 20px;
                    }
                    .__button {
                        margin-top: 20px;
                    }
                }
            }
        }
    }
}

.blog-section {
    .__button {
        text-align: center;
        margin-top: 40px;
    }
}

.about-us-section {
    .__about {
        @include bp-tablet-and-desktop {
            display: flex;
            margin-left: -40px;
        }
        .__item {

            @include bp-tablet-and-desktop {
                padding-left: 40px;
                width: 33.333%;
                flex: 1 0 33.333%;
            }

            .__thumbnail {
                width: 100%;
                display: block;
                margin-bottom: 20px;
                @include bp-mobile {
                    margin-bottom: 10px;
                }
            }
            .__title {
                font-size: 18px;
                font-weight: bold;
                font-family: $YuGothic;
                text-align: center;
                @include bp-mobile {
                    font-size: 14px;
                    margin-bottom: 30px;
                }
                &:after {
                    content: '';
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    width: 3em;
                    height: 2px;
                    background-color: #eeeeee;
                    @include bp-mobile {
                        display: none;
                    }
                }
            }
            .__description {
                font-family: $YuGothic;
                font-size: 14px;
                line-height: 1.7;
            }
        }
    }
    .__recruit {
        text-align: center;
        //font-family: $YuGothic;
        //font-weight: bold;
        //border: 4px solid #ffdd00;
        //border-radius: 5px;
        //padding: 70px 30px 80px 45px;
        //position: relative;
        margin: 130px auto;
        @include bp-mobile {
            margin: 50px auto;
        }
        //letter-spacing: 0.04em;
        //@include bp-tablet-and-desktop {
        //    width: 1000px;
        //}
        //.__image {
        //    position: absolute;
        //    top: -30px;
        //    right: 30px;
        //    width: 460px;
        //}
        //
        //.__sub-title {
        //    font-size: 15px;
        //    margin-bottom: 1.5em;
        //}
        //.__title {
        //    font-size: 30px;
        //    line-height: 1.4;
        //}
    }
    .__news {
        @include bp-tablet-and-desktop {
            width: 870px;
            float: left;
        }

        .__header {
            overflow: hidden;
            font-family: $YuGothic;
            font-weight: bold;
            .__title {
                font-size: 24px;
                margin-bottom: 1em;
                float: left;
                @include bp-mobile {
                    font-size: 18px;
                }
            }
            .__link {
                float: right;
                font-size: 14px;
                &:after {
                    content: '\e800';
                    font-family: iconfont;
                    color: #ffe000;
                    font-weight: bold;
                    margin-left: 1em;
                }
            }
        }
    }

    .__facebook {

        @include bp-tablet-and-desktop {
            float: right;
            width: 400px;
        }

        .__title {
            font-size: 28px;
            font-family: $Roboto;
            margin-bottom: 1em;
            font-weight: bold;
            line-height: 1;
            @include bp-mobile {
                font-size: 20px;
                margin-top: 40px;
            }
        }
    }
}

.cta-section {
    padding: 75px 20px;
    background-image: url("../images/cta.jpg");
    background-size: cover;
    background-position: top center;
    color: white;
    text-align: center;
    background-repeat: no-repeat;

    @include bp-tablet-and-desktop {
        border-radius: 5px;
    }

    @include bp-mobile {
        padding: 30px 20px;
        padding-top: 90px;
    }

    .__title {
        font-family: $YuGothic;
        font-weight: bold;
        font-size: 32px;

        @include bp-mobile {
            font-size: 20px;
        }

        &:after {
            content: '';
            display: block;
            width: 1em;
            height: 3px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 0.8em;
            margin-bottom: 0.8em;
            background-color: white;
        }
    }

    .__description {
        font-size: 16px;
        margin-bottom: 40px;
        @include bp-mobile {
            font-size: 13px;
        }
    }

    .__buttons {

        @include bp-tablet-and-desktop {
            display: flex;
            justify-content: center;
        }

        .button {
            display: flex;
            align-items: center;
            @include bp-mobile {
                padding-left: 0;
                padding-right: 0;
                text-align: center;
                display: block;
            }
            &:not(:last-child) {
                @include bp-tablet-and-desktop {
                    margin-right: 20px;
                }
                @include bp-mobile {
                    margin-bottom: 10px;
                }
            }
        }

        .__tel {
            font-family: $Roboto;
            font-size: 28px;
            line-height: 1;
            .__sm {
                font-size: 13px;
            }
        }
    }

}

.sticky-cta {
    position: fixed;
    bottom: 0;
    color: white;
    z-index: 2;
    right: calc((100% - 1360px) / 2);
    text-align: right;

    .__inner {
        border-radius: 5px 5px 0 0;
        display: inline-block;
        background-color: rgba(51, 47, 110, 0.95);
        font-weight: bold;
        padding: 20px;
        right: 0;
        transform: translateY(133px);
        transition: 0.3s;
        &:hover {
            transform: translateY(0);
        }
        .__top {
            display: flex;
            img {
                width: 40px;
                height: 40px;
            }
            .__text {
                padding-left: 10px;
                font-size: 14px;
                text-align: left;
            }
        }
        .__bottom {
            text-align: center;
            border-top: 1px solid rgba(255, 255, 255, 0.3);
            margin-top: 11px;
            padding-top: 11px;
            .__tel {
                font-size: 19px;
            }
            .__time {
                font-size: 11px;
                margin-bottom: 11px;
            }
            .button {
                font-size: 13px;
                padding: 1em 3.1em;
                &:after {
                    font-size: 11px;
                }
            }
        }

    }
}