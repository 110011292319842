/**

  @buttons
--------------------------------------------------------
  elements/_buttons.scss
--------------------------------------------------------

  Base settings for buttons.

------------------------------------------------------ */

button {
    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
        border: none;
    }
}

.button {
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    background: $color__purple;
    font-weight: bold;
    color: white;
    border: none;
    position: relative;
    @extend %sans--regular;
    border-radius: 5px;
    font-size: 14px;
    text-decoration: none;
    letter-spacing: 0.03em;
    padding: 0.5em 1.25em;
    &:hover,
    &:focus,
    &:active {
        outline: none;
        opacity: .85;
    }

    &:after {
        content: '\e800';
        font-family: iconfont;
        position: absolute;
        right: 1.5em;
        top: 50%;
        transform: translateY(-50%);
    }

    &.no-arrow {
        &:after {
            display: none;
        }
    }

    &.black {
        background-color: $color__black;
    }

    &.white {
        background-color: white;
        color: $color__purple;
    }

    &.bordered {
        color: $color__purple;
        border: 2px solid;
        background: transparent;
        &.white {
            color: white;
        }
    }

    &.large {
        font-size: 16px;
        padding: 1.2em 5em;
        @include bp-mobile {
            font-size: 14px;
            padding: 1em 4em;
        }
    }

    &.x-large {
        padding: 1.8em 5em;
    }

    &.md {
        font-size: 14px;
        padding: 1.2em 5em;
    }
}