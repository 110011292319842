/**

  @hide
---------------------------------------------------------
  layout/_hide.scss
--------------------------------------------------------

  Utility state classes to hide things in different ways

--------------------------------------------------------- */

// The classic, display: none
.is-hidden {
    display: none !important;
    visibility: hidden !important;
}

// Use this if you still need js access to this element
// it's visually hidden but still in the DOM
.is-visually-hidden {
    visibility: hidden;
}

.hide-if-mobile {
    @include bp-mobile {
        display: none !important;
    }
}

.hide-if-pc {
    @include bp-tablet-and-desktop {
        display: none !important;
    }
}