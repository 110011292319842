/**

   @site
--------------------------------------------------------
  modules/_site.scss
--------------------------------------------------------

  We use the naming convention of .site-* for global
  modules like the header, main, and footer.

  Sometimes it's beneficial to have a wrapping element
  around the site, directly following the <body>. In
  this case, we call that .site.

------------------------------------------------------ */

.site {

}