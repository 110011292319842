.article__content {
    a {
        color: #00bfff;
        text-decoration: underline;
    }

    strong {
        font-weight: bold;
        color: #ff7a00;
    }

    p {
        font-size: 16px;
        line-height: 1.8;
        margin-bottom: 2em;
        @include bp-mobile {
            font-size: 15px;
        }
    }

    h2 {
        font-size: 26px;
        padding-top: 1em;
        padding-bottom: 1em;
        margin-bottom: 2em;
        margin-top: 2em;
        border-top: 3px solid $color__purple;
        border-bottom: 1px solid #f6f6f6;
        @include bp-mobile {
            font-size: 20px;
        }
    }

    h3 {
        font-size: 22px !important;
        margin-top: 2em;
        @extend .heading-style-3;
        @include bp-mobile {
            font-size: 18px;
        }
    }

    h4 {
        font-size: 18px;
        border-left: 3px solid $color__purple;
        padding: 0.5em 0.9em;
        position: relative;
        margin-bottom: 2em;
        margin-top: 2em;
        &:before {
            content: '';
            position: absolute;
            height: 50%;
            width: 3px;
            left: -3px;
            bottom: 0;
            background-color: #f6f6f6;
            z-index: 1;
        }
        @include bp-mobile {
            font-size: 16px;
        }
    }

    h5 {
        font-size: 16px;
        margin-bottom: 2em;
        margin-top: 2em;
        @include bp-mobile {
            font-size: 15px;
        }
    }

    h6 {
        margin-bottom: 2em;
        margin-top: 2em;
    }

    ul, ol {
        font-size: 16px;
        margin: 2em 0;
        list-style: none;
        @include bp-mobile {
            font-size: 14px;
        }
        li {
            line-height: 1.5;
            position: relative;
            margin-bottom: 0.5em;
        }

        ul, ol {
            margin-left: 1em;
            margin-bottom: 0.5em;
        }
    }

    ul {
        li {
            padding-left: 1.6em;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                width: 0.8em;
                height: 0.8em;
                border-radius: 100%;
                border: 2px solid $color__purple;
                top: 0.3em;
            }
        }
    }

    ol {
        counter-reset: ol;
        li {
            counter-increment: ol;
            display: flex;
            &:before {
                content: counter(ol) ". ";
                margin-right: 0.8em;
                font-weight: bold;
                font-family: $Roboto;
                color: $color__purple;
            }
        }
    }

    table {
        td, th {
            //border: 1px solid #cdcdcd;
            padding: 1em;
            @include bp-mobile {
                font-size: 14px;
                padding: 0.6em;
            }
        }

        th {
            background-color: #f6f6f6;
            font-weight: bold;
            font-size: 16px;
        }

        td {
            background-color: #fff;
            font-size: 13px;
        }
    }

    blockquote {
        background-color: #f6f6f6;
        margin: 3em auto;
        padding: 2.3em;
        border-radius: 5px;
        font-size: 14px;
        position: relative;

        .__title {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 0.3em;
        }
        .__text {
            line-height: 1.8;
        }

        .__ref {
            text-align: right;
            margin-top: 1em;
        }

        &.blockquote-style-2 {
            background-color: white;
            border: 3px solid #f6f6f6;
            &:before {
                content: '“';
                font-family: $Roboto;
                position: absolute;
                left: 30px;
                top: -0.2em;
                font-size: 78px;
                line-height: 1;
            }
        }

        &.blockquote-style-3, &.blockquote-style-4 {
            border-color: #76ce00;
            border-width: 2px;
            border-style: solid;
            background-color: white;
            &:before {
                content: 'POINT';
                color: #76ce00;
                font-family: $Roboto;
                position: absolute;
                right: 30px;
                padding: 0 1em;
                background-color: #fff;
                font-weight: bold;
                top: -0.5em;
                font-size: 20px;
                line-height: 1;
                letter-spacing: 0.05em;
            }
        }

        &.blockquote-style-4 {
            border-color: #ff7a00;
            &:before {
                content: 'ATTENTION';
                color: #ff7a00;
            }
        }
    }

    strong {
        &.pink-highlighter {
            box-shadow: inset 0 -0.6em 0 #ff96c7;
        }
        &.yellow-highlighter {
            box-shadow: inset 0 -0.6em 0 #ffef3a;
        }
        &.cyan-highlighter {
            box-shadow: inset 0 -0.6em 0 #3aefff;
        }
        &.green-highlighter {
            box-shadow: inset 0 -0.6em 0 #3aff76;
        }
    }

    .focused-block {
        background-color: #f3f3f3;
        border-width: 3px;
        border-style: solid;
        border-color: #cdcdcd;
        margin: 1.5em auto;
        padding: 1.5em;
        @include bp-mobile {
            padding: 1em;
        }
        @include property(font-size, 16px, 15px);
    }

}