/**

  @breakpoints mixin
-----------------------------------------------------
  base/_mixin-breakpoints.scss
-----------------------------------------------------

  This mixin makes using inline media queries easy.
  Options include bp-at-least for min-width, bp-until
  for max-width, and bp-between for min and max widths.

  You can use your breakpoint vars, or any other value.

  Usage: styles at medium breakpoint
  .some-element {
    @include bp-at-least($breakpoint__md){
      your-styles: go-here;
    }
  }

  Usage: styles at custom breakpoint
  .another-element {
    @include bp-until(83.45em){
      your-styles: go-here;
    }
  }

-----------------------------------------------------  */

// Breakpoint At Least
@mixin bp-at-least($device-width) {
    @media screen and (min-width: $device-width + .01) {
        @content
    }
}

// Breakpoint Until
@mixin bp-until($device-width) {
    @media screen and (max-width: $device-width + .01) {
        @content
    }
}

// Breakpoint Between
@mixin bp-between($device-width, $device-width2) {
    @media screen and (min-width: $device-width) and (max-width: $device-width2) {
        @content
    }
}

// Breakpoint by devices
@mixin bp-mobile{
    @include bp-until($breakpoint__sm){
        @content;
    }
}

@mixin bp-tablet {
    @include bp-until($breakpoint__md) {
        @content;
    }
}

@mixin bp-desktop {
    @include bp-at-least($breakpoint__lg) {
        @content;
    }
}

@mixin bp-tablet-and-desktop {
    @include bp-at-least($breakpoint__sm) {
        @content;
    }
}