/**

  @code
--------------------------------------------------------
  elements/_code.scss
--------------------------------------------------------

  Code elements like <code> and <pre>

-----------------------------------------------------  */

// Code
code {
    vertical-align: middle;
    padding: .25em .5em;
    background: rgba(193, 199, 208, .3);
}

// Pre
pre {

}