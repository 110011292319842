/**

   @html
--------------------------------------------------------
  elements/_html.scss
--------------------------------------------------------

  HTML should contain the site's default background color
  and font-size

------------------------------------------------------ */

html {
    @include bp-tablet-and-desktop {
        min-width: $site-max-width;
    }
}

* {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}
